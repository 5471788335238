import React, { useMemo } from "react";
import { useDeda } from "../../../hooks";
import { HeadingText, DedaContentArticle, ListenSoundCloud, BuildingMessage } from "../atoms";
import { TextContentRender } from "../molecules";
import ContentLoading from "../../sharedComponents/atoms/ContentLoading";

export default function DedaListenRead({ dedaId }) {
  const dedaListenRead = useDeda("dedaListenReadQuery", dedaId);
  const { loading, data, error } = dedaListenRead;

  const dedaListenReadContent = useMemo(() => data?.dedaContentCollection?.items[0], [data]);

  if (!loading && data?.dedaContentCollection?.items?.length === 0) {
    return <BuildingMessage />;
  }

  if (loading || data?.dedaContentCollection?.items?.length === 0) {
    return <ContentLoading />;
  }

  if (error) {
    throw error;
  }

  const { dedaListenSoundCloudLink, dedaReadContent } = dedaListenReadContent;

  return (
    <DedaContentArticle>
      <HeadingText hType="h1" fontWeight="700">
        4) LISTEN + READ
      </HeadingText>
      <ListenSoundCloud src={dedaListenSoundCloudLink} />
      <TextContentRender rawContent={dedaReadContent?.json} links={dedaReadContent?.links} />
    </DedaContentArticle>
  );
}
