export const createLinearPerformanceDataObject = (rawData, currentTime) => {
  let currentWeek = parseInt(currentTime.currentWeek) - 1;

  const dataArray = [];
  const axisArray = [];

  Object.keys(rawData).forEach((week, weekNumber) => {
    let weekAverage = 0;

    if (weekNumber < currentWeek) {
      Object.keys(rawData[`week${weekNumber + 1}`]).forEach((day, dayNumber) => {
        if (day !== "review") {
          const { averageDeda, averagePassive, averageActive } = rawData[`week${weekNumber + 1}`][day];
          const dayAverage = 0.5 * averageDeda + 0.25 * averagePassive + 0.25 * averageActive;
          weekAverage += dayAverage;
        }
      });

      weekAverage /= 7;

      dataArray[weekNumber] = weekAverage.toFixed(2);
      axisArray[weekNumber] = `W${weekNumber + 1 < 10 ? "0" + (weekNumber + 1) : weekNumber + 1}`;
    }
  });

  return {
    labels: axisArray,
    datasets: [
      {
        label: "Overall Average %",
        data: dataArray,
        backgroundColor: "rgb(255,255,255, 0.1)",
        borderColor: "#1f8ef1",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#1f8ef1",
        pointBorderColor: "rgba(255,255,255,0)",
        pointHoverBackgroundColor: "#1f8ef1",
        pointBorderWidth: 20,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 4,
      },
    ],
  };
};
