import styled from "@emotion/styled";

const DedaContentArticle = styled.article`
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  width: 100%;
  min-height: 100%;
  height: auto;
  align-items: flex-start;
  padding: 3rem 0 3rem 0;
`;

export default DedaContentArticle;
