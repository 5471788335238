import React, { useContext, useState, useEffect } from "react";
import { UserContext } from "../../../../shared/providers";
import { CircularProgress, Dialog, DialogActions, DialogContent, Grid, Button } from "@material-ui/core";

export default function MasterAdmin() {
  const { masterAdmin, isMasterAdminLoading, setUser } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(masterAdmin !== false);
  const [emailSearch, setEmailSearch] = useState("");
  const [chosenUser, setChosenUser] = useState("");
  const [usersList, setUsersList] = useState([]);

  useEffect(() => {
    setIsOpen(masterAdmin !== false);
  }, [masterAdmin]);

  const onEmailSearchChange = (event) => {
    setEmailSearch(event.target.value);
  };

  useEffect(() => {
    if (masterAdmin.listOfUsers) {
      if (emailSearch === "") {
        setUsersList(masterAdmin.listOfUsers);
      } else {
        const newUsersList = masterAdmin.listOfUsers.filter((user) => {
          if (user !== null) {
            const searchRegex = new RegExp(emailSearch, "ig");
            return searchRegex.test(user.email);
          }
        });
        setUsersList([...newUsersList]);
        if (newUsersList.length === 0) {
          setChosenUser("");
        }
      }
    }
  }, [emailSearch]);

  const adminPanelBtn = {
    position: "absolute",
    left: 10,
    bottom: 10,
    zIndex: "9999 !important",
  };

  return (
    <React.Fragment>
      {isOpen === false && (
        <Button
          variant="contained"
          color="primary"
          style={adminPanelBtn}
          onClick={() => {
            setIsOpen(true);
          }}
        >
          Painel de admin
        </Button>
      )}
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {isMasterAdminLoading || masterAdmin === false ? (
            <CircularProgress />
          ) : typeof masterAdmin === "object" ? (
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <h2>
                  Painel de admin | Bem vindo, {masterAdmin.adminAccount.displayName} |{" "}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setUser(masterAdmin.adminAccount);
                      setIsOpen(false);
                    }}
                  >
                    Retornar à conta principal
                  </Button>
                </h2>
              </Grid>
              <Grid item>
                <Grid container direction="row" spacing={2}>
                  <Grid item>
                    <input
                      style={{
                        marginLeft: "1rem",
                        borderRadius: "0.5rem",
                        padding: "0.5rem",
                        width: "10rem",
                      }}
                      type="text"
                      placeholder="Procure por email"
                      value={emailSearch}
                      onChange={onEmailSearchChange}
                    />
                    <select
                      onChange={(event) => {
                        setChosenUser(event.target.value);
                      }}
                    >
                      <option defaultChecked>Escolha um usuário</option>
                      {usersList.map((userObj, key) => {
                        if (userObj !== null) {
                          return (
                            <option key={"user" + key} value={JSON.stringify(userObj)}>
                              Aluno: {userObj.name} | Email: {userObj.email}
                            </option>
                          );
                        }
                      })}
                    </select>
                  </Grid>
                  <Grid item>
                    <Button
                      disabled={chosenUser === ""}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        const parsedUser = JSON.parse(chosenUser);
                        alert("Voce escolheu o usuário " + parsedUser.name);
                        setUser({
                          uid: parsedUser.userUid,
                          displayName: parsedUser.name,
                          email: parsedUser.email,
                        });
                        setIsOpen(false);
                      }}
                    >
                      Ver MELP deste usuário
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <CircularProgress />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
