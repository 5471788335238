import React, { useContext } from "react";
import { Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import { AccessLink, WidgetTitle } from "../atoms";
import { HpecContext } from "../../../../shared/providers";

export default function HpecWidget() {
  const { currentHpec } = useContext(HpecContext);

  return (
    <React.Fragment>
      <Paper className="paper-shape paper-props paper-secondary widgets-height">
        <Grid
          container
          direction="column"
          xs={12}
          justifyContent="space-between"
          spacing={1}
          className="col-max-height"
        >
          <Grid item>
            <WidgetTitle titleName={"HPEC"} titleIcon={"hpecs"} />
          </Grid>

          <Grid item>
            <Typography variant="h6">
              Progress:{" "}
              <strong>
                {currentHpec && currentHpec.hpecNumber}
                /9
              </strong>
            </Typography>
          </Grid>

          <Grid item>
            <Grid container direction="row" xs={12} alignItems="center" spacing={1}>
              <Grid item xs={11}>
                <LinearProgress
                  className="progress-background"
                  variant="determinate"
                  value={currentHpec && (currentHpec.hpecNumber / 9) * 100}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle1">
                  {currentHpec && ((currentHpec.hpecNumber / 9) * 100).toFixed(0)}%
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AccessLink href={"hpec/TOPIC1/VC1"} />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
