import React, { useContext, useEffect, useState } from "react";
import { PerformanceContext, UserContext } from "../../../../../shared/providers";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { getPerformanceData } from "../../../../utils/apiCalls/lampCalls/performance";
import Chart from "chart.js";
import { createWeeklyStatsDataObject } from "../../../../utils/graphs/createWeeklyStatsDataObject";
import DataPicker from "../DataPicker";
import { unlockedDedasListMaker } from "../../../../utils/dedasListMaker";
import "../../../../assets/css/lamp/performance.css";
import Box from "@material-ui/core/Box";

export default function WeeklyStatsGraph({ theme }) {
  const { newVersionUserDocData, user, chosenWeek } = useContext(UserContext);
  const { isPerformanceLoading } = useContext(PerformanceContext);
  let [chartChosenWeek, setChartChosenWeek] = useState(false);
  const [currentDedaList, setCurrentDedaList] = useState({});

  useEffect(() => {
    unlockedDedasListMaker(newVersionUserDocData.unlockedDEDAs).then((list) => {
      delete list.week0;
      setCurrentDedaList(list);
    });
  }, []);

  const makeGraph = async () => {
    const ctx = "weekly-stats";

    if (chosenWeek !== false) {
      if (chartChosenWeek === false) {
        chartChosenWeek = chosenWeek;
      }
      await getPerformanceData({
        userUid: user.uid,
        chosenWeek: `${chartChosenWeek}`,
        chosenDay: "day1",
      }).then((response) => {
        new Chart(ctx, {
          type: "bar",
          data: createWeeklyStatsDataObject(response.weeklyStatistics, chartChosenWeek),
          options: {
            fullWidth: true,
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return tooltipItems.yLabel + " %";
                },
              },
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                    callback: function (value, index, values) {
                      return value + "%";
                    },
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  barPercentage: 0.99,
                  ticks: {
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      });
    }
  };

  useEffect(() => {
    makeGraph().catch((error) => {
      throw error;
    });
  }, [chosenWeek, isPerformanceLoading, chartChosenWeek]);

  return (
    <React.Fragment>
      <Paper className={`paper-props paper-shape`}>
        <Grid className="stretch" container direction="column" justifyContent="space-between">
          <Grid container item direction="row">
            <Grid item xs>
              <Typography className={"primary-title-" + theme} variant="h5" component="h5">
                <strong>Weekly Statistics</strong>
              </Typography>
            </Grid>

            <Grid container item xs justifyContent="flex-end">
              {isPerformanceLoading === false && chosenWeek ? (
                <DataPicker
                  listToRender={currentDedaList}
                  defaultItem={`${chosenWeek}`}
                  onSelect={(theValue) => {
                    setChartChosenWeek(theValue);
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid className={"primary-title-" + theme} item xs>
              <p>Select the week to see the averages in your studies</p>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center">
            {isPerformanceLoading ? (
              <CircularProgress />
            ) : (
              <Grid container item xs={12}>
                <Box className="graph-wrapper full-width">
                  <canvas id="weekly-stats" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
