export const createDailyStatsDataObject = (rawData, chosenWeek) => {
  const theWeek = rawData[chosenWeek];

  const readingTimeArray = [];
  const dedaTimeArray = [];
  const axisArray = ["day1", "day2", "day3", "day4", "day5", "day6", "day7"];

  axisArray.forEach((day, index) => {
    readingTimeArray[index] = theWeek[day].dedaDaily.readingTime / 60;
    dedaTimeArray[index] = theWeek[day].dedaDaily.dedaTime;
  });

  return {
    labels: ["D01", "D02", "D03", "D04", "D05", "D06", "D07"],
    datasets: [
      {
        trendlineLinear: {
          style: "rgba(255,105,180,0.8)",
          lineStyle: "solid",
          width: 2,
        },
        label: "DEDA Reading Time",
        data: readingTimeArray,
        borderColor: "rgb(29,100,215)",
        backgroundColor: "rgb(29,100,215, 0.2)",
        borderWidth: 3,
        minBarLength: 4,
      },
      {
        trendlineLinear: {
          style: "rgba(255,105,180, .8)",
          lineStyle: "solid",
          width: 2,
        },
        label: "DEDA time",
        data: dedaTimeArray,
        borderColor: "rgb(215,29,66)",
        backgroundColor: "rgb(215,29,66, 0.2)",
        borderWidth: 3,
        minBarLength: 4,
      },
    ],
  };
};
