import React from "react";
import { CircularProgress, Grid } from "@material-ui/core";

export default function LoadingGraph() {
  return (
    <React.Fragment>
      <Grid item>
        <Grid container direction="column" alignItems="center" spacing={3}>
          <Grid item xs={12}>
            <CircularProgress />
          </Grid>
          <Grid item xs={12}>
            <h4>Please wait. Loading graph</h4>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
