import React, { useContext } from "react";
import { Button, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import DayPicker from "../molecules/DayPicker";
import { ActiveInput, DedaInput, PassiveInput, ReviewInput } from "../molecules/inputs";
import { InputDataContext, NotificationContext, PerformanceContext, UserContext } from "../../../../shared/providers";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import { saveInputData } from "../../../utils/apiCalls/lamp/write/saveInputData";

export default function Input() {
  const { isLampLoading, setIsLampLoading, chosenWeek, chosenWeekDay, user } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext);
  const { inputData, setInputData, setIsInputDataChanged } = useContext(InputDataContext);
  const { setIsInputSaved, isInputSaved } = useContext(PerformanceContext);

  const handleSave = async () => {
    saveInputData({
      userUid: user.uid,
      weekNumber: `${chosenWeek}`,
      weekDay: `${chosenWeekDay}`,
      inputData,
    })
      .then((inputSaveResponse) => {
        setIsInputDataChanged(false);
        setInputData(inputSaveResponse);
        setIsLampLoading(false);
        setNotification({
          isOpen: true,
          notificationText: "Input saved successfully",
          notificationType: "success",
        });
        setIsInputSaved(!isInputSaved);
      })
      .catch((error) => {
        setIsLampLoading(false);
        setNotification({
          isOpen: true,
          notificationText: error.message.toString(),
          notificationType: "failure",
        });
      });
    return inputData;
  };

  return (
    <React.Fragment>
      <Paper className="paper-props paper-shape">
        <Grid container direction="column" xs={12} spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={5}>
                <Typography variant="h4">INPUT</Typography>
                <Typography variant="h6">
                  <strong>Input your study data</strong>
                </Typography>
              </Grid>
              <Grid item xs={12} md={7}>
                <DayPicker />
              </Grid>
            </Grid>
          </Grid>
          {isLampLoading ? (
            <React.Fragment>
              <Grid item xs={12}>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <h5>Please wait while your input data is loading...</h5>
                  </Grid>
                  <Grid item>
                    <CircularProgress />
                  </Grid>
                </Grid>
              </Grid>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item xs={12}>
                <Grid container direction="row" spacing={2} alignItems="flex-start">
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2} alignItems="stretch">
                      <Grid item xs={12}>
                        <ActiveInput />
                      </Grid>
                      <Grid item xs={12}>
                        <PassiveInput />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container direction="column" spacing={2} alignItems="stretch">
                      <Grid item xs={12}>
                        <DedaInput />
                      </Grid>
                      <Grid item xs={12}>
                        {parseInt(chosenWeek.slice(4)) >= 4 && <ReviewInput />}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Button
                  className="dark-blue-gradient primary-button"
                  disabled={isLampLoading}
                  fullWidth
                  size="large"
                  endIcon={<SaveAltIcon />}
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                    setIsLampLoading(true);
                    handleSave().catch((err) => console.error(err));
                  }}
                >
                  Save data
                </Button>
              </Grid>
            </React.Fragment>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
