import React, { useContext } from "react";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { AccessLink, WidgetTitle } from "../atoms";
import { PerformanceContext } from "../../../../shared/providers";

function PerformanceWidgetTile({ statistic, statisticValue }) {
  const { isPerformanceLoading } = useContext(PerformanceContext);
  return (
    <React.Fragment>
      <Paper className="performance-tiles paper-props">
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item xs={6} sm={12} lg={6} justifyContent="center">
            <Grid container item justifyContent="center">
              <Grid item>
                <Typography variant="h6">{statistic}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={12} lg={6}>
            <Grid container item justifyContent="center">
              <Grid item>
                <Typography variant="h6">
                  <strong>
                    {isPerformanceLoading === false ? (
                      statisticValue.toFixed(2) + "%"
                    ) : (
                      <CircularProgress
                        style={{
                          color: "white",
                        }}
                        size={20}
                      />
                    )}
                  </strong>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default function PerformanceWidget() {
  const { mainPerformanceStats } = useContext(PerformanceContext);

  return (
    <React.Fragment>
      <Paper id="performance-widget-paper" className="paper-shape paper-props paper-secondary widgets-height">
        <Grid
          container
          direction="column"
          xs={12}
          justifyContent="space-between"
          spacing={1}
          className="col-max-height"
        >
          <Grid item>
            <WidgetTitle titleName={"LAMP"} titleIcon={"lamp"} />
          </Grid>

          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={6}>
                <PerformanceWidgetTile statistic="DEDA" statisticValue={mainPerformanceStats.general.dedaQuality} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PerformanceWidgetTile statistic="REVIEW" statisticValue={mainPerformanceStats.general.review} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="row" spacing={1}>
              <Grid item xs={12} sm={6}>
                <PerformanceWidgetTile statistic="ACTIVE" statisticValue={mainPerformanceStats.general.active} />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PerformanceWidgetTile statistic="PASSIVE" statisticValue={mainPerformanceStats.general.passive} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AccessLink href="lamp/performance" />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
