import React, { useMemo } from "react";
import { useDeda } from "../../../hooks";
import { DedaContentArticle, HeadingText, ListenSoundCloud, BuildingMessage } from "../atoms";
import ContentLoading from "../../sharedComponents/atoms/ContentLoading";

export default function DedaListen({ dedaId }) {
  const dedaListenResult = useDeda("dedaListenQuery", dedaId);
  const { loading, data, error } = dedaListenResult;

  const dedaListenContent = useMemo(() => data?.dedaContentCollection?.items[0], [data]);

  if (!loading && data?.dedaContentCollection?.items?.length === 0) {
    return <BuildingMessage />;
  }

  if (loading || data?.dedaContentCollection?.items?.length === 0) {
    return <ContentLoading />;
  }

  if (error) {
    throw error;
  }

  const { dedaListenSoundCloudLink } = dedaListenContent;

  return (
    <DedaContentArticle>
      <HeadingText hType="h1" fontWeight="700">
        1) LISTEN
      </HeadingText>
      <ListenSoundCloud src={dedaListenSoundCloudLink} />
    </DedaContentArticle>
  );
}
