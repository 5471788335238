import React, { useContext, useState } from "react";
import { Button, Grid, makeStyles, CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import "../shared/css/login.css";
import { SettingsContext, UserContext } from "../shared/providers";
import { forgottenPassword } from "../shared/authentication/passwordReset";
import logoDeda from "../shared/img/svg/deda-horizontal.svg";
import logoHpec from "../shared/img/svg/hpec-horizontal.svg";
import logoLamp from "../shared/img/svg/lamp-horizontal.svg";
import logoMelpLogin from "../shared/img/svg/melp-login.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PasswordReset() {
  const classes = useStyles();

  const { email, setEmail, emailError, setEmailError, setLoading } = useContext(UserContext);
  const { isDesktop } = useContext(SettingsContext);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const url = new URLSearchParams(window.location.search);
  const isApp = url.get("isApp");

  const flexCenter = {
    display: "flex",
    justifyContent: "center",
  };

  const formWidth = {
    width: "100%",
  };

  return (
    <div className="main">
      <Grid container direction="row" alignItems="stretch">
        {isDesktop && (
          <Grid className="full-vh" item xs={12} sm={6}>
            <Grid className="full-height vertical-padding" container direction="column" justifyContent="flex-end">
              <Grid item>
                <Grid
                  className="paper-padding"
                  container
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoDeda} alt="logo-deda" />
                  </Grid>
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoHpec} alt="logo-deda" />
                  </Grid>
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoLamp} alt="logo-deda" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid className={isDesktop ? "dark-blue-background" : "dark-blue-background mobile-form"} item xs={12} sm={6}>
          <Grid
            style={{ height: "100%" }}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            <Grid
              item
              style={{
                width: isDesktop ? "50%" : "80%",
              }}
            >
              <Grid column spacing={4}>
                <Grid
                  style={{
                    ...flexCenter,
                    ...formWidth,
                    marginBottom: "2.5rem",
                  }}
                  item
                  sm={12}
                >
                  <img style={formWidth} id="login-logo" src={logoMelpLogin} alt="melp-text-white-login" />
                </Grid>
                {successMessage ? (
                  <React.Fragment>
                    <Grid
                      style={{
                        ...formWidth,
                        flexCenter,
                      }}
                      item
                      sm={12}
                    >
                      <h3 className="color-white">Sucesso! Verifique seu e-mail para recuperar sua senha.</h3>
                    </Grid>
                    {!isApp && (
                      <Grid item xs>
                        <Link style={{ textDecoration: "underline" }} className="color-white" to="/">
                          Voltar à página principal
                        </Link>
                      </Grid>
                    )}
                  </React.Fragment>
                ) : (
                  <Grid
                    style={{
                      ...formWidth,
                      flexCenter,
                    }}
                    item
                    sm={12}
                  >
                    <p className="color-white">
                      Esqueceu sua senha? Não tem problema! Insira o seu endereço de e-mail abaixo e, se você tiver uma
                      conta ativa, receberá instruções no endereço sobre como trocar de senha.
                    </p>
                    <form
                      onSubmit={(event) => {
                        setIsSubmitLoading(true);
                        event.preventDefault();
                        forgottenPassword(email)
                          .then(() => {
                            setEmailError("");
                            setEmail("");
                            setIsSubmitLoading(false);
                            setSuccessMessage(true);
                          })
                          .catch((err) => {
                            setEmailError(err.message);
                            setLoading(false);
                          });
                      }}
                      className={classes.form}
                    >
                      {isSubmitLoading ? (
                        <CircularProgress style={{ color: "white" }} />
                      ) : (
                        <React.Fragment>
                          <input
                            type="email"
                            className="form-field"
                            required
                            placeholder="Email"
                            id="email"
                            name="userEmail"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(event) => {
                              setEmail(event.target.value);
                              setEmailError();
                            }}
                          />
                          <p className="errorMsg">{emailError}</p>
                          <Button id="login-button" type="submit" fullWidth>
                            Recuperar
                          </Button>
                        </React.Fragment>
                      )}
                      <Grid
                        style={{
                          marginTop: "3vh",
                        }}
                        container
                      >
                        {!isApp && (
                          <Grid item xs>
                            <Link style={{ textDecoration: "underline" }} className="color-white" to="/">
                              Voltar à página principal
                            </Link>
                          </Grid>
                        )}
                      </Grid>
                    </form>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
