import React, { useContext } from "react";
import { Button, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import logoDeda from "../shared/img/svg/deda-horizontal.svg";
import logoLamp from "../shared/img/svg/lamp-horizontal.svg";
import logoHpec from "../shared/img/svg/hpec-horizontal.svg";
import logoMelpLogin from "../shared/img/svg/melp-login.svg";
import "../shared/css/login.css";

import { handleLogin } from "../shared/authentication/handleLogin";
import { UserContext, SettingsContext } from "../shared/providers";

export default function SignIn() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    emailError,
    setEmailError,
    passwordError,
    setPasswordError,
    setLoading,
  } = useContext(UserContext);

  const { isDesktop } = useContext(SettingsContext);

  const flexCenter = {
    display: "flex",
    justifyContent: "center",
  };

  const formWidth = {
    width: "100%",
  };

  return (
    <div className="main">
      <Grid
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: "100vh",
        }}
        container
        direction="row"
        alignItems="stretch"
        spacin={0}
      >
        {isDesktop && (
          <Grid style={{ minHeight: "100%" }} item xs={12} sm={6}>
            <Grid
              style={{ paddingBottom: "13vh" }}
              className="full-height"
              container
              direction="column"
              justifyContent="flex-end"
            >
              <Grid item>
                <Grid
                  className="paper-padding"
                  container
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="flex-start"
                >
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoDeda} alt="logo-deda" />
                  </Grid>
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoHpec} alt="logo-deda" />
                  </Grid>
                  <Grid style={flexCenter} item xs={12} sm={4}>
                    <img className="logo-secondary" src={logoLamp} alt="logo-deda" />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid className={isDesktop ? "dark-blue-background" : "dark-blue-background mobile-form"} item xs={12} sm={6}>
          <Grid
            style={{ minHeight: "100%" }}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
            spacing={0}
          >
            <Grid
              item
              style={{
                width: isDesktop ? "50%" : "80%",
              }}
            >
              <Grid column spacing={4}>
                <Grid
                  style={{
                    ...flexCenter,
                    ...formWidth,
                    marginBottom: "2.5rem",
                  }}
                  item
                  xs={12}
                >
                  <img style={formWidth} id="login-logo" src={logoMelpLogin} alt="melp-text-white-login" />
                </Grid>
                <Grid
                  style={{
                    ...formWidth,
                    flexCenter,
                  }}
                  item
                  xs={12}
                >
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      setLoading(true);
                      handleLogin({
                        email,
                        password,
                        setEmailError,
                        setPasswordError,
                        setLoading,
                      }).then();
                    }}
                  >
                    <input
                      type="email"
                      className="form-field"
                      required
                      id="email"
                      placeholder="Email"
                      name="userEmail"
                      autoComplete="email"
                      autoFocus
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                        setEmailError();
                      }}
                    />
                    <p className="errorMsg color-white">{emailError}</p>
                    <input
                      placeholder="Senha"
                      type="password"
                      className="form-field"
                      required
                      name="userPassword"
                      id="password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(event) => {
                        setPassword(event.target.value);
                      }}
                    />
                    <p className="errorMsg color-white">{passwordError}</p>
                    <Button id="login-button" type="submit" fullWidth>
                      Entrar
                    </Button>

                    <Grid
                      style={{
                        marginTop: "3vh",
                      }}
                      container
                    >
                      <Grid item xs>
                        <Link style={{ textDecoration: "underline" }} className="color-white" to="/password-reset">
                          Esqueceu sua senha?
                        </Link>
                      </Grid>
                    </Grid>
                    {/*<Box mt={5}>*/}
                    {/*  <Copyright isWhite="" />*/}
                    {/*</Box>*/}
                  </form>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
