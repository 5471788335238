import { auth } from "../../services/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { clearErrors } from "./authenticationHelpers";

export const handleLogin = async ({ email, password, setEmailError, setPasswordError, setLoading }) => {
  clearErrors({ setEmailError, setPasswordError });
  await signInWithEmailAndPassword(auth, email, password)
    .catch((err) => {
      switch (err.code) {
        case "auth/invalid-email":
        case "auth/user-disabled":
          setEmailError("Sorry, you cannot commonPages at the moment");
          break;
        case "auth/user-not-found":
          setEmailError(err.message);
          break;
        case "auth/wrong-password":
          setPasswordError(err.message);
          break;
        default:
          return 0;
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
