import React, { useState } from "react";
import { Button, Fade, Menu, MenuItem, Typography } from "@material-ui/core";

//fix it
export default function DataPicker({ listToRender, defaultItem, onSelect, isInput }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [chosenItem, setChosenItem] = useState(defaultItem);

  // console.log("listToRender", listToRender, "defaultItem", defaultItem);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (key) => {
    if (key) {
      setChosenItem(key);
    }
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Button
        className={
          isInput && parseInt(chosenItem.split("timeValue")[1]) > 0
            ? "data-picker blue-gradient"
            : "data-picker dark-blue-gradient"
        }
        variant="contained"
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Typography style={{ textAlign: "center" }} variant="subtitle1" component="subtitle1">
          {listToRender[chosenItem]}
        </Typography>
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => {
          handleClose(false);
        }}
        TransitionComponent={Fade}
      >
        {Object.keys(listToRender).map((listItem, key) => {
          return (
            <MenuItem
              key={key + 1}
              onClick={() => {
                handleClose(listItem);
                // onSelect(listItem.match(/\d+/)[0]); OLD VERSION
                onSelect(listItem);
              }}
            >
              {listToRender[listItem]}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
}
