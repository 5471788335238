import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, Grid, Button } from "@material-ui/core";
import TimerTwoToneIcon from "@material-ui/icons/TimerTwoTone";
import CheckIcon from "@material-ui/icons/Check";

export default function ConfirmDedaStartPopup({ openPopup, onClose, closePopup }) {
  const [buttonsDisabled, setButtonsDisabled] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setButtonsDisabled(false);
    }, 15000);
  }, []);

  const btnWidth = "13rem";
  const btnFontSize = "1.8rem";

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth="md"
        open={openPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <Grid container direction="column" alignItems="center">
            <Grid item xs={12}>
              <iframe
                style={{ borderRadius: "1rem" }}
                src="https://player.vimeo.com/video/678667093?h=4127a732ca"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                width="640"
                height="360"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justifyContent="center">
            <Grid item>
              <Button
                style={{ width: btnWidth, borderRadius: "1rem" }}
                className="danger-background primary-title-dark"
                onClick={() => {
                  closePopup(false);
                }}
              >
                <Grid container direction="row" alignItems="center" justifyContent="space-around">
                  <Grid item xs={10}>
                    <h4 style={{ fontSize: btnFontSize }}>NOT YET</h4>
                  </Grid>
                  <Grid item xs={2}>
                    <TimerTwoToneIcon style={{ fontSize: "2.1rem" }} />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            <Grid item>
              <Button
                style={{ width: btnWidth, borderRadius: "1rem" }}
                className="success-background primary-title-dark"
                disabled={buttonsDisabled}
                onClick={() => {
                  onClose();
                }}
              >
                <Grid container direction="row" alignItems="center" justifyContent="space-around">
                  <Grid item xs={10}>
                    <h4 style={{ fontSize: btnFontSize }}>START</h4>
                  </Grid>
                  <Grid item xs={2}>
                    <CheckIcon style={{ fontSize: "2.1rem" }} />
                  </Grid>
                </Grid>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
