import React, { useContext, useState } from "react";
import { Avatar, IconButton, makeStyles } from "@material-ui/core";
import { UserContext } from "../../../../shared/providers";
import ContentLoading from "../atoms/ContentLoading";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    "&:hover": {
      cursor: "pointer",
    },
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    filter: "alpha(opacity=0)",
    "-moz-opacity": 0,
    "-khtml-opacity": 0,
    opacity: 0,
    transition: "opacity 0.3s ease-in-out",
    borderRadius: "150px",
    "&:hover": {
      filter: "alpha(opacity=100)",
      "-moz-opacity": 1,
      "-khtml-opacity": 1,
      opacity: 1,
    },
  },
  text: {
    color: "#fff",
  },
  wrapper: {
    position: "relative",
  },
  removePhotoButton: {
    position: "absolute",
    bottom: "-20px",
    right: "-30px",
    backgroundColor: "#1d212e",
    "&:hover": {
      backgroundColor: "red",
    },
  },
}));

function ProfilePhotoUploader() {
  const { newVersionUserDocData, setNewVersionUserDocData } = useContext(UserContext);
  const { userData } = newVersionUserDocData;
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (event) => {
    setIsLoading(true);
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("photo", file);
    formData.append("userUid", userData.uid);

    fetch(`${process.env.REACT_APP_SERVICE_ACCOUNT_MANAGER_API}upload-profile-photo`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "melp-token": process.env.REACT_APP_MELP_REQUEST_TOKEN,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        // Update avatar's src with the URL of the uploaded photo
        setNewVersionUserDocData({
          ...newVersionUserDocData,
          userData: {
            ...userData,
            profilePhotoUrl: response.fileUrl,
          },
        });
        setIsLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
        setIsLoading(false);
      });
  };

  const handlePhotoDelete = (event) => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_SERVICE_ACCOUNT_MANAGER_API}upload-profile-photo`, {
      method: "DELETE",
      mode: "cors",
      cache: "no-cache",
      headers: {
        "melp-token": process.env.REACT_APP_MELP_REQUEST_TOKEN,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userUid: userData.uid,
      }),
    })
      .then((response) => response.json())
      .then(() => {
        setNewVersionUserDocData({
          ...newVersionUserDocData,
          userData: {
            ...userData,
            profilePhotoUrl: null,
          },
        });
        setIsLoading(false);
      })
      .then((response) => {
        alert(response.message);
      })
      .catch((error) => {
        console.error(error.message);
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <ContentLoading isLoading={isLoading} />;
  } else {
    return (
      <React.Fragment>
        <input accept="image/*" style={{ display: "none" }} id="upload-photo" type="file" onChange={handleFileChange} />
        <div className={classes.wrapper}>
          <label className={classes.root} htmlFor="upload-photo">
            <Avatar
              src={userData.profilePhotoUrl ? userData.profilePhotoUrl.split("?")[0] : undefined}
              className="dark-background"
              style={{
                width: "150px",
                height: "150px",
                imageRendering: "pixelated",
              }}
            />
            <div className={`${classes.overlay}`}>
              <h6 className={classes.text}>Update Photo</h6>
            </div>
          </label>
          {userData.profilePhotoUrl && (
            <IconButton className={classes.removePhotoButton} component="span" onClick={handlePhotoDelete}>
              <DeleteForeverIcon style={{ color: "white" }} />
            </IconButton>
          )}
        </div>
      </React.Fragment>
    );
  }
}

export default ProfilePhotoUploader;
