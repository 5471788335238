import React, { useContext, useEffect, useState } from "react";
import "../../../assets/css/lamp/dayPicker.css";

import { Grid, Menu, MenuItem, CircularProgress, Paper } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { UserContext } from "../../../../shared/providers";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

import { unlockedDedasListMaker } from "../../../utils/dedasListMaker";
import { ComponentTitle } from "../../sharedComponents";

const getWeekDaysList = (currentTime, chosenWeek, isAccountPaused) => {
  let weekDaysList = {};
  const weekDaysObjects = [
    { day1: "Monday" },
    { day2: "Tuesday" },
    { day3: "Wednesday" },
    { day4: "Thursday" },
    { day5: "Friday" },
    { day6: "Saturday" },
    { day7: "Sunday" },
  ];

  if (parseInt(chosenWeek.slice(4)) === currentTime.currentWeek && !isAccountPaused) {
    const currentWeekDay = currentTime.currentDay % 7 === 0 ? 7 : currentTime.currentDay % 7;

    for (let i = 0; i < currentWeekDay; i++) {
      weekDaysList = {
        ...weekDaysList,
        ...weekDaysObjects[i],
      };
    }
  } else {
    weekDaysList = {
      day1: "Monday",
      day2: "Tuesday",
      day3: "Wednesday",
      day4: "Thursday",
      day5: "Friday",
      day6: "Saturday",
      day7: "Sunday",
    };
  }

  return weekDaysList;
};

export default function DayPicker() {
  const { chosenWeek, setChosenWeek, chosenWeekDay, setChosenWeekDay, newVersionUserDocData, currentTime } =
    useContext(UserContext);

  const [isListLoading, setIsListLoading] = useState(true);
  const weekDaysList = getWeekDaysList(
    newVersionUserDocData.currentTime,
    chosenWeek,
    newVersionUserDocData.accountStatus.pause.isAccountPaused,
  );

  const { isLampLoading } = useContext(UserContext);

  //@todo make this shared in a context api
  const [currentDedaList, setCurrentDedaList] = useState({});

  useEffect(() => {
    unlockedDedasListMaker(newVersionUserDocData.unlockedDEDAs).then((list) => {
      setCurrentDedaList(list);
    });
  }, []);

  useEffect(() => {
    if (chosenWeekDay && chosenWeek && !weekDaysList[chosenWeekDay]) {
      const { currentTime } = newVersionUserDocData;
      const { currentDay } = currentTime;

      const currentDayProcessed = "day" + (currentDay % 7 === 0 ? 7 : currentDay % 7);
      setChosenWeekDay(currentDayProcessed);
    }
  }, [chosenWeekDay, chosenWeek, weekDaysList]);

  const [anchorWeek, setAnchorWeek] = useState(null);
  const [anchorWeekDay, setAnchorWeekDay] = useState(null);

  const handleCloseWeek = (key) => {
    if (key && key !== chosenWeek) {
      setIsListLoading(true);
      setChosenWeek("week" + key);
    }
    setAnchorWeek(null);
  };

  const handleCloseDay = (key) => {
    if (key && key !== chosenWeekDay) {
      setChosenWeekDay("day" + key);
    }
    setAnchorWeekDay(null);
  };

  return (
    <React.Fragment>
      <Paper className="paper-props paper-shape paper-secondary smaller-padding">
        <Grid container direction="column" justifyContent="space-between" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="center">
              <Grid item>
                <CalendarTodayIcon fontSize="medium" />
              </Grid>
              <Grid item>
                <ComponentTitle>Pick your DEDA date</ComponentTitle>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            item
            xs={12}
          >
            {isLampLoading ? (
              <Grid container direction="row" justifyContent="center">
                <Grid
                  style={{
                    textAlign: "center",
                  }}
                  item
                  xs={12}
                >
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <Grid id="day-picker" container direction="row" justifyContent="center" alignItems="center" spacing={1}>
                <Grid item xs={12} md={6}>
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <Grid container item direction="row" xs={12} justifyContent="center" alignItems="center">
                      <Paper className="paper-shape paper-props smaller-padding full-width">
                        {isLampLoading ? (
                          <CircularProgress />
                        ) : (
                          <div
                            className="menu-button pointer"
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) => {
                              setAnchorWeek(event.currentTarget);
                            }}
                          >
                            <h6
                              className="item-hover-background"
                              style={{
                                textAlign: "center",
                              }}
                            >
                              <strong>{currentDedaList[`${chosenWeek}`]}</strong>{" "}
                              <ArrowDropDownIcon
                                style={{
                                  fontSize: "1rem",
                                }}
                              />
                            </h6>
                          </div>
                        )}
                      </Paper>
                      <Menu
                        id="simple-menu"
                        anchorEl={anchorWeek}
                        keepMounted
                        open={Boolean(anchorWeek)}
                        onClose={() => {
                          handleCloseWeek(false);
                        }}
                      >
                        {Object.keys(currentDedaList)
                          .filter((week) => week !== "week0")
                          .map((week, key) => {
                            const theWeek = key + 1;
                            return (
                              <MenuItem
                                key={key + 1}
                                onClick={() => {
                                  handleCloseWeek(theWeek);
                                }}
                              >
                                {currentDedaList[week]}
                              </MenuItem>
                            );
                          })}
                      </Menu>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper className="paper-shape paper-props smaller-padding full-width">
                    <div
                      className="pointer"
                      aria-controls="week-menu"
                      aria-haspopup="true"
                      onClick={(event) => {
                        setAnchorWeekDay(event.currentTarget);
                      }}
                    >
                      <h6
                        className="item-hover-background"
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <strong>{isLampLoading ? <CircularProgress /> : weekDaysList[`${chosenWeekDay}`]}</strong>{" "}
                        <ArrowDropDownIcon
                          style={{
                            fontSize: "1rem",
                          }}
                        />
                      </h6>
                    </div>
                  </Paper>
                  <Menu
                    id="week-menu"
                    anchorEl={anchorWeekDay}
                    keepMounted
                    open={Boolean(anchorWeekDay)}
                    onClose={() => {
                      handleCloseDay(false);
                    }}
                  >
                    {weekDaysList !== null || (!isListLoading && !isLampLoading)
                      ? Object.keys(weekDaysList).map((day, key) => {
                          const theDay = key + 1;
                          return (
                            <MenuItem
                              key={key + 1}
                              onClick={() => {
                                handleCloseDay(theDay);
                              }}
                            >
                              {weekDaysList[day]}
                            </MenuItem>
                          );
                        })
                      : null}
                  </Menu>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
