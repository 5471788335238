import React, { useContext, useState } from "react";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { NotificationContext, UserContext } from "../../../shared/providers";

import Button from "@material-ui/core/Button";
import LoadingScreen from "../../components/lampComponents/atoms/LoadingScreen";
import Copyright from "../../components/sharedComponents/atoms/Copyright";
import {
  resetAccount,
  pauseAccount,
  requestPasswordChange,
} from "../../utils/apiCalls/accountManagerCalls/accountStatuses";
import { differenceInDays } from "date-fns";

import PopUpAlert from "../../components/sharedComponents/molecules/PopUpAlert";
import axios from "axios";
import { AppVersion, ProfilePhotoUploader } from "../../components/sharedComponents";

const timestampToDate = (timestamp) => {
  return new Date(timestamp._seconds * 1000);
};

export default function Profile({ scrollTargetRef }) {
  const { newVersionUserDocData, isLoading, setLoading } = useContext(UserContext);
  const { userData, accountStatus, settings, dedaConfirmedAndStarted } = newVersionUserDocData;

  const [pausePopup, setPausePopup] = useState(false);
  const [resetPopup, setResetPopup] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { setNotification } = useContext(NotificationContext);

  const onOptionChange = (message) => {
    setNotification({
      isOpen: true,
      notificationText: message ? message : "Remember to save your changes before leaving",
      notificationType: "info",
    });
  };

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <main ref={scrollTargetRef} className={"light"}>
          <div id="main-dashboard-container">
            <div className="main-panel">
              <div id="main-content-welcome" className="main-content">
                <Grid container direction="column" justifyContent="center" alignItems="center" spacing={3}>
                  {isLoading ? (
                    <CircularProgress />
                  ) : (
                    <React.Fragment>
                      <Grid item xs={12} className="full-width">
                        <Grid container direction="row" spacing={4} alignItems="stretch">
                          {/*Column with the user data*/}
                          <Grid item xs={12} md={4}>
                            <Paper className="paper-props paper-shape">
                              <Grid container direction="column" spacing={4} alignItems="center">
                                <Grid item xs={12} className="bottom-content-margin">
                                  <ProfilePhotoUploader />
                                </Grid>
                                {!userData.profilePhotoUrl && <p>*Click to upload your photo</p>}
                                {/*User details*/}
                                <Grid item xs={12} className="full-width">
                                  <Grid container direction="column" spacing={2} alignItems="flex-start">
                                    <Grid item>
                                      <h2>User details</h2>
                                    </Grid>
                                    <Grid item>
                                      <h4>
                                        Name: <strong>{userData.firstName + " " + userData.lastName}</strong>
                                      </h4>
                                    </Grid>
                                    <Grid item>
                                      <h4>
                                        Email:{" "}
                                        <strong>
                                          {userData.email.length >= 23
                                            ? userData.email.substr(0, 23) + "..."
                                            : userData.email}
                                        </strong>
                                      </h4>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                {/*  Account details*/}
                                <Grid item xs={12} className="bottom-content-margin full-width">
                                  <Grid container direction="column" spacing={2} alignItems="flex-start">
                                    <Grid item>
                                      <h2>Account details</h2>
                                    </Grid>
                                    <Grid item>
                                      <h4>
                                        Payment status: <strong>{accountStatus.payment}</strong>
                                      </h4>
                                    </Grid>
                                    <Grid item>
                                      <h4>
                                        Purchase date:{" "}
                                        <strong>
                                          {timestampToDate(accountStatus.purchaseDate).toLocaleDateString()}
                                        </strong>
                                      </h4>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>

                          {/*column with other profile options*/}
                          <Grid item xs={12} md={8}>
                            <Paper className="paper-props paper-shape full-height">
                              <Grid item>
                                <h2>
                                  <strong>Settings</strong>
                                </h2>
                              </Grid>
                              <Grid container direction="column" spacing={4} style={{ marginTop: "2rem" }}>
                                {/*  App settings*/}
                                {/*<Grid item xs={12}>*/}
                                {/*  <Grid container direction="column" spacing={2} alignItems="flex-start">*/}
                                {/*    <Grid item>*/}
                                {/*      <h3>App settings</h3>*/}
                                {/*    </Grid>*/}
                                {/*    */}
                                {/*  </Grid>*/}
                                {/*</Grid>*/}

                                {/* account settings */}
                                <Grid item xs={12}>
                                  <Grid container direction="column" spacing={2} alignItems="flex-start">
                                    <Grid item>
                                      <h3>Account settings</h3>
                                    </Grid>
                                    {accountStatus.dedaStart.isDedaStartConfirmed &&
                                      dedaConfirmedAndStarted &&
                                      differenceInDays(
                                        new Date(),
                                        timestampToDate(
                                          accountStatus.dedaStart.startDates[
                                            accountStatus.dedaStart.startDates.length - 1
                                          ],
                                        ),
                                      ) > 7 && (
                                        <React.Fragment>
                                          <Grid item>
                                            <Grid container direction="row" spacing={2} alignItems="flex-end">
                                              <Grid item>
                                                <h4>
                                                  Remaining pauses:{" "}
                                                  <strong>
                                                    {settings.remainingPauses === 0
                                                      ? "You don't have any pauses left anymore"
                                                      : settings.remainingPauses}
                                                  </strong>
                                                </h4>
                                              </Grid>
                                              {settings.remainingPauses > 0 && (
                                                <Grid item>
                                                  <PopUpAlert
                                                    popMessage={{
                                                      title: "Atenção!",
                                                      content:
                                                        "Ao pausar a sua conta, TODO o progresso desta semana atual será perdido e você só poderá continuar semana que vem.",
                                                    }}
                                                    openPopup={pausePopup}
                                                    closePopup={setPausePopup}
                                                    onClose={async () => {
                                                      setLoading(true);
                                                      await pauseAccount(newVersionUserDocData.userData.uid)
                                                        .then(() => {
                                                          window.location.reload();
                                                        })
                                                        .catch((error) => {
                                                          throw error;
                                                        });
                                                    }}
                                                  />
                                                  <Button
                                                    style={{
                                                      marginTop: "1rem",
                                                    }}
                                                    className="access-button"
                                                    onClick={() => {
                                                      setPausePopup(true);
                                                    }}
                                                  >
                                                    Pause account
                                                  </Button>
                                                </Grid>
                                              )}
                                            </Grid>
                                          </Grid>
                                          {settings.remainingPauses > 0 && (
                                            <Grid item>
                                              <p>
                                                *If you wish to make a pause on DEDA program, you can use your remaining
                                                pauses to do so.
                                              </p>
                                            </Grid>
                                          )}
                                        </React.Fragment>
                                      )}
                                    <Grid item>
                                      <Grid container direction="row" spacing={2} alignItems="flex-end">
                                        <Grid item>
                                          <h4>
                                            Remaining account resets:{" "}
                                            <strong>
                                              {settings.remainingResetAttempts === 0
                                                ? "You don't have any resets left anymore"
                                                : settings.remainingResetAttempts}
                                            </strong>
                                          </h4>
                                        </Grid>
                                        {settings.remainingResetAttempts > 0 && (
                                          <Grid item>
                                            <PopUpAlert
                                              popMessage={{
                                                title: "Atenção!",
                                                content:
                                                  "Ao resetar a sua conta, TODO o progresso e conteúdo será APAGADO. Você recomeçará o MELP do ínicio com a data de hoje.",
                                              }}
                                              openPopup={resetPopup}
                                              closePopup={setResetPopup}
                                              onClose={async () => {
                                                setLoading(true);
                                                await resetAccount(newVersionUserDocData.userData.uid)
                                                  .then(() => {
                                                    window.location.reload();
                                                  })
                                                  .catch((error) => {
                                                    throw error;
                                                  });
                                              }}
                                            />
                                            <Button
                                              style={{ marginTop: "1rem" }}
                                              className="access-button"
                                              onClick={() => {
                                                setResetPopup(true);
                                              }}
                                            >
                                              Reset account
                                            </Button>
                                          </Grid>
                                        )}
                                      </Grid>
                                    </Grid>
                                    {settings.remainingResetAttempts > 0 && (
                                      <Grid item>
                                        <p>
                                          *If you wish to reset your account to restart the program today, you can use
                                          your remaining resets to do so.
                                        </p>
                                      </Grid>
                                    )}
                                    <Grid item>
                                      <Grid container direction="column" spacing={2} alignItems="flex-start">
                                        <Grid item>
                                          <h4>Change account's password</h4>
                                        </Grid>
                                        <Grid item>
                                          {/*<form*/}
                                          {/*  onSubmit={(event) => {*/}
                                          {/*    event.preventDefault();*/}
                                          {/*  }}*/}
                                          {/*>*/}
                                          {/*  <input*/}
                                          {/*    type="password"*/}
                                          {/*    className="form-field"*/}
                                          {/*    required*/}
                                          {/*    id="password"*/}
                                          {/*    placeholder="New password"*/}
                                          {/*    name="userPassword"*/}
                                          {/*    autoComplete="password"*/}
                                          {/*    autoFocus*/}
                                          {/*    value={passwordChange}*/}
                                          {/*    onChange={(event) => {*/}
                                          {/*      setPasswordChange(event.target.value);*/}
                                          {/*    }}*/}
                                          {/*  />*/}
                                          {isEmailSent === false ? (
                                            <Button
                                              style={{ marginTop: "1rem" }}
                                              className="access-button"
                                              onClick={() => {
                                                requestPasswordChange(newVersionUserDocData?.userData?.uid)
                                                  .then(() => {
                                                    setIsEmailSent("Email sent");
                                                  })
                                                  .catch((error) => {
                                                    throw error;
                                                  });
                                              }}
                                            >
                                              Request password change
                                            </Button>
                                          ) : (
                                            <p>{isEmailSent}</p>
                                          )}
                                          {/*</form>*/}
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} className="full-width">
                        <Grid container direction="row" justifyContent="center" alignItems="stretch">
                          <Grid item xs={12}>
                            <Paper
                              className="paper-props paper-shape text-center"
                              style={{
                                paddingTop: "1rem",
                                paddingBottom: "1rem",
                              }}
                            >
                              <AppVersion />
                              <Copyright />
                            </Paper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
              </div>
            </div>
          </div>
        </main>
      )}
    </React.Fragment>
  );
}
