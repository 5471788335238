import React from "react";
import { Performance, Goal } from "../views";
import { PerformanceProvider } from "../../../../shared/providers";
import Input from "../views/Input";

const viewsList = {
  goal: <Goal />,
  performance: (
    <PerformanceProvider>
      <Performance />
    </PerformanceProvider>
  ),
  input: <Input />,
};

export default function ViewRender({ renderView }) {
  return <React.Fragment>{viewsList[renderView]}</React.Fragment>;
}
