import React from "react";
import CreateIcon from "@material-ui/icons/Create";
import PieChartIcon from "@material-ui/icons/PieChart";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import ClassIcon from "@material-ui/icons/Class";
import { Grid, Typography } from "@material-ui/core";

export default function WidgetTitle({ titleName, titleIcon }) {
  const icons = {
    continueWork: <CreateIcon fontSize="large" />,
    lamp: <PieChartIcon fontSize="large" />,
    dedas: <VerticalSplitIcon fontSize="large" />,
    hpecs: <ClassIcon fontSize="large" />,
  };

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item>{icons[titleIcon]}</Grid>
      <Grid item>
        <Typography variant="h5" component="h5">
          <strong>&nbsp;{titleName}</strong>
        </Typography>
      </Grid>
    </Grid>
  );
}
