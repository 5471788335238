import React, { useContext } from "react";
import { CircularProgress, Grid, Paper } from "@material-ui/core";
import { UserContext } from "../../../../shared/providers";

export default function TargetIndex({ title, index }) {
  const { isLampLoading } = useContext(UserContext);

  return (
    <React.Fragment>
      <Paper className="paper-props paper-shape">
        <Grid container direction="column" alignItems="center" style={{ textAlign: "center" }}>
          <Grid item xs={12}>
            <h6>
              <strong>{title}</strong>
            </h6>
          </Grid>
          <Grid item xs={12}>
            <h5>
              <strong>
                {isLampLoading || typeof index !== "number" ? <CircularProgress /> : index.toFixed(2) + "%"}
              </strong>
            </h5>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
