export const createWeeklyStatsDataObject = (rawData, chartChosenWeek) => {
  const chartChosenWeekNumber = parseInt(chartChosenWeek.substr(4));

  const dataArray = [];
  const axisArray = ["DEDA", "REVIEW", "ACTIVE", "PASSIVE"];
  const statisticsNameArray = ["deda", "review", "active", "passive"];
  const borderColors = ["rgb(215,29,66)", "rgb(207,169,30)", "rgb(29,100,215)", "rgb(29,172,132)"];
  const backgroundColors = [
    "rgb(215,29,66, 0.2)",
    "rgb(207,169,30, 0.2)",
    "rgb(29,100,215, 0.2)",
    "rgb(29,172,132, 0.2)",
  ];

  if (chartChosenWeekNumber < 4) {
    axisArray.splice(1, 1);
    statisticsNameArray.splice(1, 1);
    backgroundColors.splice(1, 1);
    borderColors.splice(1, 1);
  }

  statisticsNameArray.forEach((statistic, index) => {
    dataArray[index] = parseFloat(rawData[statistic]).toFixed(2);
  });

  return {
    labels: axisArray,
    datasets: [
      {
        label: false,
        data: dataArray,
        borderColor: borderColors,
        backgroundColor: backgroundColors,
        borderWidth: 3,
        minBarLength: 4,
      },
    ],
  };
};
