import React, { useContext } from "react";
import { Route, BrowserRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import SignIn from "./commonPages/SignIn";
import PasswordReset from "./commonPages/PasswordReset";
import LoadingPage from "./commonPages/LoadingPage";
import { UserContext } from "./shared/providers";
import UpdatedApp from "./MELPv3/UpdatedApp";
import PasswordRecovery from "./commonPages/PasswordRecovery";
import HelpPage from "./commonPages/HelpPage";

function App() {
  const { user, isLoading, newVersionUserDocData } = useContext(UserContext);
  const history = createBrowserHistory();

  return isLoading ? (
    <LoadingPage />
  ) : newVersionUserDocData.userData && !isLoading ? (
    <UpdatedApp />
  ) : (
    !user &&
    !isLoading && (
      <BrowserRouter history={history}>
        <Route exact path="/" children={<SignIn />} />
        <Route exact path="/password-reset" children={<PasswordReset />} />
        <Route exact path="/password-reset" children={<PasswordReset />} />
        <Route exact path="/password-recovery" children={<PasswordRecovery />} />
        <Route exact path="/help" children={<HelpPage />} />
        {/*<Route exact path="*" children={<NotFound />} />*/}
      </BrowserRouter>
    )
  );
}

export default App;
