import React, { useEffect, useMemo } from "react";
import styled from "@emotion/styled";
import { useDeda } from "../../../hooks";
import { HeadingText, MagicLink, ParagraphText, PodcastFrame, DedaContentArticle, BuildingMessage } from "../atoms";
import { TextContentRender } from "../molecules";
import ContentLoading from "../../sharedComponents/atoms/ContentLoading";

const DedaNotesQuote = styled.div`
  align-self: center;
`;

const DedaNotesMotivationQuote = styled.div`
  align-self: center;
  & > * {
    font-size: 2.5rem;
    font-weight: 700;
  }
`;

const DedaNotesImage = styled.img`
  border-radius: 1rem;
  width: 100%;
  aspect-ratio: auto;
  align-self: center;

  &.end-image {
    width: 40%;
  }
`;

const LinKnowledge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  gap: 1.3rem;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const PodcastSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: stretch;
  width: 100%;
`;

function DedaNotes({ dedaId }) {
  const dedaNotesResult = useDeda("dedaNotesQuery", dedaId);
  const { loading, data, error } = dedaNotesResult;

  const dedaNotesContent = useMemo(() => data?.dedaContentCollection?.items[0], [data]);

  useEffect(() => {
    if (data?.dedaContentCollection?.items?.length === 0) {
      console.error("not found");
    }
  }, [data]);

  if (!loading && data?.dedaContentCollection?.items?.length === 0) {
    return <BuildingMessage />;
  }

  if (loading || data?.dedaContentCollection?.items?.length === 0) {
    return <ContentLoading />;
  }

  if (error) {
    throw error;
  }

  const {
    dedaTitle,
    dedaNotesQuote,
    dedaNotesIntroductionContent,
    dedaNotesIntroductionMotivationQuote,
    dedaFeaturedImage,
    dedaNotesGlossaryContent,
    dedaNotesSecondaryImage,
    dedaNotesArticlesLinksCollection,
    dedaNotesVideosLinksCollection,
    dedaNotesPodcasts,
    dedaNotesEndImage,
  } = dedaNotesContent || {};

  return (
    <DedaContentArticle>
      <HeadingText hType="h1" fontWeight="700">
        DEDA NOTES
      </HeadingText>
      <div>
        <HeadingText hType="h3">INTRODUCTION</HeadingText>
      </div>
      <DedaNotesQuote>
        <TextContentRender rawContent={dedaNotesQuote?.json} links={dedaNotesQuote?.json?.links} />
      </DedaNotesQuote>
      <TextContentRender rawContent={dedaNotesIntroductionContent?.json} links={dedaNotesIntroductionContent?.links} />
      <DedaNotesMotivationQuote>
        <HeadingText hType="h2">{dedaNotesIntroductionMotivationQuote}</HeadingText>
      </DedaNotesMotivationQuote>
      <DedaNotesImage src={dedaFeaturedImage?.url} />
      <div>
        <HeadingText hType="h2">GLOSSARY</HeadingText>
      </div>
      <TextContentRender rawContent={dedaNotesGlossaryContent?.json} links={dedaNotesGlossaryContent?.links} />
      <DedaNotesImage src={dedaNotesSecondaryImage?.url} />
      <div>
        <HeadingText hType="h2">
          DEDA LIN<strong style={{ color: "red" }}>K</strong>NOWLEDGE
        </HeadingText>
      </div>
      <div>
        <ParagraphText>
          To complete our <strong>DEDA NOTES</strong> about <strong>&quot;{dedaTitle},&quot;</strong> please find below
          some interesting links to articles, videos, and podcasts that will help you to expand your knowledge on this
          topic:
        </ParagraphText>
      </div>
      <div>
        <HeadingText hType="h3">ARTICLES:</HeadingText>
      </div>
      <div>
        {dedaNotesArticlesLinksCollection?.items?.map((item, key) => (
          <LinKnowledge key={key}>
            <HeadingText hType="h2" fontWeight="700">
              DAY{key + 1}
            </HeadingText>
            <MagicLink magicLink={item.magicLinkUrl} magicLabel={item.magicLinkLabel} />
          </LinKnowledge>
        ))}
      </div>
      <div>
        <HeadingText hType="h3">VIDEOS:</HeadingText>
      </div>
      <div>
        {dedaNotesVideosLinksCollection?.items?.map((item, key) => (
          <LinKnowledge key={key}>
            <HeadingText hType="h2" fontWeight="700">
              DAY{key + 1}
            </HeadingText>
            <MagicLink magicLink={item.magicLinkUrl} magicLabel={item.magicLinkLabel} />
          </LinKnowledge>
        ))}
      </div>
      <div>
        <HeadingText hType="h3">PODCASTS</HeadingText>
      </div>
      <PodcastSection>
        {dedaNotesPodcasts?.map((podcastURL, key) => (
          <PodcastFrame key={key} src={podcastURL} />
        ))}
      </PodcastSection>
      <div>
        <HeadingText hType="h3">KEEP MOVING</HeadingText>
      </div>
      <DedaNotesQuote>
        <HeadingText hType="h4" variant="secondary">
          <strong>
            <em>
              &quot;Good, better, best. Never let it rest. Until your good is better and your better is best.&quot;
            </em>
          </strong>
          &nbsp;(St. Jerome)
        </HeadingText>
      </DedaNotesQuote>
      <DedaNotesImage className="end-image" src={dedaNotesEndImage?.url} />
    </DedaContentArticle>
  );
}

export default DedaNotes;
