import React, { useContext } from "react";
import { Grid, LinearProgress, Paper, Typography } from "@material-ui/core";
import { AccessLink, WidgetTitle } from "../atoms";
import { UserContext } from "../../../../shared/providers";

export default function DedaWidget({ dedaIdsList }) {
  const { newVersionUserDocData } = useContext(UserContext);
  const totalDedasAvailable = Object.keys(dedaIdsList)?.length || 1;
  const { currentTime } = newVersionUserDocData;
  const { currentWeek } = currentTime;

  const progressPercent =
    (currentWeek / totalDedasAvailable) * 100 > 100 ? 100 : (currentWeek / totalDedasAvailable) * 100;

  return (
    <React.Fragment>
      <Paper className="paper-shape paper-props paper-secondary widgets-height">
        <Grid
          container
          direction="column"
          xs={12}
          justifyContent="space-between"
          spacing={1}
          className="col-max-height"
        >
          <Grid item>
            <WidgetTitle titleName={"DEDA"} titleIcon={"dedas"} />
          </Grid>

          <Grid item>
            <Typography variant="h6">
              Progress:{" "}
              <strong>
                {currentWeek <= totalDedasAvailable ? currentWeek : totalDedasAvailable}/{totalDedasAvailable}
              </strong>
            </Typography>
          </Grid>

          <Grid item>
            <Grid container direction="row" xs={12} alignItems="center" spacing={1}>
              <Grid item xs={11}>
                <LinearProgress
                  id="deda-progress"
                  className="progress-background"
                  variant="determinate"
                  value={progressPercent}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="subtitle1">{progressPercent.toFixed(0)}%</Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AccessLink href={`deda/week${currentWeek}/dedaNotes`} />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
