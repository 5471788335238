import React, { useState, createContext, useContext, useEffect } from "react";
import { UserContext, ProfileContext, HpecContext } from "./index";
import { addDays } from "date-fns";
// import { hpecList } from "../utils/constants";
// import { dedasList } from "../utils/constants";

export const DatesContext = createContext();

export const DatesProvider = ({ children }) => {
  const [dates, setDates] = useState();
  const { currentDay, isUserLegacy, newVersionUserDocData } = useContext(UserContext);
  const { currentTime, accountStatus } = newVersionUserDocData;
  const { profileData } = useContext(ProfileContext);
  const { nextHpec } = useContext(HpecContext);

  const timestampToDate = (timestamp) => {
    return new Date(timestamp._seconds * 1000);
  };

  const getLegacyDates = () => {
    const calculateDays = (start, numberOfDays) => {
      const days = 24 * 3600 * 1000;
      return new Date(start.getTime() + numberOfDays * days);
    };
    const startDate = new Date(profileData.startDate);
    const dedaWeekNumber = currentDay.WEEK;
    const lampStartDate = new Date(profileData.lampStartDate);
    const dedaStartDate = new Date(profileData.dedaStartDate);
    const dedaWeekDate = calculateDays(dedaStartDate, dedaWeekNumber * 7);
    const nextDedaDate = calculateDays(dedaWeekDate, 7);
    const nextHpecDate = nextHpec ? calculateDays(startDate, nextHpec.drippingDay) : null;

    return {
      dedaWeekDate: dedaWeekDate.toLocaleDateString(),
      nextDedaDate: nextDedaDate.toLocaleDateString(),
      nextHpecDate: nextHpecDate.toLocaleDateString(),
      lampStartDate: lampStartDate.toLocaleDateString(),
      dedaStartDate: dedaStartDate.toLocaleDateString(),
    };
  };

  const getNewVersionDates = () => {
    // const melpStartDate = timestampToDate(accountStatus.melpStartDate);
    // const accountPurchaseDate = timestampToDate(accountStatus.purchaseDate);
    let nextHpecDate;

    if (accountStatus.dedaStart.isDedaStartConfirmed && nextHpec) {
      const daysUntilNextHpec = nextHpec.drippingDay - 9 - currentTime.currentDay;
      const today = new Date();
      nextHpecDate = addDays(today, daysUntilNextHpec);
      return {
        nextHpecDate: nextHpecDate.toLocaleDateString(),
      };
    }
  };

  useEffect(() => {
    if (currentDay && profileData && nextHpec && isUserLegacy) {
      const datesToSet = getLegacyDates();
      setDates(datesToSet);
    }
  }, [currentDay, profileData, nextHpec]);

  useEffect(() => {
    if (!isUserLegacy && nextHpec) {
      const datesToSet = getNewVersionDates();
      setDates(datesToSet);
    }
  }, [isUserLegacy, nextHpec]);

  return <DatesContext.Provider value={{ dates }}>{children}</DatesContext.Provider>;
};
