import React, { useState, createContext, useEffect } from "react";
import { authListener } from "../authentication/authenticationHelpers";
import { getUserData } from "../../MELPv3/utils/apiCalls/melpCalls/getUserData";
import { getAdminData } from "../../MELPv3/utils/apiCalls/accountManagerCalls/adminData";

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(true);
  const [currentDay, setCurrentDay] = useState(false);
  const [chosenWeek, setChosenWeek] = useState(false);
  const [chosenWeekDay, setChosenWeekDay] = useState(false);
  const [weekDaysList, setWeekDaysList] = useState(null);
  const [currentDedaList, setCurrentDedaList] = useState(false);
  const [isLampLoading, setIsLampLoading] = useState(true);
  const [hasSummaryCalled, setHasSummaryCalled] = useState(false);
  //new version user
  const [newVersionUserDocData, setNewVersionUserDocData] = useState({});
  const [masterAdmin, setMasterAdmin] = useState(false);
  const [isMasterAdminAccountSaved, setIsMasterAdminAccountSaved] = useState(false);
  const [isMasterAdminLoading, setIsMasterAdminLoading] = useState(true);

  const isUserLegacy = false;

  useEffect(() => {
    const fetchData = async () => {
      await authListener({
        setUser,
        setEmail,
        setPassword,
        setLoading,
      });
    };
    fetchData().catch((error) => {
      throw error;
    });
  }, []);

  useEffect(() => {
    const makeNewApiUserCall = async (userUid) => {
      return await getUserData(userUid).catch((error) => {
        throw error;
      });
    };

    if (user) {
      makeNewApiUserCall(user.uid)
        .then((userDocData) => {
          setNewVersionUserDocData(() => userDocData);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          throw error;
        });
    }
  }, [user]);

  //new API effect
  useEffect(() => {
    if (!isUserLegacy && user) {
      const { currentTime } = newVersionUserDocData;
      const { currentDay, currentWeek } = currentTime;

      const dayString = "day" + (currentDay % 7 === 0 ? 7 : currentDay % 7);
      const weekString = "week" + currentWeek;

      setChosenWeek(weekString);
      setChosenWeekDay(dayString);
    }
  }, [newVersionUserDocData]);

  useEffect(() => {
    const isMasterAdmin = async () => {
      if (
        !isUserLegacy &&
        user &&
        newVersionUserDocData &&
        newVersionUserDocData.userData.isAdmin &&
        !isMasterAdminAccountSaved
      ) {
        await getAdminData(newVersionUserDocData.userData.uid).then((response) => {
          setMasterAdmin({
            adminAccount: user,
            listOfUsers: response,
          });
          setIsMasterAdminLoading(false);
          setIsMasterAdminAccountSaved(true);
        });
      }
    };
    isMasterAdmin().catch((error) => {
      throw error;
    });
  }, [newVersionUserDocData]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        email,
        setEmail,
        password,
        setPassword,
        isLoading,
        setLoading,
        emailError,
        setEmailError,
        passwordError,
        setPasswordError,
        currentDay,
        setCurrentDay,
        chosenWeek,
        setChosenWeek,
        chosenWeekDay,
        setChosenWeekDay,
        weekDaysList,
        setWeekDaysList,
        currentDedaList,
        isLampLoading,
        setIsLampLoading,
        isUserLegacy,
        newVersionUserDocData,
        setNewVersionUserDocData,
        masterAdmin,
        isMasterAdminLoading,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
