import React, { useContext } from "react";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { ComponentTitle } from "../../../sharedComponents";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import DedaRating from "../DedaRating";
import TimeInputList from "../../atoms/TimeInputList";
import { dedaTimeList, readingTimeList } from "../../../../utils/constants";
import { InputDataContext, SettingsContext, UserContext } from "../../../../../shared/providers";
import { TargetIndex } from "../../atoms";

const dedaRatingItemStyle = {
  padding: "0 !important",
  marginBottom: "0 !important",
  height: "1rem !important",
  width: "38rem",
};

function DailyBlob({ dailyBlobTitle, dailyStatNumber, dailyTime, timeList }) {
  const { inputData, setInputData, setIsInputDataChanged } = useContext(InputDataContext);

  return (
    <Paper
      className={`paper-props paper-shape`}
      style={{
        minWidth: "10rem",
        minHeight: "7rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid
        container
        direction="column"
        spacing={4}
        justifyContent="space-between"
        alignItems="center"
        className="full-height"
      >
        <Grid item xs={12}>
          <h6>
            <strong>{dailyBlobTitle}</strong>
          </h6>
        </Grid>
        <Grid item xs={12}>
          <TimeInputList
            isInput
            onSelect={(theValue) => {
              setIsInputDataChanged(true);
              setInputData({
                ...inputData,
                dedaDaily: {
                  ...inputData.dedaDaily,
                  [dailyTime]: parseInt(theValue.slice(9)),
                },
                dedaEvaluation: {
                  ...inputData.inputData.dedaEvaluation,
                },
              });
            }}
            timeInputList={timeList}
            defaultItem={"timeValue" + dailyStatNumber}
          />
        </Grid>
      </Grid>
    </Paper>
  );
}

export default function DedaInput() {
  const { isLampLoading } = useContext(UserContext);
  const { inputData } = useContext(InputDataContext);
  const { isDesktop } = useContext(SettingsContext);

  return (
    <React.Fragment>
      <Paper className={"paper-props paper-shape paper-secondary"}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="stretch">
              <Grid item xs={2} md={1}>
                <AssignmentTurnedInIcon fontSize="large" />
              </Grid>
              <Grid item xs={5} md={8}>
                <ComponentTitle>DEDA</ComponentTitle>
                {isDesktop && (
                  <Typography variant="subtitle1">
                    How is the QUALITY of your relationship to DEDA? (From 1 to 5, score the quality of each of the five
                    variables for the day.)
                  </Typography>
                )}
              </Grid>
              <Grid item xs={5} md={3}>
                <TargetIndex
                  index={inputData && (inputData.inputData.dedaEvaluation.average / 5) * 100}
                  title="Quality index"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper className={"paper-props paper-shape"}>
              <Grid container direction="column" spacing={2} alignItems="stretch">
                <Grid item xs={12}>
                  <Grid container direction="column" alignItems="stretch">
                    {isLampLoading ? (
                      <Grid item xs={12}>
                        <CircularProgress />
                        <h5>Loading... Please wait</h5>
                      </Grid>
                    ) : (
                      <React.Fragment>
                        <Grid style={dedaRatingItemStyle} item xs={12}>
                          <DedaRating
                            initialRating={inputData && inputData.inputData.dedaEvaluation.predPlace}
                            ratingTitle={"Predetermined Place/Time"}
                            dedaEvaluationName="predPlace"
                          />
                        </Grid>
                        <Grid style={dedaRatingItemStyle} item xs={12}>
                          <DedaRating
                            initialRating={inputData && inputData.inputData.dedaEvaluation.fiveSteps}
                            ratingTitle={"Five steps (DEEP)"}
                            dedaEvaluationName="fiveSteps"
                          />
                        </Grid>
                        <Grid style={dedaRatingItemStyle} item xs={12}>
                          <DedaRating
                            initialRating={inputData && inputData.inputData.dedaEvaluation.stateMind}
                            ratingTitle={"State of mind"}
                            dedaEvaluationName="stateMind"
                          />
                        </Grid>
                        <Grid style={dedaRatingItemStyle} item xs={12}>
                          <DedaRating
                            initialRating={inputData && inputData.inputData.dedaEvaluation.stateBeing}
                            ratingTitle={"State of being"}
                            dedaEvaluationName="stateBeing"
                          />
                        </Grid>
                        <Grid style={dedaRatingItemStyle} item xs={12}>
                          <DedaRating
                            initialRating={inputData && inputData.inputData.dedaEvaluation.dedaFocus}
                            ratingTitle={"Focus"}
                            dedaEvaluationName="dedaFocus"
                          />
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <DailyBlob
                  dailyBlobTitle="Enter the reading time"
                  dailyStatNumber={inputData && inputData.dedaDaily.readingTime}
                  dailyTime="readingTime"
                  timeList={readingTimeList}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DailyBlob
                  dailyBlobTitle="Enter the DEDA time"
                  dailyStatNumber={inputData && inputData.dedaDaily.dedaTime}
                  dailyTime="dedaTime"
                  timeList={dedaTimeList}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
