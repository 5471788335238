import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import "../../assets/css/welcome/welcome.css";
import { ContinueWorkWidget, DedaWidget, PerformanceWidget, HpecWidget } from "../../components/welcomeComponents";
import { PerformanceContext, SettingsContext, UserContext } from "../../../shared/providers";
import LockIcon from "@material-ui/icons/Lock";
import Copyright from "../../components/sharedComponents/atoms/Copyright";
import { ComponentTitle } from "../../components/sharedComponents";
import { confirmDedaStart } from "../../utils/apiCalls/accountManagerCalls/accountStatuses";
import ConfirmDedaStartPopup from "../../components/sharedComponents/molecules/ConfirmDedaStartPopup";
import { getDedaIdsList } from "../../utils/apiCalls/deda/read/getDedasIdsList";

function LockedTile({ lockedMessage }) {
  return (
    <React.Fragment>
      <div>
        <Grid container direction="row">
          <Grid item xs={12}>
            <LockIcon />
            <Typography variant="h6">{lockedMessage}</Typography>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
}

function WidgetsGrid({ dedaIdsList }) {
  const { newVersionUserDocData } = useContext(UserContext);
  const { isPerformanceLoading } = useContext(PerformanceContext);
  const { userData, accountStatus, dedaConfirmedAndStarted, currentTime } = newVersionUserDocData;
  const { dedaStart, pause } = accountStatus;

  return (
    <React.Fragment>
      <Paper className={"paper-shape paper-props"}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">
              {newVersionUserDocData && <ComponentTitle>Welcome, {userData.firstName}!</ComponentTitle>}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} alignItems="stretch">
              <Grid item xs={12} md={6}>
                <ContinueWorkWidget dedaIdsList={dedaIdsList} />
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className={
                    !(
                      (dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
                      pause.isAccountPaused ||
                      dedaStart.startDates.length >= 2
                    ) && "blocked-content"
                  }
                >
                  {(dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
                  pause.isAccountPaused ||
                  dedaStart.startDates.length >= 2 ? (
                    isPerformanceLoading ? (
                      <CircularProgress />
                    ) : (
                      <PerformanceWidget />
                    )
                  ) : (
                    <LockedTile lockedMessage="LAMP will unlock once you confirm the start of DEDA" />
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6}>
                <HpecWidget />
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className={
                    // !accountStatus.dedaStart.isDedaStartConfirmed &&
                    // !accountStatus.pause.isAccountPaused &&
                    !(currentTime.daysSinceMelpBegin >= 2) && "blocked-content"
                  }
                >
                  {
                    //   accountStatus.dedaStart.isDedaStartConfirmed ||
                    // (!accountStatus.dedaStart.isDedaStartConfirmed && accountStatus.pause.isAccountPaused)
                    currentTime.daysSinceMelpBegin >= 2 ? (
                      <DedaWidget dedaIdsList={dedaIdsList} />
                    ) : (
                      <LockedTile lockedMessage="DEDA will unlock as you go through HPEC" />
                    )
                  }
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}

export default function WelcomeHome({ scrollTargetRef }) {
  const { newVersionUserDocData, setLoading } = useContext(UserContext);
  const { currentTime, accountStatus } = newVersionUserDocData;
  const [canStartDedaPopup, setCanStartDedaPopup] = useState(
    currentTime.daysSinceMelpBegin >= 9 && accountStatus.dedaStart.startDates.length === 0,
  );

  const [dedaIdsList, setDedaIdsList] = useState({});

  useEffect(() => {
    getDedaIdsList().then((list) => {
      setDedaIdsList(list);
    });
  }, []);

  return (
    <React.Fragment>
      <ConfirmDedaStartPopup
        openPopup={canStartDedaPopup}
        closePopup={setCanStartDedaPopup}
        onClose={async () => {
          setLoading(true);
          await confirmDedaStart(newVersionUserDocData.userData.uid)
            .then(() => {
              window.location.reload();
            })
            .catch((error) => {
              throw error;
            });
        }}
      />
      <main
        ref={scrollTargetRef}
        // style={{ overflowY: isDesktop ? "hidden" : "scroll" }}
        className={"light"}
      >
        <div id="main-dashboard-container">
          <div className="main-panel module-panel">
            <div id="main-content-deda" className="bottom-content-margin">
              <Grid style={{ width: "100%" }} container direction="column" xs={12} alignItems="stretch" spacing={1}>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="space-between" alignItems="stretch" spacing={2}>
                    {/*Widgets column*/}
                    <Grid
                      item
                      xs={12}
                      // sm={8}
                      style={{
                        width: "100%",
                      }}
                    >
                      <WidgetsGrid dedaIdsList={dedaIdsList} />
                    </Grid>

                    {/*List column*/}
                    {/*<Grid item xs={12} sm={4}>*/}
                    {/*  <Grid*/}
                    {/*    container*/}
                    {/*    direction="column"*/}
                    {/*    alignItems="stretch"*/}
                    {/*    style={{*/}
                    {/*      height: "100%",*/}
                    {/*    }}*/}
                    {/*  >*/}
                    {/*    <Grid className="full-height" item>*/}
                    {/*      <LeaderBoardWidget />*/}
                    {/*    </Grid>*/}
                    {/*  </Grid>*/}
                    {/*</Grid>*/}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Copyright />
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
