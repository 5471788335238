import React from "react";
import { Grid, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";
import { subMenuList } from "../../../utils/constants";

export default function DedaSubMenu({ dedaWeek, isBlocked }) {
  return (
    <React.Fragment>
      {isBlocked
        ? Object.keys(subMenuList).map((subMenuItem) => {
            return (
              <Grid
                className="list-option"
                style={{ padding: "0.6rem" }}
                container
                direction="row"
                spacing={0}
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    paddingLeft: "0.4rem",
                  }}
                >
                  <LockIcon />
                </Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: "0.4rem",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "400",
                    }}
                    variant="subtitle1"
                  >
                    {subMenuList[subMenuItem]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        : Object.keys(subMenuList).map((subMenuItem) => {
            return (
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to={`/deda/${dedaWeek}/${subMenuItem}`}
              >
                <Grid
                  className="list-option"
                  style={{ padding: "0.6rem" }}
                  container
                  direction="row"
                  spacing={0}
                  alignItems="center"
                >
                  <Grid
                    item
                    style={{
                      paddingLeft: "0.4rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "400",
                      }}
                      variant="subtitle1"
                    >
                      {subMenuList[subMenuItem]}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            );
          })}
    </React.Fragment>
  );
}
