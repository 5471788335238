import styled from "@emotion/styled";
import React from "react";

const IFrame = styled.iframe`
  border: none;
  border-radius: 1rem;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "50%")};
  min-height: 30rem;
  align-self: center;
  margin-bottom: 3rem;
`;

const EmbedVideo = ({ videoUrl, fullWidth = false }) => {
  return (
    <IFrame
      src={videoUrl}
      title="Lesson video"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      height={500}
      fullWidth={fullWidth}
    />
  );
};

export default EmbedVideo;
