import React, { useState, useContext } from "react";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarIcon from "@material-ui/icons/Star";
import { Grid } from "@material-ui/core";
import { InputDataContext, SettingsContext } from "../../../../shared/providers";

export default function DedaRating({ ratingTitle, initialRating, dedaEvaluationName }) {
  const { inputData, setInputData, isInputDataChanged, setIsInputDataChanged } = useContext(InputDataContext);
  const { isDesktop } = useContext(SettingsContext);
  const [rating, setRating] = useState(initialRating);
  const [hoverRating, setHoverRating] = useState(null);
  const [isRatingChanged, setIsRatingChanged] = useState(false);
  const changedStyle = {
    color: isRatingChanged && isInputDataChanged ? "#5166b3" : null,
  };

  const handleMouseHover = (hoverNumber) => {
    setHoverRating(hoverNumber);
  };

  const handleMouseOut = () => {
    setHoverRating(null);
  };

  const handleClick = (chosenNumber) => {
    setIsInputDataChanged(true);
    setIsRatingChanged(true);
    console.log({
      ...inputData,
      inputData: {
        ...inputData.inputData,
        dedaEvaluation: {
          ...inputData.inputData.dedaEvaluation,
          [dedaEvaluationName]: chosenNumber,
        },
      },
    });
    setInputData({
      ...inputData,
      inputData: {
        ...inputData.inputData,
        dedaEvaluation: {
          ...inputData.inputData.dedaEvaluation,
          [dedaEvaluationName]: chosenNumber,
        },
      },
    });
    if (chosenNumber === 1 && rating === 1) {
      setHoverRating(null);
      setRating(0);
    } else {
      setRating(chosenNumber);
    }
  };

  return (
    <React.Fragment>
      <Grid container direction="row" alignItems="center" spacing={0}>
        <Grid item xs={5} md={5}>
          <h6 style={{ fontSize: !isDesktop && "1rem" }}>
            <strong>{ratingTitle}</strong>
          </h6>
        </Grid>
        <Grid item xs={7} md={5}>
          <Grid
            className="pointer"
            container
            direction="row"
            spacing={0}
            justifyContent={!isDesktop && "flex-end"}
            onMouseOut={() => {
              if (isDesktop) {
                handleMouseOut();
              }
            }}
          >
            <Grid
              item
              xs={2}
              sm
              onClick={() => {
                handleClick(1);
              }}
              onMouseOver={() => {
                if (isDesktop) {
                  handleMouseHover(1);
                }
              }}
              onMouseOut={() => {
                if (isDesktop) {
                  handleMouseOut();
                }
              }}
            >
              {hoverRating && hoverRating <= 5 && hoverRating > 0 ? (
                <StarIcon fontSize="large" />
              ) : rating <= 5 && rating > 0 ? (
                <StarIcon style={changedStyle} fontSize="large" />
              ) : (
                <StarBorderIcon fontSize="large" />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm
              onClick={() => {
                handleClick(2);
              }}
              onMouseOver={() => {
                if (isDesktop) {
                  handleMouseHover(2);
                }
              }}
              onMouseOut={() => {
                if (isDesktop) {
                  handleMouseOut();
                }
              }}
            >
              {hoverRating && hoverRating <= 5 && hoverRating > 1 ? (
                <StarIcon fontSize="large" />
              ) : rating <= 5 && rating > 1 && hoverRating === null ? (
                <StarIcon style={changedStyle} fontSize="large" />
              ) : (
                <StarBorderIcon fontSize="large" />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm
              onClick={() => {
                handleClick(3);
              }}
              onMouseOver={() => {
                if (isDesktop) {
                  handleMouseHover(3);
                }
              }}
              onMouseOut={() => {
                if (isDesktop) {
                  handleMouseOut();
                }
              }}
            >
              {hoverRating && hoverRating <= 5 && hoverRating > 2 ? (
                <StarIcon fontSize="large" />
              ) : rating <= 5 && rating > 2 && hoverRating === null ? (
                <StarIcon style={changedStyle} fontSize="large" />
              ) : (
                <StarBorderIcon fontSize="large" />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm
              onClick={() => {
                handleClick(4);
              }}
              onMouseOver={() => {
                if (isDesktop) {
                  handleMouseHover(4);
                }
              }}
              onMouseOut={() => {
                if (isDesktop) {
                  handleMouseOut();
                }
              }}
            >
              {hoverRating && hoverRating <= 5 && hoverRating > 3 ? (
                <StarIcon fontSize="large" />
              ) : rating <= 5 && rating > 3 && hoverRating === null ? (
                <StarIcon style={changedStyle} fontSize="large" />
              ) : (
                <StarBorderIcon fontSize="large" />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              sm
              onClick={() => {
                handleClick(5);
              }}
              onMouseOver={() => {
                if (isDesktop) {
                  handleMouseHover(5);
                }
              }}
              onMouseOut={() => {
                if (isDesktop) {
                  handleMouseOut();
                }
              }}
            >
              {hoverRating && hoverRating === 5 ? (
                <StarIcon fontSize="large" />
              ) : rating === 5 && hoverRating === null ? (
                <StarIcon style={changedStyle} fontSize="large" />
              ) : (
                <StarBorderIcon fontSize="large" />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
