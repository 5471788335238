import React from "react";
import ReactDOM from "react-dom";
import "./shared/css/index.css";
import App from "./App";
import { UserProvider, SettingsProvider, ProfileProvider } from "./shared/providers";
import { Container, Grid } from "@material-ui/core";
import logo from "./shared/img/svg/melp-horizontal.svg";
import Help from "./MELPv3/components/sharedComponents/molecules/Help";
// import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/melp\.app\/*/],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

function ErrorPage({ errorInfo }) {
  console.error("error info", errorInfo);
  return (
    <React.Fragment>
      <Container style={{ padding: 0 }} className="white-background" maxWidth="xl">
        <Grid
          container
          direction="column"
          xs={12}
          style={{ minHeight: "100vh", textAlign: "center" }}
          alignItems="center"
          justifyContent="center"
          spacing={3}
        >
          <Grid item xs={12}>
            <img width="400" src={logo} alt="melp-logo" />
          </Grid>
          <Grid item xs={12}>
            <h1>Desculpe! Algo deu errado :(</h1>
            <h3>Recarregue a página ou tente acessar mais tarde</h3>
            <a href="/">Voltar ao início</a>
          </Grid>
          <Grid item xs={12}>
            <Help />
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

class Application extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
      errorMessage: "",
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    if (error) {
      this.setState({ hasError: true, errorInfo, errorMessage: error.message });
    } else {
      this.setState({ hasError: false, errorInfo, errorMessage: "" });
    }
  }

  render() {
    if (this.state.hasError) {
      console.error("Caught error!", this.state.errorMessage, this.state.errorInfo);
      return <ErrorPage errorInfo={[this.state.errorMessage, this.state.errorInfo]} />;
    }
    return (
      <UserProvider>
        <ProfileProvider>
          <SettingsProvider>
            <App />
          </SettingsProvider>
        </ProfileProvider>
      </UserProvider>
    );
  }
}

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
