import { clients } from "../../clients";

const pauseAccount = async (userUid) => {
  try {
    return await clients.accountsClient.patch(`/${userUid}/deda-pause`);
  } catch (error) {
    throw error;
  }
};

const unpauseAccount = async (userUid) => {
  try {
    return await clients.accountsClient.patch(`/${userUid}/deda-unpause`);
  } catch (error) {
    throw error;
  }
};

const confirmDedaStart = async (userUid) => {
  try {
    return await clients.accountsClient.patch(`/${userUid}/deda-start`);
  } catch (error) {
    throw error;
  }
};

const resetAccount = async (userUid) => {
  try {
    return await clients.accountsClient.put(`/${userUid}/reset`);
  } catch (error) {
    throw error;
  }
};

const requestPasswordChange = async (userUid) => {
  try {
    return await clients.accountsClient.get(`/passwords/${userUid}/forgot`);
  } catch (error) {
    throw error;
  }
};

export { pauseAccount, unpauseAccount, confirmDedaStart, resetAccount, requestPasswordChange };
