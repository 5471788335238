import React, { useState, createContext, useEffect, useContext } from "react";
import { getPerformanceData } from "../../MELPv3/utils/apiCalls/lampCalls/performance";
import { UserContext } from "./UserProvider";

export const PerformanceContext = createContext();

export const PerformanceProvider = ({ children }) => {
  const { user, isUserLegacy, newVersionUserDocData } = useContext(UserContext);
  const [mainPerformanceStats, setMainPerformanceStats] = useState({});
  const [isPerformanceLoading, setIsPerformanceLoading] = useState(true);
  const [isInputSaved, setIsInputSaved] = useState(false);

  useEffect(() => {
    if (isUserLegacy && user) {
      setIsPerformanceLoading(true);
      const getPerformance = async () => {
        await getPerformanceDataLegacy({ user: user }).then((performanceData) => {
          setMainPerformanceStats(performanceData.generalPerformance);
        });
      };
      getPerformance()
        .then(() => {
          setIsPerformanceLoading(false);
        })
        .catch((err) => Promise.reject(err));
    }
  }, [isUserLegacy, user]);

  useEffect(() => {
    if (!isUserLegacy && user) {
      const { accountStatus, dedaConfirmedAndStarted } = newVersionUserDocData;
      const { dedaStart, pause } = accountStatus;
      const shouldLampBeUnlocked =
        (dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
        pause.isAccountPaused ||
        dedaStart.startDates.length >= 2;
      if (shouldLampBeUnlocked) {
        const getPerformance = async () => {
          await getPerformanceData({ userUid: user.uid, includesGeneral: true })
            .then((result) => {
              setMainPerformanceStats(result);
            })
            .catch((error) => {
              throw error;
            });
        };
        getPerformance()
          .then(() => {
            setIsPerformanceLoading(false);
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }, [isUserLegacy, newVersionUserDocData, isInputSaved]);

  return (
    <PerformanceContext.Provider
      value={{
        isInputSaved,
        setIsInputSaved,
        mainPerformanceStats,
        setMainPerformanceStats,
        isPerformanceLoading,
        setIsPerformanceLoading,
      }}
    >
      {children}
    </PerformanceContext.Provider>
  );
};
