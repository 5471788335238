import React from "react";
import styled from "@emotion/styled";

const ListenSoundCloud = styled.iframe`
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  display: flex;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Garuda, Verdana, Tahoma, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-variant-caps: normal;
  font-weight: 400;
  height: 10.3;
  line-height: 16.799999px;
  width: 100%;
  border: none;
`;

export default ListenSoundCloud;
