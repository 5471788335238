import React, { useContext, useState } from "react";
import { Grid, Paper } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ComponentTitle } from "../../../sharedComponents";
import { TargetIndex } from "../../atoms";
import { InputDataContext } from "../../../../../shared/providers";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";

export default function ReviewInput() {
  const { inputData, setInputData, setIsInputDataChanged } = useContext(InputDataContext);

  function ReviewBlob({ reviews }) {
    const defaultChecked = [];

    Object.keys(reviews).map((review, index) => {
      if (review !== "reviewProgress") {
        if (reviews[review].status) {
          defaultChecked.push(index + 1);
        }
      }
    });

    const [checked, setChecked] = useState(defaultChecked);

    const handleToggle = (value) => () => {
      setIsInputDataChanged(true);
      inputData.reviewData[`review${value}`].status = !inputData.reviewData[`review${value}`].status;
      const currentIndex = checked.indexOf(value);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
      setInputData({
        ...inputData,
        reviewData: {
          ...inputData.reviewData,
          // reviewProgress: (newChecked.length / (Object.keys(inputData.reviewData).length - 1)) * 100,
        },
      });
    };

    return (
      <Grid container direction="column" spacing={2} justifyContent="flex-start">
        {Object.keys(reviews).map((review, index) => {
          if (review !== "reviewProgress") {
            const labelId = `checkbox-list-label-${index + 1}`;

            return (
              <Grid item xs={12}>
                <Paper className={`paper-props paper-shape`}>
                  <Grid container direction="row" alignItems="center" justifyContent="center">
                    <ListItem
                      style={{
                        borderRadius: "0.2rem",
                      }}
                      key={"reviewCheck" + index + 1}
                      role={undefined}
                      button
                      onClick={handleToggle(parseInt(review.slice(6)))}
                    >
                      <Grid item xs={2}>
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            checked={checked.indexOf(index + 1) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                        </ListItemIcon>
                      </Grid>
                      <Grid container>
                        <Grid item>
                          <ListItemText id={labelId} primary={<h5>{`Review ${review.slice(6)}`}</h5>} />
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center">
                        <Grid item>
                          <ListItemText id={labelId} primary={<h5>{reviews[review].name}</h5>} />
                        </Grid>
                      </Grid>
                    </ListItem>
                  </Grid>
                </Paper>
              </Grid>
            );
          }
          return null;
        })}
      </Grid>
    );
  }

  return (
    <React.Fragment>
      <Paper className={"paper-props paper-shape paper-secondary"}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Grid container direction="row" spacing={1} alignItems="">
              <Grid item xs={2} md={1}>
                <AssignmentTurnedInIcon fontSize="large" />
              </Grid>
              <Grid item xs={5} md={8}>
                <ComponentTitle>REVIEW</ComponentTitle>
              </Grid>
              <Grid item xs={5} md={3}>
                <TargetIndex index={inputData && inputData.reviewData.reviewProgress} title="Review progress" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <ReviewBlob reviews={inputData && inputData.reviewData} />
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
