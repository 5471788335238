import React from "react";
import HpecListMenu from "./HpecListMenu";
import { IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import Drawer from "@material-ui/core/Drawer";
import Grid from "@material-ui/core/Grid";

export default function HpecsListMenuMobile({ chosenTopic }) {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left"].map((anchor) => {
        return (
          <React.Fragment key={anchor}>
            <IconButton
              style={{
                color: "#1d212e",
                backgroundColor: "transparent",
                padding: "0.5rem",
              }}
              onClick={toggleDrawer(anchor, true)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Drawer id="hpec-mobile-drawer" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              <Grid
                style={{
                  backgroundColor: "white",
                }}
                container
                xs={12}
              >
                <HpecListMenu chosenTopic={chosenTopic} />
              </Grid>
            </Drawer>
          </React.Fragment>
        );
      })}
    </div>
  );
}
