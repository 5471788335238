import React from "react";
import styled from "@emotion/styled";

const Paragraph = styled.p`
  color: black;
  font-size: 1.5rem !important;
  font-weight: 400;
  line-height: 2rem;
  margin-bottom: 0;

  @media only screen and (max-width: 767px) {
    font-size: 1.2rem !important;
  }
`;

const ParagraphText = ({ children, className = "", fontColor = "fontPrimary" }) => {
  return (
    <Paragraph fontColor={fontColor} className={`${className}`}>
      {children}
    </Paragraph>
  );
};

export default ParagraphText;
