import React, { useContext } from "react";
import { Grid, Paper, Typography, CircularProgress } from "@material-ui/core";
import { TimeInputList } from "../atoms";
import { InputDataContext, SettingsContext } from "../../../../shared/providers";

export default function InputStatisticTile({ inputName, inputTime, inputValueName, isLoading, statisticObjectProp }) {
  const { inputData, setInputData, setIsInputDataChanged } = useContext(InputDataContext);
  const { isDesktop } = useContext(SettingsContext);
  const inputsValuesList = inputData.inputData[statisticObjectProp];
  return (
    <React.Fragment>
      <Paper
        className={`tile-color paper-props paper-shape`}
        style={{
          height: "12rem",
          width: !isDesktop ? "12rem" : "100%",
        }}
      >
        <Grid className={"full-height"} container direction="column" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h6" component="h6">
              <strong>{inputName}</strong>
            </Typography>
          </Grid>
          {typeof inputTime !== "number" || isLoading ? (
            <CircularProgress />
          ) : (
            <Grid item justifyContent="center">
              <TimeInputList
                isInput
                onSelect={(theValue) => {
                  setIsInputDataChanged(true);
                  setInputData({
                    ...inputData,
                    inputData: {
                      ...inputData.inputData,
                      [statisticObjectProp]: {
                        ...inputsValuesList,
                        [inputValueName]: parseInt(theValue.slice(9)),
                      },
                    },
                  });
                }}
                defaultItem={"timeValue" + inputTime}
              />
            </Grid>
          )}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
