import React from "react";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { HeadingText, ParagraphText } from "../atoms";
import styled from "@emotion/styled";

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: flex-start;

  & > * {
    text-align: justify;
  }

  & > h1 {
    font-size: 3.2rem;
  }

  & > h2 {
    font-size: 2.5rem;
  }

  & > h3 {
    font-size: 2rem;
  }
`;

const HyperLink = styled.a`
  font-weight: 700;
  color: blue;

  &::after {
    content: "\\2197"; /* Unicode code for the up-right arrow */
    font-size: inherit;
  }

  &:hover {
    text-decoration: underline;
  }
`;

const EmbedImage = styled.img`
  width: 100%;
  height: 100%;
  max-width: 30rem;
  text-align: center;
  align-self: center;
  border-radius: 1rem;
  box-shadow: 0 20px 100px 0.000000008rem rgb(107, 107, 107, 0.2);
  margin-top: 2rem;
`;

const renderOptions = (links) => {
  const assetMap = new Map();

  links?.assets?.block?.forEach((asset) => {
    assetMap.set(asset.sys.id, asset);
  });

  return {
    renderMark: {
      [MARKS.BOLD]: (text) => <strong>{text}</strong>,
      [MARKS.ITALIC]: (text) => <em>{text}</em>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <ParagraphText fontColor="fontSecondary">{children}</ParagraphText>,
      [BLOCKS.HEADING_1]: (node, children) => (
        <HeadingText hType="h1" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <HeadingText hType="h2" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <HeadingText hType="h3" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <HeadingText hType="h4" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <HeadingText hType="h5" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.HEADING_6]: (node, children) => (
        <HeadingText hType="h6" fontWeight="500">
          {children}
        </HeadingText>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = assetMap.get(node.data.target.sys.id);

        return (
          <EmbedImage src={asset.url} width={300} height={300} alt={asset.title ? asset.title : "Embed text image"} />
        );
      },
      // [INLINES.EMBEDDED_ENTRY]: (node) => {
      //     return <p>LINK WILL BE HERE {JSON.stringify(node)}</p>;
      // },
      [INLINES.HYPERLINK]: (node, children) => (
        <HyperLink href={node.data.uri} target="_blank">
          {children}
        </HyperLink>
      ),
    },
  };
};

const TextContentRender = ({ rawContent, links }) => {
  return (
    <TextWrapper>
      {/*// @ts-ignore*/}
      {documentToReactComponents(rawContent, renderOptions(links))}
    </TextWrapper>
  );
};

export default TextContentRender;
