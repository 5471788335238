import { clients } from "../../clients";

export const getUserData = async (userUid) => {
  try {
    const response = await clients.melpClient.get(`/${userUid}/summary`);

    return response;
  } catch (error) {
    throw error;
  }
};
