import React, { useContext } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ComponentTitle } from "../../../sharedComponents";
import { InputStatisticTile } from "../index";
import { TargetIndex } from "../../atoms";
import { InputDataContext, SettingsContext } from "../../../../../shared/providers";

export default function PassiveInput() {
  const { inputData } = useContext(InputDataContext);
  const { isDesktop } = useContext(SettingsContext);

  return (
    <React.Fragment>
      <Paper className={"paper-props paper-shape paper-secondary"}>
        <Grid container direction="column" spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} alignItems="space-between">
              <Grid item xs={2} md={1}>
                <AssignmentTurnedInIcon fontSize="large" />
              </Grid>
              <Grid item xs={5} md={8}>
                <ComponentTitle>PASSIVE</ComponentTitle>
                {isDesktop && <Typography variant="subtitle1">Input your Passive Studying (HH:MM)</Typography>}
              </Grid>
              <Grid item xs={5} md={3}>
                <TargetIndex
                  index={inputData && inputData.inputData.passive.percentageConcluded}
                  title="Passive progress"
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              overflowX: !isDesktop ? "scroll" : "hidden",
              overflowY: "hidden",
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              style={{
                width: !isDesktop ? "140rem" : "auto",
              }}
            >
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Podcast"
                  inputTime={inputData && inputData.inputData.passive.podcast}
                  inputValueName="podcast"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Audiobook"
                  inputTime={inputData && inputData.inputData.passive.audiobook}
                  inputValueName="audiobook"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="News/Shows"
                  inputTime={inputData && inputData.inputData.passive.newsShows}
                  inputValueName="newsShows"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Series"
                  inputTime={inputData && inputData.inputData.passive.series}
                  inputValueName="series"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Movie/Doc"
                  inputTime={inputData && inputData.inputData.passive.movieDoc}
                  inputValueName="movieDoc"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="YouTube"
                  inputTime={inputData && inputData.inputData.passive.youtube}
                  inputValueName="youtube"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="TED"
                  inputTime={inputData && inputData.inputData.passive.ted}
                  inputValueName="ted"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Conversation"
                  inputTime={inputData && inputData.inputData.passive.conversation}
                  inputValueName="conversation"
                  statisticObjectProp="passive"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Others"
                  inputTime={inputData && inputData.inputData.passive.others}
                  inputValueName="others"
                  statisticObjectProp="passive"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
