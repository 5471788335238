import React, { useState, useEffect, useContext } from "react";
import { PerformanceContext, UserContext } from "../../../../../shared/providers";
import { Grid, Paper, Typography } from "@material-ui/core";
import { LoadingGraph } from "../../atoms";
import GaugeChart from "react-gauge-chart";

const ColorBox = ({ color }) => <div style={{ width: "1rem", height: "1rem", backgroundColor: color }} />;

const getOverallColor = ({ setOverallColor, mainPerformanceStats }) => {
  const { general } = mainPerformanceStats;

  if (general.overall < 50) {
    setOverallColor("bad");
  } else if (general.overall >= 50 && general.overall < 70) {
    setOverallColor("poor");
  } else if (general.overall >= 70 && general.overall < 80) {
    setOverallColor("good");
  } else if (general.overall >= 80 && general.overall <= 95) {
    setOverallColor("great");
  } else if (general.overall > 95) {
    setOverallColor("excellent");
  }
};

export default function OverallPieGraph({ theme }) {
  const { mainPerformanceStats, isPerformanceLoading } = useContext(PerformanceContext);
  const { isLampLoading } = useContext(UserContext);
  const [overallColor, setOverallColor] = useState("");

  useEffect(() => {
    getOverallColor({ setOverallColor, mainPerformanceStats });
  }, [mainPerformanceStats, isPerformanceLoading]);

  return (
    <React.Fragment>
      <Paper className={`paper-props paper-shape full-width`}>
        <Grid className="full-height" container direction="column" justifyContent="space-between" alignItems="stretch">
          <Grid item>
            <Grid container direction="row">
              <Grid item xs={8}>
                <Typography className={"primary-title-" + theme} variant="h5" component="h5">
                  <strong>Overall progress</strong>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  style={{ textAlign: "right" }}
                  className={"primary-title-" + theme}
                  variant="h5"
                  component="h5"
                >
                  <span className={"overall-number " + overallColor}>
                    <strong>{mainPerformanceStats && mainPerformanceStats.general.overall.toFixed(2) + "%"}</strong>
                  </span>
                </Typography>
              </Grid>
            </Grid>

            <Grid class="padding-standard vertical-padding" item>
              <Grid container direction="row" spacing={2} justifyContent="space-evenly">
                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                      <ColorBox color="#FF0000FF" />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Bad</h4>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                      <ColorBox color="#FF6363FF" />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Poor</h4>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                      <ColorBox color="#B8C889FF" />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Good</h4>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                      <ColorBox color="#A7E55FFF" />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Great</h4>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  <Grid container direction="row" justifyContent="center" spacing={1}>
                    <Grid item xs={6}>
                      <ColorBox color="#85E90FFF" />
                    </Grid>
                    <Grid item xs={6}>
                      <h4>Excellent</h4>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container justifyContent="center" style={{ minHeight: "50%" }}>
              {isPerformanceLoading || isLampLoading ? (
                <LoadingGraph />
              ) : (
                <Grid container xs={12}>
                  <Grid container direction="column" alignItems="center">
                    <Grid item>
                      <GaugeChart
                        id="gauge-chart1"
                        nrOfLevels={5}
                        arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                        colors={["#FF0000FF", "#FF6363FF", "#B8C889FF", "#A7E55FFF", "#85E90FFF"]}
                        hideText={true}
                        percent={mainPerformanceStats.general.overall.toFixed(2) / 100}
                        arcPadding={0.02}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
