import React, { useContext, useEffect } from "react";
import TimeoutSessionDialog from "./components/sharedComponents/molecules/TimeoutSessionDialog";
import {
  DatesProvider,
  DedaProvider,
  HpecProvider,
  NotificationProvider,
  PerformanceProvider,
  SettingsContext,
  UserContext,
} from "../shared/providers";
import { Container, Grid } from "@material-ui/core";
import NavigationBar from "./components/sharedComponents/molecules/NavigationBar";
import { BrowserRouter, Route } from "react-router-dom";
import { createBrowserHistory } from "history";
import SideMenu from "./components/sharedComponents/molecules/SideMenu";
// import NotFound from "../commonPages/NotFound";
import WelcomeHome from "./routes/welcome/WelcomeHome";
import Lamp from "./routes/lamp/Lamp";
import Deda from "./routes/deda/Deda";
import Hpec from "./routes/hpec/Hpec";
import Profile from "./routes/profileSettings/Profile";
import BottomMenu from "./components/sharedComponents/molecules/BottomMenu";
import PasswordRecovery from "../commonPages/PasswordRecovery";
import { useScrollDirection } from "react-use-scroll-direction";
import MasterAdmin from "./components/sharedComponents/molecules/MasterAdmin";

export default function UpdatedApp() {
  const { isDesktop, isBottomMenuDisplayed, setIsBottomMenuDisplayed } = useContext(SettingsContext);
  const { newVersionUserDocData, masterAdmin } = useContext(UserContext);
  const { currentTime, accountStatus, dedaConfirmedAndStarted } = newVersionUserDocData;
  const { dedaStart, pause } = accountStatus;
  const { currentWeek, daysSinceMelpBegin } = currentTime;

  const { isScrollingUp, isScrollingDown, scrollTargetRef } = useScrollDirection();

  const history = createBrowserHistory();

  useEffect(() => {
    if (isScrollingUp && !isScrollingDown) {
      setIsBottomMenuDisplayed(true);
    } else if (!isScrollingUp && isScrollingDown) {
      setIsBottomMenuDisplayed(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollingUp, isScrollingDown]);

  const shouldLampBeUnlocked =
    (dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
    pause.isAccountPaused ||
    dedaStart.startDates.length >= 2;
  return (
    <React.Fragment>
      <TimeoutSessionDialog />
      <NotificationProvider>
        <HpecProvider>
          {masterAdmin !== false && <MasterAdmin />}
          <BrowserRouter history={history}>
            <Container style={{ padding: 0 }} className="white-background" maxWidth="xl">
              <Grid container direction="column" xs={12}>
                <Grid item xs={12}>
                  <Grid container direction="row" xs={12}>
                    <Grid item xs={12}>
                      <NavigationBar />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" alignItems="center">
                    {/*Menu column*/}
                    {isDesktop && (
                      <Grid item xs={12} md={1}>
                        <SideMenu />
                      </Grid>
                    )}

                    {/*Content views column*/}
                    <Grid item xs={12} md={11}>
                      <DatesProvider>
                        <PerformanceProvider>
                          <Route exact path="/" children={<WelcomeHome scrollTargetRef={scrollTargetRef} />} />
                          <Route exact path="/password-recovery" children={<PasswordRecovery />} />
                          {currentWeek > 0 && shouldLampBeUnlocked && (
                            <Route
                              exact
                              path="/lamp/:renderView"
                              children={<Lamp scrollTargetRef={scrollTargetRef} />}
                            />
                          )}
                          {daysSinceMelpBegin >= 2 && (
                            <Route
                              exact
                              path="/deda/:weekNumber/:dedaSubItem"
                              children={
                                <DedaProvider>
                                  <Deda scrollTargetRef={scrollTargetRef} />
                                </DedaProvider>
                              }
                            />
                          )}
                          <Route
                            exact
                            path="/hpec/:topic/:subTopic"
                            children={<Hpec scrollTargetRef={scrollTargetRef} />}
                          />
                          <Route exact path="/profile" children={<Profile scrollTargetRef={scrollTargetRef} />} />
                          {/*<Route children={<NotFound errorDescription="Oops! It seems that we couldn't find the page you are looking for" errorTitle="404" />} />*/}
                        </PerformanceProvider>
                      </DatesProvider>
                    </Grid>
                  </Grid>
                </Grid>

                {!isDesktop && isBottomMenuDisplayed && (
                  <Grid item xs={12}>
                    <BottomMenu default />
                  </Grid>
                )}
              </Grid>
            </Container>
          </BrowserRouter>
        </HpecProvider>
      </NotificationProvider>
    </React.Fragment>
  );
}
