import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const modulesListQuery = gql`
  query ModulesQuery($courseSlug: String) {
    courseCollection(where: { courseSlug: $courseSlug }, limit: 1) {
      items {
        courseModulesCollection {
          items {
            moduleId
            moduleName
            lessonsCollection {
              items {
                lessonId
                lessonTitle
                lessonFeaturedText
              }
            }
          }
        }
      }
    }
  }
`;

const useGetModulesList = (courseSlug) => {
  return useQuery(modulesListQuery, {
    variables: {
      courseSlug,
    },
  });
};

export default useGetModulesList;
