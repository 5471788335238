import React, { useContext, useState, useEffect } from "react";
import { Grid, Box, AppBar, Button } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import PieChartIcon from "@material-ui/icons/PieChart";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import ClassIcon from "@material-ui/icons/Class";
import HomeIcon from "@material-ui/icons/Home";
import Typography from "@material-ui/core/Typography";
import { UserContext, HpecContext } from "../../../../shared/providers";
import LockIcon from "@material-ui/icons/Lock";

export default function BottomMenu() {
  const { isLoading, chosenWeek, newVersionUserDocData } = useContext(UserContext);
  const { currentTime, accountStatus, dedaConfirmedAndStarted } = newVersionUserDocData;
  const { dedaStart, pause } = accountStatus;
  const { currentHpec } = useContext(HpecContext);
  const hpecVc = Object.keys(currentHpec)[2];
  const [activeLink, setActiveLink] = useState(window.location.pathname.split("/")[1]);
  const location = useLocation();
  const [isInstallActive, setIsInstallActive] = useState(false);

  const shouldLampBeUnlocked =
    (dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
    pause.isAccountPaused ||
    dedaStart.startDates.length >= 2;

  useEffect(() => {
    setActiveLink(location.pathname.split("/")[1]);
  }, [location]);

  const menuItems = [
    {
      label: "Home",
      icon: <HomeIcon fontSize="large" />,
      link: "/",
      activeLink: "",
    },
    {
      label: "HPEC",
      icon: <ClassIcon fontSize="large" />,
      link: "/hpec/TOPIC1/VC1",
      activeLink: "hpec",
    },
    {
      label: currentTime.daysSinceMelpBegin < 2 ? "(Locked)" : "DEDA",
      icon: currentTime.daysSinceMelpBegin < 2 ? <LockIcon fontSize="large" /> : <VerticalSplitIcon fontSize="large" />,
      link: currentTime.daysSinceMelpBegin < 2 ? "#" : `/deda/${chosenWeek}/dedaNotes`,
      activeLink: "deda",
    },
    {
      label: !shouldLampBeUnlocked ? "(Locked)" : "LAMP",
      icon: !shouldLampBeUnlocked ? <LockIcon fontSize="large" /> : <PieChartIcon fontSize="large" />,
      link: !shouldLampBeUnlocked ? "#" : "/lamp/performance",
      activeLink: "lamp",
      options: [
        {
          label: "Goal",
          link: "",
        },
        {
          label: "Performance",
          link: "",
        },
        {
          label: "DEDA",
          link: "",
        },
        {
          label: "Active",
          link: "",
        },
        {
          label: "Passive",
          link: "",
        },
        {
          label: "Review",
          link: "",
        },
      ],
    },
  ];

  return (
    <React.Fragment>
      {isInstallActive && (
        <AppBar
          id="install-pwa-blob"
          position="fixed"
          style={{
            top: "auto",
            bottom: 110,
            padding: "1rem",
          }}
        >
          <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="stretch">
            <Grid item xs={6}>
              <Typography variant="subtitle1">Install the app on your phone</Typography>
            </Grid>
            <Grid item xs={3}>
              <Button variant="container" color="primary">
                Install
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="container"
                color="secondary"
                onClick={() => {
                  setIsInstallActive(false);
                }}
              >
                Dismiss
              </Button>
            </Grid>
          </Grid>
        </AppBar>
      )}
      <AppBar id="bottom-menu-bar" position="fixed" style={{ top: "auto", bottom: 0, padding: "1rem" }}>
        <Grid container direction="row" spacing={2} justifyContent="space-between" alignItems="stretch">
          {menuItems.map((menuItem) => {
            return (
              <React.Fragment>
                <Grid id="side-menu" item xs={3}>
                  <Link to={menuItem.link}>
                    <Box
                      className={menuItem.activeLink === activeLink ? "bottom-menu-icon selected" : "bottom-menu-icon"}
                    >
                      <Grid container direction="column" alignItems="center">
                        <Grid item xs={12}>
                          {menuItem.icon}
                        </Grid>
                        <Grid className="bottom-menu-label" item xs={12}>
                          <Typography
                            variant="h6"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {menuItem.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      </AppBar>
    </React.Fragment>
  );
}
