import React from "react";
import styled from "@emotion/styled";

const Heading = styled.h1`
  font-weight: ${(props) => props.fontWeight || "400"};
  color: black;
`;

const HeadingText = ({ hType, fontWeight, children, className, fontColor = "fontPrimary", variant = "primary" }) => {
  return (
    <Heading as={hType} fontWeight={fontWeight} className={`${className}`} fontColor={fontColor}>
      {children}
    </Heading>
  );
};

export default HeadingText;
