import React from "react";
import { Container, Grid } from "@material-ui/core";
import logo from "../shared/img/svg/melp-horizontal.svg";
import Help from "../MELPv3/components/sharedComponents/molecules/Help";

export default function HelpPage() {
  return (
    <Container style={{ padding: 0 }} className="white-background" maxWidth="xl">
      <Grid
        container
        direction="column"
        xs={12}
        style={{ minHeight: "100vh", textAlign: "center" }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        <Grid item xs={12}>
          <img width="400" src={logo} alt="melp-logo" />
        </Grid>
        <Grid item xs={12}>
          <h1>Bem vindo ao suporte da MELP!</h1>
          <h3>Responderemos à sua pergunta o mais rápido possível.</h3>
        </Grid>
        <Grid item xs={12}>
          <Help />
        </Grid>
      </Grid>
    </Container>
  );
}
