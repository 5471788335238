import axios from "axios";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "./firebase";

class ApiClient {
  constructor(serviceName) {
    this.serviceName = serviceName;
    this.baseUrl = process.env.REACT_APP_METTLE_API;
    this.client = axios.create({
      baseURL: `${this.baseUrl}/${this.serviceName}`,
    });

    this.setAuthInterceptor();
  }

  async getAuthToken() {
    return new Promise((resolve) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          user.getIdToken().then((token) => {
            resolve(token);
          });
        } else {
          resolve(null);
        }
      });
    });
  }

  setAuthInterceptor() {
    this.client.interceptors.request.use(async (config) => {
      const token = await this.getAuthToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
  }

  get(endpoint, options) {
    return this.client.get(endpoint, options).then(({ data }) => data);
  }

  post(endpoint, data, options) {
    return this.client.post(endpoint, data, options).then(({ data }) => data);
  }

  patch(endpoint, data, options) {
    return this.client.patch(endpoint, data, options).then(({ data }) => data);
  }

  delete(endpoint) {
    return this.client.delete(endpoint).then(({ data }) => data);
  }

  put(endpoint, data, options) {
    return this.client.put(endpoint, data, options).then(({ data }) => data);
  }
}

export default ApiClient;
