import React from "react";
import { Box, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

export default function ContentLoading({ isLoading }) {
  return (
    <React.Fragment>
      <Box style={{ textAlign: "center" }} marginTop={9}>
        <CircularProgress />
        <Typography variant="subtitle1">
          <strong>Loading... Please wait as this can take a minute</strong>
        </Typography>
      </Box>
    </React.Fragment>
  );
}
