import React, { useEffect, useContext } from "react";
import { PerformanceContext, UserContext } from "../../../../../shared/providers";
import { Grid, Paper, Typography } from "@material-ui/core";
import { getLinearGraph } from "../../../../utils/apiCalls/lampCalls/getLinearGraph";
import Chart from "chart.js";
import { createLinearPerformanceDataObject } from "../../../../utils/graphs/createLinearPerformanceDataObject";
import Box from "@material-ui/core/Box";
import { LoadingGraph } from "../../atoms";

export default function LinearGraphDevelopment({ theme }) {
  const { newVersionUserDocData, user, isLampLoading } = useContext(UserContext);
  const { isPerformanceLoading } = useContext(PerformanceContext);
  const ctx = "linear-performance";

  const makeGraph = async () => {
    if (user && isLampLoading === false && isPerformanceLoading === false) {
      await getLinearGraph(user.uid).then((response) => {
        new Chart(ctx, {
          type: "line",
          data: createLinearPerformanceDataObject(response, newVersionUserDocData.currentTime),
          options: {
            fullWidth: true,
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItems, data) {
                  return tooltipItems.yLabel + " %";
                },
              },
            },
            legend: {
              display: false,
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                    suggestedMax: 100,
                    stepSize: 10,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                  },
                  gridLines: {
                    display: true,
                    drawBorder: false,
                    color: "rgba(29,140,248,0.1)",
                    zeroLineColor: "transparent",
                  },
                },
              ],
            },
          },
        });
      });
    }
  };

  useEffect(() => {
    makeGraph().catch((error) => {
      throw error;
    });
  }, [isPerformanceLoading, isLampLoading, user]);

  return (
    <React.Fragment>
      <Paper className={`paper-props paper-shape`}>
        <Grid container direction="column" justifyContent="space-between">
          <Grid container item direction="row" justifyContent="space-between">
            <Grid className={"primary-title-" + theme} item xs={12}>
              <Typography variant="h5" component="h5">
                <strong>Your weekly development so far</strong>
              </Typography>
              <p>The weekly average of your performance so far</p>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center">
            {isPerformanceLoading || isLampLoading ? (
              <Grid container item xs={12}>
                <LoadingGraph />
              </Grid>
            ) : (
              <Grid container item>
                <Box style={{ position: "relative", width: "100%" }} className="graph-wrapper">
                  <canvas className="padding-graph" id="linear-performance" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
