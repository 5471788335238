export const dedasList = {
  WEEK0: "NFTs",
  WEEK1: "32-Hour Workweek",
  WEEK2: "New York City",
  WEEK3: "Grit",
  WEEK4: "Coding",
  WEEK5: "Music",
  WEEK6: "Meditation",
  WEEK7: "Water",
  WEEK8: "Greatness",
  WEEK9: "Bionic Limbs",
  WEEK10: "Boston",
  WEEK11: "Bees",
  WEEK12: "Nobel Prizes",
  WEEK13: "U.S. Dollar",
  WEEK14: "Graphene",
  WEEK15: "Sleep",
  WEEK16: "Discipline",
  WEEK17: "Machine Learning",
  WEEK18: "Washington, D.C.",
  WEEK19: "Public Speaking",
  WEEK20: "Elon Musk",
  WEEK21: "Vertical Farming",
  WEEK22: "5G Technology",
  WEEK23: "Caffeine",
  WEEK24: "Gratitude",
  WEEK25: "Shoe Dog",
  WEEK26: "Rome",
  WEEK27: "Procrastination",
  WEEK28: "Cooking",
  WEEK29: "Electric Car",
  WEEK30: "Forgiveness",
  WEEK31: "Heart Attack",
  WEEK32: "MindShift",
  WEEK33: "Meatless Meat",
  WEEK34: "London",
  WEEK35: "Change",
  WEEK36: "Airplane",
  WEEK37: "Coworking",
  WEEK38: "Creativity",
  WEEK39: "Confidence",
  WEEK40: "Clarity",
  WEEK41: "2022 World Cup",
  WEEK42: "Dubai",
  WEEK43: "Mathematical Beauty",
  WEEK44: "All Related",
  WEEK45: "Morning, Night, Neither",
  WEEK46: "Language",
  WEEK47: "Reading",
  WEEK48: "Create Yourself",
  WEEK49: "Hyperloop",
  WEEK50: "San Francisco",
  WEEK51: "Smile",
  WEEK52: "Sugar",
  WEEK53: "FOMO",
  WEEK54: "GMO",
  WEEK55: "Carbohydrates",
  WEEK56: "Quiet Mind",
  WEEK57: "Wave Pool",
  WEEK58: "Bora Bora",
  WEEK59: "Growth Mindset",
  WEEK60: "Placebo",
};

export const dedasIdsList = {
  DEDA0: "NFTs",
  DEDA1: "32-Hour Workweek",
  DEDA2: "New York City",
  DEDA3: "Grit",
  DEDA4: "Coding",
  DEDA5: "Music",
  DEDA6: "Meditation",
  DEDA7: "Water",
  DEDA8: "Greatness",
  DEDA9: "Bionic Limbs",
  DEDA10: "Boston",
  DEDA11: "Bees",
  DEDA12: "Nobel Prizes",
  DEDA13: "U.S. Dollar",
  DEDA14: "Graphene",
  DEDA15: "Sleep",
  DEDA16: "Discipline",
  DEDA17: "Machine Learning",
  DEDA18: "Washington, D.C.",
  DEDA19: "Public Speaking",
  DEDA20: "Elon Musk",
  DEDA21: "Vertical Farming",
  DEDA22: "5G Technology",
  DEDA23: "Caffeine",
  DEDA24: "Gratitude",
  DEDA25: "Shoe Dog",
  DEDA26: "Rome",
  DEDA27: "Procrastination",
  DEDA28: "Cooking",
  DEDA29: "Electric Car",
  DEDA30: "Forgiveness",
  DEDA31: "Heart Attack",
  DEDA32: "MindShift",
  DEDA33: "Meatless Meat",
  DEDA34: "London",
  DEDA35: "Change",
  DEDA36: "Airplane",
  DEDA37: "Coworking",
  DEDA38: "Creativity",
  DEDA39: "Confidence",
  DEDA40: "Clarity",
  DEDA41: "2022 World Cup",
  DEDA42: "Dubai",
  DEDA43: "Mathematical Beauty",
  DEDA44: "All Related",
  DEDA45: "Morning, Night, Neither",
  DEDA46: "Language",
  DEDA47: "Reading",
  DEDA48: "Pelé",
  DEDA49: "Hyperloop",
  DEDA50: "San Francisco",
  DEDA51: "Smile",
  DEDA52: "Sugar",
  DEDA53: "FOMO",
  DEDA54: "GMO",
  DEDA55: "Carbohydrates",
  DEDA56: "Quiet Mind",
  DEDA57: "Wave Pool",
  DEDA58: "Bora Bora",
  DEDA59: "Growth Mindset",
  DEDA60: "Placebo",
};

export const hpecList = {
  TOPIC1: {
    drippingDay: 0,
    NAME: "M01 | GET STARTED",
    VC1: "C01 | Welcome",
    VC2: "C02 | Introduction",
    VC3: "C03 | Flip/Apps",
  },
  TOPIC2: {
    drippingDay: 1,
    NAME: "M02 | GET TO KNOW YOUR BRAIN",
    VC4: "C04 | Studying in English",
    VC5: "C05 | Learning Modes",
    VC6: "C06 | Neurolearning/CEO",
    VC7: "C07 | The 3 Principles",
  },
  TOPIC3: {
    drippingDay: 2,
    NAME: "M03 | DEDA NEUROENGLISH",
    VC8: "C08 | DEDA Method",
    VC9: "C09 | DEDA Notes",
  },
  TOPIC4: {
    drippingDay: 8,
    NAME: "M04 | LAMP | PART I",
    VC10: "C10 | LAMP | Concept",
    VC11: "C11 | LAMP | Goal/Performance",
    VC12: "C12 | LAMP | Input: Active/Passive",
    VC13: "C13 | LAMP | Input: DEDA",
    VC14: "C14 | Introduction to Passive",
  },
  TOPIC5: {
    drippingDay: 15,
    NAME: "M05 | SMARTER & DEEPER",
    VC15: "C15 | SSS",
    VC16: "C16 | DEEP",
  },
  TOPIC6: {
    drippingDay: 22,
    NAME: "M06 | LAMP | PART II + TALL",
    VC17: "C17 | LAMP | Review / TALL",
  },
  TOPIC7: {
    drippingDay: 22,
    NAME: "M07 | SHARPEN THE SAW",
    VC18: "C18 | Passive Learning: The 3 Pillars",
    // VC19: "C19 | Mastering your passive learning",
  },
  TOPIC8: {
    drippingDay: 22,
    NAME: "M08 | TRAINING TO TALK",
    VC19: "C20 | TTT",
  },
  TOPIC9: {
    drippingDay: 22,
    NAME: "M09 | BOOKS. BOOKS. BOOKS.",
    VC20: "C21 | How to Read a Book",
    VC21: "C22 | BMW Technique",
    VC22: "C23 | How to Buy",
  },
};

export const hpecListSubMenu = {
  TOPIC2: {
    VC4: "C04 | Studying in English",
    VC5: "C05 | Learning Modes",
    VC6: "C06 | Neurolearning/CEO",
    VC7: "C07 | The 3 Principles",
  },
  TOPIC3: {
    VC8: "C08 | DEDA Method",
    VC9: "C09 | DEDA Notes",
  },
  TOPIC4: {
    VC10: "C10 | LAMP | Concept",
    VC11: "C11 | LAMP | Goal/Performance",
    VC12: "C12 | LAMP | Input: Active/Passive",
    VC13: "C13 | LAMP | Input: DEDA",
    VC14: "C14 | Introduction to Passive",
  },
  TOPIC5: {
    VC15: "C15 | SSS",
    VC16: "C16 | DEEP",
  },
  TOPIC6: {
    VC17: "C17 | LAMP | Review / TALL",
  },
  TOPIC7: {
    VC18: "C18 | Passive Learning: The 3 Pillars",
  },
  TOPIC8: {
    VC19: "C20 | TTT",
  },
  TOPIC9: {
    VC20: "C21 | How to Read a Book",
    VC21: "C22 | BMW Technique",
    VC22: "C23 | How to Buy",
  },
};

export const hpecLessonIds = {
  VC1: "welcome",
  VC2: "introduction",
  VC3: "flip-apps",
  VC4: "studying-in-english",
  VC5: "learning-modes",
  VC6: "neurolearning-ceo",
  VC7: "the-3-principles",
  VC8: "deda-method",
  VC9: "deda-notes",
  VC10: "lamp-concept",
  VC11: "lamp-goal-performance",
  VC12: "lamp-input-active-passive",
  VC13: "lamp-input-deda",
  VC14: "introduction-to-passive",
  VC15: "sss",
  VC16: "deep",
  VC17: "lamp-review-tall",
  VC18: "passive-learning-the-3-pillars",
  VC19: "ttt",
  VC20: "how-to-read-a-book",
  VC21: "bmw-technique",
  VC22: "how-to-buy",
};

export const timeList = [
  {
    time: "00h:00min",
    value: 0,
  },
  {
    time: "00h:05min",
    value: 5,
  },
  {
    time: "00h:10min",
    value: 10,
  },
  {
    time: "00h:15min",
    value: 15,
  },
  {
    time: "00h:20min",
    value: 20,
  },
  {
    time: "00h:25min",
    value: 25,
  },
  {
    time: "00h:30min",
    value: 30,
  },
  {
    time: "00h:35min",
    value: 35,
  },
  {
    time: "00h:40min",
    value: 40,
  },
  {
    time: "00h:45min",
    value: 45,
  },
  {
    time: "00h:50min",
    value: 50,
  },
  {
    time: "00h:55min",
    value: 55,
  },
  {
    time: "01h:00min",
    value: 60,
  },
  {
    time: "01h:05min",
    value: 65,
  },
  {
    time: "01h:10min",
    value: 70,
  },
  {
    time: "01h:15min",
    value: 75,
  },
  {
    time: "01h:20min",
    value: 80,
  },
  {
    time: "01h:25min",
    value: 85,
  },
  {
    time: "01h:30min",
    value: 90,
  },
  {
    time: "01h:35min",
    value: 95,
  },
  {
    time: "01h:40min",
    value: 100,
  },
  {
    time: "01h:45min",
    value: 105,
  },
  {
    time: "01h:50min",
    value: 110,
  },
  {
    time: "01h:55min",
    value: 115,
  },
  {
    time: "02h:00min",
    value: 120,
  },
  {
    time: "02h:05min",
    value: 125,
  },
  {
    time: "02h:10min",
    value: 130,
  },
  {
    time: "02h:15min",
    value: 135,
  },
  {
    time: "02h:20min",
    value: 140,
  },
  {
    time: "02h:25min",
    value: 145,
  },
  {
    time: "02h:30min",
    value: 150,
  },
  {
    time: "02h:35min",
    value: 155,
  },
  {
    time: "02h:40min",
    value: 160,
  },
  {
    time: "02h:45min",
    value: 165,
  },
  {
    time: "02h:50min",
    value: 170,
  },
  {
    time: "02h:55min",
    value: 175,
  },
  {
    time: "03h:00min",
    value: 180,
  },
  {
    time: "03h:05min",
    value: 185,
  },
  {
    time: "03h:10min",
    value: 190,
  },
  {
    time: "03h:15min",
    value: 195,
  },
  {
    time: "03h:20min",
    value: 200,
  },
  {
    time: "03h:25min",
    value: 205,
  },
  {
    time: "03h:30min",
    value: 210,
  },
  {
    time: "03h:35min",
    value: 215,
  },
  {
    time: "03h:40min",
    value: 220,
  },
  {
    time: "03h:45min",
    value: 225,
  },
  {
    time: "03h:50min",
    value: 230,
  },
  {
    time: "03h:55min",
    value: 235,
  },
  {
    time: "04h:00min",
    value: 240,
  },
  {
    time: "04h:05min",
    value: 245,
  },
  {
    time: "04h:10min",
    value: 250,
  },
  {
    time: "04h:15min",
    value: 255,
  },
  {
    time: "04h:20min",
    value: 260,
  },
  {
    time: "04h:25min",
    value: 265,
  },
  {
    time: "04h:30min",
    value: 270,
  },
  {
    time: "04h:35min",
    value: 275,
  },
  {
    time: "04h:40min",
    value: 280,
  },
  {
    time: "04h:45min",
    value: 285,
  },
];

export const readingTimeList = [
  {
    time: "Not started yet",
    value: 0,
  },
  {
    time: "03min:00sec",
    value: 180,
  },
  {
    time: "03min:05sec",
    value: 185,
  },
  {
    time: "03min:10sec",
    value: 190,
  },
  {
    time: "03min:15sec",
    value: 195,
  },
  {
    time: "03min:20sec",
    value: 200,
  },
  {
    time: "03min:25sec",
    value: 205,
  },
  {
    time: "03min:30sec",
    value: 210,
  },
  {
    time: "03min:35sec",
    value: 215,
  },
  {
    time: "03min:40sec",
    value: 220,
  },
  {
    time: "03min:45sec",
    value: 225,
  },
  {
    time: "03min:50sec",
    value: 230,
  },
  {
    time: "03min:55sec",
    value: 235,
  },
  {
    time: "04min:00sec",
    value: 240,
  },
  {
    time: "04min:05sec",
    value: 245,
  },
  {
    time: "04min:10sec",
    value: 250,
  },
  {
    time: "04min:15sec",
    value: 255,
  },
  {
    time: "04min:20sec",
    value: 260,
  },
  {
    time: "04min:25sec",
    value: 265,
  },
  {
    time: "04min:30sec",
    value: 270,
  },
  {
    time: "04min:35sec",
    value: 275,
  },
  {
    time: "04min:40sec",
    value: 280,
  },
  {
    time: "04min:45sec",
    value: 285,
  },
  {
    time: "04min:50sec",
    value: 290,
  },
  {
    time: "04min:55sec",
    value: 295,
  },
  {
    time: "05min:00sec",
    value: 300,
  },
  {
    time: "05min:05sec",
    value: 305,
  },
  {
    time: "05min:10sec",
    value: 310,
  },
  {
    time: "05min:15sec",
    value: 315,
  },
  {
    time: "05min:20sec",
    value: 320,
  },
  {
    time: "05min:25sec",
    value: 325,
  },
  {
    time: "05min:30sec",
    value: 330,
  },
  {
    time: "05min:35sec",
    value: 335,
  },
  {
    time: "05min:40sec",
    value: 340,
  },
  {
    time: "05min:45sec",
    value: 345,
  },
  {
    time: "05min:50sec",
    value: 350,
  },
  {
    time: "05min:55sec",
    value: 355,
  },
  {
    time: "06min:00sec",
    value: 360,
  },
  {
    time: "06min:05sec",
    value: 365,
  },
  {
    time: "06min:10sec",
    value: 370,
  },
  {
    time: "06min:15sec",
    value: 375,
  },
  {
    time: "06min:20sec",
    value: 380,
  },
  {
    time: "06min:25sec",
    value: 385,
  },
  {
    time: "06min:30sec",
    value: 390,
  },
  {
    time: "06min:35sec",
    value: 395,
  },
  {
    time: "06min:40sec",
    value: 400,
  },
  {
    time: "06min:45sec",
    value: 405,
  },
  {
    time: "06min:50sec",
    value: 410,
  },
  {
    time: "06min:55sec",
    value: 415,
  },
  {
    time: "07min:00sec",
    value: 420,
  },
  {
    time: "07min:05sec",
    value: 425,
  },
  {
    time: "07min:10sec",
    value: 430,
  },
  {
    time: "07min:15sec",
    value: 435,
  },
  {
    time: "07min:20sec",
    value: 440,
  },
  {
    time: "07min:25sec",
    value: 445,
  },
  {
    time: "07min:30sec",
    value: 450,
  },
  {
    time: "07min:35sec",
    value: 455,
  },
  {
    time: "07min:40sec",
    value: 460,
  },
  {
    time: "07min:45sec",
    value: 465,
  },
  {
    time: "07min:50sec",
    value: 470,
  },
  {
    time: "07min:55sec",
    value: 475,
  },
  {
    time: "08min:00sec",
    value: 480,
  },
  {
    time: "08min:05sec",
    value: 485,
  },
  {
    time: "08min:10sec",
    value: 490,
  },
  {
    time: "08min:15sec",
    value: 495,
  },
  {
    time: "08min:20sec",
    value: 500,
  },
  {
    time: "08min:25sec",
    value: 505,
  },
  {
    time: "08min:30sec",
    value: 510,
  },
  {
    time: "08min:35sec",
    value: 515,
  },
  {
    time: "08min:40sec",
    value: 520,
  },
  {
    time: "08min:45sec",
    value: 525,
  },
  {
    time: "08min:50sec",
    value: 530,
  },
  {
    time: "08min:55sec",
    value: 535,
  },
  {
    time: "09min:00sec",
    value: 540,
  },
  {
    time: "09min:05sec",
    value: 545,
  },
  {
    time: "09min:10sec",
    value: 550,
  },
  {
    time: "09min:15sec",
    value: 555,
  },
  {
    time: "09min:20sec",
    value: 560,
  },
  {
    time: "09min:25sec",
    value: 565,
  },
  {
    time: "09min:30sec",
    value: 570,
  },
  {
    time: "09min:35sec",
    value: 575,
  },
  {
    time: "09min:40sec",
    value: 580,
  },
  {
    time: "09min:45sec",
    value: 585,
  },
  {
    time: "09min:50sec",
    value: 590,
  },
  {
    time: "09min:55sec",
    value: 595,
  },
  {
    time: "10min:00sec",
    value: 600,
  },
  {
    time: "10min:05sec",
    value: 605,
  },
  {
    time: "10min:10sec",
    value: 610,
  },
  {
    time: "10min:15sec",
    value: 615,
  },
  {
    time: "10min:20sec",
    value: 620,
  },
  {
    time: "10min:25sec",
    value: 625,
  },
  {
    time: "10min:30sec",
    value: 630,
  },
  {
    time: "10min:35sec",
    value: 635,
  },
  {
    time: "10min:40sec",
    value: 640,
  },
  {
    time: "10min:45sec",
    value: 645,
  },
  {
    time: "10min:50sec",
    value: 650,
  },
  {
    time: "10min:55sec",
    value: 655,
  },
  {
    time: "11min:00sec",
    value: 660,
  },
  {
    time: "11min:05sec",
    value: 665,
  },
  {
    time: "11min:10sec",
    value: 670,
  },
  {
    time: "11min:15sec",
    value: 675,
  },
  {
    time: "11min:20sec",
    value: 680,
  },
  {
    time: "11min:25sec",
    value: 685,
  },
  {
    time: "11min:30sec",
    value: 690,
  },
  {
    time: "11min:35sec",
    value: 695,
  },
  {
    time: "11min:40sec",
    value: 700,
  },
  {
    time: "11min:45sec",
    value: 705,
  },
  {
    time: "11min:50sec",
    value: 710,
  },
  {
    time: "11min:55sec",
    value: 715,
  },
  {
    time: "12min:00sec",
    value: 720,
  },
  {
    time: "12min:05sec",
    value: 725,
  },
  {
    time: "12min:10sec",
    value: 730,
  },
  {
    time: "12min:15sec",
    value: 735,
  },
  {
    time: "12min:20sec",
    value: 740,
  },
  {
    time: "12min:25sec",
    value: 745,
  },
  {
    time: "12min:30sec",
    value: 750,
  },
  {
    time: "12min:35sec",
    value: 755,
  },
  {
    time: "12min:40sec",
    value: 760,
  },
  {
    time: "12min:45sec",
    value: 765,
  },
  {
    time: "12min:50sec",
    value: 770,
  },
  {
    time: "12min:55sec",
    value: 775,
  },
  {
    time: "13min:00sec",
    value: 780,
  },
  {
    time: "13min:05sec",
    value: 785,
  },
  {
    time: "13min:10sec",
    value: 790,
  },
  {
    time: "13min:15sec",
    value: 795,
  },
  {
    time: "13min:20sec",
    value: 800,
  },
  {
    time: "13min:25sec",
    value: 805,
  },
  {
    time: "13min:30sec",
    value: 810,
  },
  {
    time: "13min:35sec",
    value: 815,
  },
  {
    time: "13min:40sec",
    value: 820,
  },
  {
    time: "13min:45sec",
    value: 825,
  },
  {
    time: "13min:50sec",
    value: 830,
  },
  {
    time: "13min:55sec",
    value: 835,
  },
  {
    time: "14min:00sec",
    value: 840,
  },
  {
    time: "14min:05sec",
    value: 845,
  },
  {
    time: "14min:10sec",
    value: 850,
  },
  {
    time: "14min:15sec",
    value: 855,
  },
  {
    time: "14min:20sec",
    value: 860,
  },
  {
    time: "14min:25sec",
    value: 865,
  },
  {
    time: "14min:30sec",
    value: 870,
  },
  {
    time: "14min:35sec",
    value: 875,
  },
  {
    time: "14min:40sec",
    value: 880,
  },
  {
    time: "14min:45sec",
    value: 885,
  },
  {
    time: "14min:50sec",
    value: 890,
  },
  {
    time: "14min:55sec",
    value: 895,
  },
  {
    time: "15min:00sec",
    value: 900,
  },
  {
    time: "15min:05sec",
    value: 905,
  },
  {
    time: "15min:10sec",
    value: 910,
  },
  {
    time: "15min:15sec",
    value: 915,
  },
  {
    time: "15min:20sec",
    value: 920,
  },
  {
    time: "15min:25sec",
    value: 925,
  },
  {
    time: "15min:30sec",
    value: 930,
  },
  {
    time: "15min:35sec",
    value: 935,
  },
  {
    time: "15min:40sec",
    value: 940,
  },
  {
    time: "15min:45sec",
    value: 945,
  },
  {
    time: "15min:50sec",
    value: 950,
  },
  {
    time: "15min:55sec",
    value: 955,
  },
  {
    time: "16min:00sec",
    value: 960,
  },
  {
    time: "16min:05sec",
    value: 965,
  },
  {
    time: "16min:10sec",
    value: 970,
  },
  {
    time: "16min:15sec",
    value: 975,
  },
  {
    time: "16min:20sec",
    value: 980,
  },
  {
    time: "16min:25sec",
    value: 985,
  },
  {
    time: "16min:30sec",
    value: 990,
  },
  {
    time: "16min:35sec",
    value: 995,
  },
  {
    time: "16min:40sec",
    value: 1000,
  },
  {
    time: "16min:45sec",
    value: 1005,
  },
  {
    time: "16min:50sec",
    value: 1010,
  },
  {
    time: "16min:55sec",
    value: 1015,
  },
  {
    time: "17min:00sec",
    value: 1020,
  },
  {
    time: "17min:05sec",
    value: 1025,
  },
  {
    time: "17min:10sec",
    value: 1030,
  },
  {
    time: "17min:15sec",
    value: 1035,
  },
  {
    time: "17min:20sec",
    value: 1040,
  },
  {
    time: "17min:25sec",
    value: 1045,
  },
  {
    time: "17min:30sec",
    value: 1050,
  },
  {
    time: "17min:35sec",
    value: 1055,
  },
  {
    time: "17min:40sec",
    value: 1060,
  },
  {
    time: "17min:45sec",
    value: 1065,
  },
  {
    time: "17min:50sec",
    value: 1070,
  },
  {
    time: "17min:55sec",
    value: 1075,
  },
  {
    time: "18min:00sec",
    value: 1080,
  },
  {
    time: "18min:05sec",
    value: 1085,
  },
  {
    time: "18min:10sec",
    value: 1090,
  },
  {
    time: "18min:15sec",
    value: 1095,
  },
  {
    time: "18min:20sec",
    value: 1100,
  },
  {
    time: "18min:25sec",
    value: 1105,
  },
  {
    time: "18min:30sec",
    value: 1110,
  },
  {
    time: "18min:35sec",
    value: 1115,
  },
  {
    time: "18min:40sec",
    value: 1120,
  },
  {
    time: "18min:45sec",
    value: 1125,
  },
  {
    time: "18min:50sec",
    value: 1130,
  },
  {
    time: "18min:55sec",
    value: 1135,
  },
  {
    time: "19min:00sec",
    value: 1140,
  },
  {
    time: "19min:05sec",
    value: 1145,
  },
  {
    time: "19min:10sec",
    value: 1150,
  },
  {
    time: "19min:15sec",
    value: 1155,
  },
  {
    time: "19min:20sec",
    value: 1160,
  },
  {
    time: "19min:25sec",
    value: 1165,
  },
  {
    time: "19min:30sec",
    value: 1170,
  },
  {
    time: "19min:35sec",
    value: 1175,
  },
  {
    time: "19min:40sec",
    value: 1180,
  },
  {
    time: "19min:45sec",
    value: 1185,
  },
  {
    time: "19min:50sec",
    value: 1190,
  },
  {
    time: "19min:55sec",
    value: 1195,
  },
  {
    time: "20min:00sec",
    value: 1200,
  },
];

export const dedaTimeList = [
  {
    time: "Not started yet",
    value: 0,
  },
  {
    time: "00h:30min",
    value: 30,
  },
  {
    time: "00h:35min",
    value: 35,
  },
  {
    time: "00h:40min",
    value: 40,
  },
  {
    time: "00h:45min",
    value: 45,
  },
  {
    time: "00h:50min",
    value: 50,
  },
  {
    time: "00h:55min",
    value: 55,
  },
  {
    time: "01h:00min",
    value: 60,
  },
  {
    time: "01h:05min",
    value: 65,
  },
  {
    time: "01h:10min",
    value: 70,
  },
  {
    time: "01h:15min",
    value: 75,
  },
  {
    time: "01h:20min",
    value: 80,
  },
  {
    time: "01h:25min",
    value: 85,
  },
  {
    time: "01h:30min",
    value: 90,
  },
];

export const goalTable = {
  WEEK1: {
    WEEK: "1",
    DEDA: "45",
    ACTIVE: "10",
    PASSIVE: "5",
    TOTAL: "60",
  },
  WEEK2: {
    WEEK: "2",
    DEDA: "45",
    ACTIVE: "10",
    PASSIVE: "10",
    TOTAL: "65",
  },
  WEEK3: {
    WEEK: "3",
    DEDA: "45",
    ACTIVE: "10",
    PASSIVE: "15",
    TOTAL: "70",
  },
  WEEK4: {
    WEEK: "4",
    DEDA: "45",
    ACTIVE: "10",
    PASSIVE: "20",
    TOTAL: "75",
  },
  WEEK5: {
    WEEK: "5",
    DEDA: "45",
    ACTIVE: "15",
    PASSIVE: "25",
    TOTAL: "85",
  },
  WEEK6: {
    WEEK: "6",
    DEDA: "45",
    ACTIVE: "15",
    PASSIVE: "30",
    TOTAL: "90",
  },
  WEEK7: {
    WEEK: "7",
    DEDA: "45",
    ACTIVE: "15",
    PASSIVE: "35",
    TOTAL: "95",
  },
  WEEK8: {
    WEEK: "8",
    DEDA: "45",
    ACTIVE: "15",
    PASSIVE: "40",
    TOTAL: "100",
  },
  WEEK9: {
    WEEK: "9",
    DEDA: "45",
    ACTIVE: "20",
    PASSIVE: "45",
    TOTAL: "110",
  },
  WEEK10: {
    WEEK: "10",
    DEDA: "45",
    ACTIVE: "20",
    PASSIVE: "50",
    TOTAL: "115",
  },
  WEEK11: {
    WEEK: "11",
    DEDA: "45",
    ACTIVE: "20",
    PASSIVE: "55",
    TOTAL: "120",
  },
  WEEK12: {
    WEEK: "12",
    DEDA: "45",
    ACTIVE: "20",
    PASSIVE: "60",
    TOTAL: "125",
  },
  WEEK13: {
    WEEK: "13",
    DEDA: "45",
    ACTIVE: "25",
    PASSIVE: "65",
    TOTAL: "135",
  },
  WEEK14: {
    WEEK: "14",
    DEDA: "45",
    ACTIVE: "25",
    PASSIVE: "70",
    TOTAL: "140",
  },
  WEEK15: {
    WEEK: "15",
    DEDA: "45",
    ACTIVE: "25",
    PASSIVE: "75",
    TOTAL: "145",
  },
  WEEK16: {
    WEEK: "16",
    DEDA: "45",
    ACTIVE: "25",
    PASSIVE: "80",
    TOTAL: "150",
  },
  WEEK17: {
    WEEK: "17",
    DEDA: "45",
    ACTIVE: "30",
    PASSIVE: "85",
    TOTAL: "160",
  },
  WEEK18: {
    WEEK: "18",
    DEDA: "45",
    ACTIVE: "30",
    PASSIVE: "90",
    TOTAL: "165",
  },
  WEEK19: {
    WEEK: "19",
    DEDA: "45",
    ACTIVE: "30",
    PASSIVE: "95",
    TOTAL: "170",
  },
  WEEK20: {
    WEEK: "20",
    DEDA: "45",
    ACTIVE: "30",
    PASSIVE: "100",
    TOTAL: "175",
  },
  WEEK21: {
    WEEK: "21",
    DEDA: "45",
    ACTIVE: "35",
    PASSIVE: "105",
    TOTAL: "185",
  },
  WEEK22: {
    WEEK: "22",
    DEDA: "45",
    ACTIVE: "35",
    PASSIVE: "110",
    TOTAL: "190",
  },
  WEEK23: {
    WEEK: "23",
    DEDA: "45",
    ACTIVE: "35",
    PASSIVE: "115",
    TOTAL: "195",
  },
  WEEK24: {
    WEEK: "24",
    DEDA: "45",
    ACTIVE: "35",
    PASSIVE: "120",
    TOTAL: "200",
  },
  WEEK25: {
    WEEK: "25",
    DEDA: "45",
    ACTIVE: "40",
    PASSIVE: "125",
    TOTAL: "210",
  },
  WEEK26: {
    WEEK: "26",
    DEDA: "45",
    ACTIVE: "40",
    PASSIVE: "130",
    TOTAL: "215",
  },
  WEEK27: {
    WEEK: "27",
    DEDA: "45",
    ACTIVE: "40",
    PASSIVE: "135",
    TOTAL: "220",
  },
  WEEK28: {
    WEEK: "28",
    DEDA: "45",
    ACTIVE: "40",
    PASSIVE: "140",
    TOTAL: "225",
  },
  WEEK29: {
    WEEK: "29",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "145",
    TOTAL: "235",
  },
  WEEK30: {
    WEEK: "30",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK31: {
    WEEK: "31",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK32: {
    WEEK: "32",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK33: {
    WEEK: "33",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK34: {
    WEEK: "34",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK35: {
    WEEK: "35",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK36: {
    WEEK: "36",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK37: {
    WEEK: "37",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK38: {
    WEEK: "38",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK39: {
    WEEK: "39",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK40: {
    WEEK: "40",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK41: {
    WEEK: "41",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK42: {
    WEEK: "42",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK43: {
    WEEK: "43",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK44: {
    WEEK: "44",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK45: {
    WEEK: "45",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK46: {
    WEEK: "46",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK47: {
    WEEK: "47",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK48: {
    WEEK: "48",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK49: {
    WEEK: "49",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK50: {
    WEEK: "50",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK51: {
    WEEK: "51",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK52: {
    WEEK: "52",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK53: {
    WEEK: "53",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK54: {
    WEEK: "54",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK55: {
    WEEK: "55",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK56: {
    WEEK: "56",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK57: {
    WEEK: "57",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK58: {
    WEEK: "58",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK59: {
    WEEK: "59",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
  WEEK60: {
    WEEK: "60",
    DEDA: "45",
    ACTIVE: "45",
    PASSIVE: "150",
    TOTAL: "240",
  },
};

export const subMenuList = {
  dedaNotes: "DEDA NOTES",
  listen: "1) LISTEN",
  read: "2) READ + RECORD",
  watch: "3) WATCH",
  listenRead: "4) LISTEN + READ",
  write: "5) WRITE",
};

export const dedasCheckList = {
  WEEK0: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK1: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK2: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK3: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK4: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK5: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK6: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK7: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK8: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK9: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK10: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK11: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK12: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK13: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK14: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK15: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK16: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK17: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK18: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK19: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK20: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK21: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK22: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK23: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK24: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK25: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK26: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK27: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK28: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK29: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK30: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK31: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK32: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK33: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK34: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK35: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK36: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK37: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK38: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK39: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK40: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK41: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK42: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK43: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK44: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK45: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK46: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK47: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK48: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK49: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK50: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK51: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK52: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK53: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK54: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK55: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK56: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK57: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK58: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK59: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
  WEEK60: {
    dedaNotes: false,
    listen: false,
    read: false,
    watch: false,
    listenRead: false,
    write: false,
  },
};
