import React, { useState, createContext, useEffect, useContext } from "react";
import { UserContext } from "./UserProvider";

export const ProfileContext = createContext({});

export const ProfileProvider = ({ children }) => {
  const { user, isUserLegacy } = useContext(UserContext);
  const [profileData, setProfileData] = useState(false);
  const [isProfileLoading, setIsProfileLoading] = useState(true);

  useEffect(() => {
    if (isUserLegacy) {
      const fetchProfile = async () => {};
      if (user) {
        fetchProfile()
          .then(() => {
            setIsProfileLoading(false);
          })
          .catch((err) => Promise.reject(err));
      }
    }
  }, [user, isUserLegacy]);

  return <ProfileContext.Provider value={{ profileData, isProfileLoading }}>{children}</ProfileContext.Provider>;
};
