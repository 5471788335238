import React from "react";
import { CircularProgress, Container, Typography, Grid } from "@material-ui/core";
import "../../../assets/css/shared/loadingScreen.css";

export default function LoadingScreen() {
  return (
    <React.Fragment>
      <Container maxWidth="xl" className="loading-screen">
        <Grid className="loading-blob" container direction="column" justifyContent="center" alignItems="center">
          <Grid container item direction="column" justifyContent="center" alignItems="center">
            <CircularProgress color="primary" />
            <Typography variant="h6" component="h6">
              <strong>Loading... Please wait</strong>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
