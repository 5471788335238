import React, { useContext, useEffect, useState } from "react";
import { Container, Grid, IconButton, Paper } from "@material-ui/core";
import "../../assets/css/deda/main.css";
import "../../assets/css/deda/articles.css";
import { SettingsContext, UserContext } from "../../../shared/providers";
import MenuIcon from "@material-ui/icons/Menu";
import { hpecList } from "../../utils/constants";
import HpecListMenu from "../../components/hpecComponents/molecules/HpecListMenu";
import HpecsListMenuMobile from "../../components/hpecComponents/molecules/HpecsListMenuMobile";
import Copyright from "../../components/sharedComponents/atoms/Copyright";
import HpecNavigationButtons from "../../components/hpecComponents/molecules/HpecNavigationButtons";
import { useParams } from "react-router-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache } from "@apollo/client";
import { HpecRenderer } from "../../components/hpecComponents/atoms/HpecRenderer";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/pjob7ut4tut3?access_token=JN6ypGRp60TdL_D3CVnABjBscmBl6X2HYXkEME34IU8",
  cache: new InMemoryCache(),
});

export default function Hpec({ scrollTargetRef }) {
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(true);
  const { isDesktop } = useContext(SettingsContext);
  const { newVersionUserDocData } = useContext(UserContext);
  const { currentTime } = newVersionUserDocData;
  const { currentDay } = currentTime;
  const { topic, subTopic } = useParams();
  const [isContentAllowed, setIsContentAllowed] = useState(false);

  // TODO - fix blocking locked content
  useEffect(() => {
    setIsContentAllowed(hpecList[topic].drippingDay - 9 <= currentDay);
  }, [topic, subTopic, currentDay]);

  if (isDesktop) {
    return (
      <main ref={scrollTargetRef} style={{ overflowY: "hidden" }} className="light">
        <div className="main-panel">
          <Paper className="paper-shape paper-props paper-course-content">
            <Grid container direction="row" spacing={1}>
              <Grid item md={isMainMenuOpen ? 3 : 0}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems={isDesktop ? "flex-start" : "stretch"}
                >
                  <Grid item xs={12}>
                    <div>
                      <IconButton
                        style={{
                          color: "#1d212e",
                          backgroundColor: "transparent",
                          padding: "0.5rem",
                        }}
                        onClick={() => {
                          setIsMainMenuOpen(!isMainMenuOpen);
                        }}
                      >
                        <MenuIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {isMainMenuOpen && (
                      <Paper className="paper-props paper-shape paper-secondary content-scroller-height">
                        <HpecListMenu chosenTopic={topic} />
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={isDesktop && isMainMenuOpen ? 9 : 12}>
                <Container className="content-scroller-height" maxWidth="xl">
                  <Grid container direction="column" alignItems="center">
                    <ApolloProvider client={client}>
                      <Grid item xs={12} sm={8} className="module-content">
                        <div className="mt-5">
                          {isContentAllowed ? (
                            <HpecRenderer subTopic={subTopic} />
                          ) : (
                            <>
                              <h2>Sorry, the page you are looking for is not here</h2>
                              <br />
                              <h2>
                                Try another time or{" "}
                                <a style={{ textDecoration: "underline" }} href="/">
                                  click here to go back
                                </a>
                              </h2>
                            </>
                          )}
                        </div>
                      </Grid>
                    </ApolloProvider>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Paper>
          <Grid container direction="row" justifyContent="flex-end" className="full-width mt-4">
            <Grid item xs={2}>
              <HpecNavigationButtons />
            </Grid>
          </Grid>
        </div>
        <Copyright />
      </main>
    );
  } else {
    return (
      <main
        ref={scrollTargetRef}
        style={{ overflowY: isDesktop ? "hidden" : "scroll", marginTop: "9rem", backgroundColor: "#FFFFFF" }}
        className={"light"}
      >
        <Grid container direction="column" spacing={2} alignItems="stretch" className="full-width">
          <Grid item className="sticky-navbar" xs>
            <Grid container direction="row" spacing={4} alignItems="center">
              <Grid item xs={3}>
                <HpecsListMenuMobile chosenTopic={topic} />
              </Grid>
              <Grid xs={9}>
                <Grid item xs={10} md={9}>
                  <HpecNavigationButtons />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ paddingLeft: "2.2rem", paddingRight: "2.2rem" }}>
            <Grid container direction="column" alignItems="center">
              <ApolloProvider client={client}>
                <div className="mt-5">
                  {isContentAllowed ? (
                    <HpecRenderer subTopic={subTopic} />
                  ) : (
                    <>
                      <h2>Sorry, the page you are looking for is not here</h2>
                      <br />
                      <h2>
                        Try another time or{" "}
                        <a style={{ textDecoration: "underline" }} href="/">
                          click here to go back
                        </a>
                      </h2>
                    </>
                  )}
                </div>
              </ApolloProvider>
            </Grid>
          </Grid>
        </Grid>
        <Copyright />
      </main>
    );
  }
}
