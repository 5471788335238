import React, { useContext, useState, useEffect } from "react";
import { Grid, Box, CircularProgress } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import PieChartIcon from "@material-ui/icons/PieChart";
import VerticalSplitIcon from "@material-ui/icons/VerticalSplit";
import ClassIcon from "@material-ui/icons/Class";
import HomeIcon from "@material-ui/icons/Home";
import SettingsIcon from "@material-ui/icons/Settings";
import Typography from "@material-ui/core/Typography";
import { UserContext, HpecContext } from "../../../../shared/providers";
import LockIcon from "@material-ui/icons/Lock";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";

export default function SideMenu() {
  const { isLoading, chosenWeek, newVersionUserDocData } = useContext(UserContext);
  const { currentTime, accountStatus, dedaConfirmedAndStarted } = newVersionUserDocData;
  const { dedaStart, pause } = accountStatus;
  const { currentHpec } = useContext(HpecContext);
  const hpecVc = Object.keys(currentHpec)[2];
  const [activeLink, setActiveLink] = useState(window.location.pathname.split("/")[1]);
  const location = useLocation();

  const shouldLampBeUnlocked =
    (dedaStart.isDedaStartConfirmed && dedaConfirmedAndStarted) ||
    pause.isAccountPaused ||
    dedaStart.startDates.length >= 2;

  useEffect(() => {
    setActiveLink(location.pathname.split("/")[1]);
  }, [location]);

  const menuItems = [
    {
      label: "Home",
      icon: <HomeIcon fontSize="large" />,
      link: "/",
      activeLink: "",
    },
    {
      label: "HPEC",
      icon: <ClassIcon fontSize="large" />,
      link: "/hpec/TOPIC1/VC1",
      activeLink: "hpec",
    },
    {
      label: currentTime.daysSinceMelpBegin < 2 ? "(Locked)" : "DEDA",
      icon: currentTime.daysSinceMelpBegin < 2 ? <LockIcon fontSize="large" /> : <VerticalSplitIcon fontSize="large" />,
      link: currentTime.daysSinceMelpBegin < 2 ? "#" : `/deda/${chosenWeek}/dedaNotes`,
      activeLink: "deda",
    },
    {
      label: !shouldLampBeUnlocked ? "(Locked)" : "LAMP",
      icon: !shouldLampBeUnlocked ? <LockIcon fontSize="large" /> : <PieChartIcon fontSize="large" />,
      link: !shouldLampBeUnlocked ? "#" : "/lamp/performance",
      activeLink: "lamp",
      options: [
        {
          label: "Goal",
          link: "",
        },
        {
          label: "Performance",
          link: "",
        },
        {
          label: "DEDA",
          link: "",
        },
        {
          label: "Active",
          link: "",
        },
        {
          label: "Passive",
          link: "",
        },
        {
          label: "Review",
          link: "",
        },
      ],
    },
    {
      label: "Profile",
      icon: <AccountBoxRoundedIcon fontSize="large" />,
      link: "/profile",
      activeLink: "profile",
    },
  ];

  return (
    <React.Fragment>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Grid container direction="column" spacing={2} alignItems="center">
          {menuItems.map((menuItem) => {
            return (
              <React.Fragment>
                <Grid id="side-menu" item xs={12}>
                  <Link to={menuItem.link}>
                    <Box className={menuItem.activeLink === activeLink ? "side-menu-icon selected" : "side-menu-icon"}>
                      <Grid container direction="row">
                        <Grid item xs={6}>
                          {menuItem.icon}
                        </Grid>
                        <Grid className="side-menu-label" item xs={6}>
                          <Typography
                            variant="h6"
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            {menuItem.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Link>
                </Grid>
              </React.Fragment>
            );
          })}
        </Grid>
      )}
    </React.Fragment>
  );
}
