import React from "react";
import { Typography, Link } from "@material-ui/core";

export default function Copyright({ theme }) {
  return (
    <Typography
      style={{ marginTop: "2vh" }}
      className={"primary-title-" + theme}
      variant="body2"
      color="textSecondary"
      align="center"
    >
      {"Copyright © "}
      <Link color="inherit" href="https://andrefloriano.com.br/">
        MELP
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}
