import React, { useContext, useState } from "react";
import { Button, Fade, Menu, MenuItem, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import AccountBoxRoundedIcon from "@material-ui/icons/AccountBoxRounded";
import ListItemText from "@material-ui/core/ListItemText";
// import SettingsApplicationsRoundedIcon from "@material-ui/icons/SettingsApplicationsRounded";
import MeetingRoomIcon from "@material-ui/icons/MeetingRoom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import Avatar from "@material-ui/core/Avatar";
import Grid from "@material-ui/core/Grid";
import { SettingsContext, UserContext } from "../../../../shared/providers";
import { handleLogout } from "../../../../shared/authentication/handleLogout";

export default function UserMenu() {
  const { newVersionUserDocData } = useContext(UserContext);
  const { userData } = newVersionUserDocData;
  const { firstName } = userData;
  const { isDesktop } = useContext(SettingsContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <div>
        <Grid
          style={{ cursor: "pointer" }}
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={handleClick}
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid style={{ display: "flex", flexDirection: "row", alignItems: "center" }} item xs={10}>
            <div>
              <Avatar
                id="user-avatar"
                alt="user-profile"
                className="dark-background"
                src={userData.profilePhotoUrl ? userData.profilePhotoUrl.split("?")[0] : undefined}
              />
            </div>
            <div>{isDesktop && <ArrowDropDownIcon style={{ justifySelf: "flex-start" }} className="dark-text" />}</div>
          </Grid>
        </Grid>

        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => {
            handleClose(false);
          }}
          TransitionComponent={Fade}
        >
          <Typography variant="subtitle1" style={{ textAlign: "center" }}>
            Howdy, {firstName}
          </Typography>
          <MenuItem>
            <Link className="link-neutral-style" to="/profile">
              <ListItem button>
                <ListItemIcon>
                  <AccountBoxRoundedIcon />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </ListItem>
            </Link>
          </MenuItem>
          {/*<MenuItem>*/}
          {/*  <Link className="link-neutral-style" to="settings">*/}
          {/*    <ListItem button>*/}
          {/*      <ListItemIcon>*/}
          {/*        <SettingsApplicationsRoundedIcon />*/}
          {/*      </ListItemIcon>*/}
          {/*      <ListItemText primary="Settings" />*/}
          {/*    </ListItem>*/}
          {/*  </Link>*/}
          {/*</MenuItem>*/}
          <MenuItem>
            <ListItem>
              <Button
                id="logout-button"
                className="blue-gradient"
                variant="contained"
                color="secondary"
                startIcon={<MeetingRoomIcon />}
                onClick={() => {
                  window.location.pathname = "";
                  handleLogout();
                }}
              >
                Log out
              </Button>
            </ListItem>
          </MenuItem>
        </Menu>
      </div>
    </React.Fragment>
  );
}
