import { clients } from "../../../clients";

const { lampClient } = clients;

export const getInputDataNewVersion = async ({ userUid, chosenWeek, chosenWeekDay }) => {
  try {
    const inputData = await lampClient.get(`/input/${userUid}/${chosenWeek}/${chosenWeekDay}`);
    return inputData;
  } catch (error) {
    throw error;
  }
};
