import React, { useContext } from "react";
import Typography from "@material-ui/core/Typography";
import { Grid, Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import DoneIcon from "@material-ui/icons/Done";
import { SettingsContext, UserContext } from "../../../../shared/providers";
import { goalTable } from "../../../utils/constants";
import "../../../assets/css/lamp/goal.css";
import TimerIcon from "@material-ui/icons/Timer";
import { ComponentTitle } from "../../sharedComponents";

const green = "#55d673";
const version = 2;

export default function Goal() {
  const { newVersionUserDocData } = useContext(UserContext);
  const { settings } = useContext(SettingsContext);

  return (
    <React.Fragment>
      <Paper className="paper-props paper-shape">
        <Grid container direction="column" spacing={4}>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item>
                <Typography variant="h4" component="h4">
                  DAILY ENGLISH DEVELOPMENT ACTIVITY
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item>
                <Typography variant="subtitle1" component="h6">
                  <DoneIcon
                    style={{
                      color: green,
                    }}
                  />
                  <strong>DEDA</strong> will always be accounted as a 45 minutes activity (doesn't matter how long you
                  actually take to finish it).
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" component="h6">
                  <DoneIcon
                    style={{
                      color: green,
                    }}
                  />
                  That's why you're only seeing a checkmark for <strong>DEDA</strong> (LAMP considers it as a daily 45
                  minutes activity).
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Paper className="paper-props paper-shape paper-secondary">
              <Grid container direction="column" spacing={4}>
                <Grid item xs={12}>
                  <Grid container direction="row" spacing={1} alignItems="center" justifyContent="flex-start">
                    <Grid item>
                      <TimerIcon fontSize="large" />
                    </Grid>
                    <Grid item>
                      <ComponentTitle>You can check goal for this week below</ComponentTitle>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <Grid container item direction="row" justifyContent="center">
                    <Grid item xs={12}>
                      <Paper className="paper-props paper-shape">
                        <TableContainer component={Box}>
                          <Table className={`text-table-${settings.theme}`} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell>WEEKS</TableCell>
                                <TableCell>DEDA</TableCell>
                                <TableCell>Active Studying</TableCell>
                                <TableCell>Passive Studying</TableCell>
                                <TableCell>TOTAL PER DAY</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {Object.keys(goalTable).map((row, keys) => {
                                return (
                                  <TableRow
                                    className={
                                      keys + 1 === newVersionUserDocData.currentTime.currentWeek ? "red-background" : ""
                                    }
                                    key={keys + 1}
                                  >
                                    <TableCell>{goalTable[row].WEEK}</TableCell>
                                    <TableCell>
                                      <CheckBoxIcon
                                        style={{
                                          color: green,
                                        }}
                                      />
                                    </TableCell>
                                    <TableCell>
                                      00h:
                                      {goalTable[row].ACTIVE}
                                      min
                                    </TableCell>
                                    <TableCell>
                                      {goalTable[row].PASSIVE / 60 < 10
                                        ? "0" + parseInt(goalTable[row].PASSIVE / 60)
                                        : parseInt(goalTable[row].PASSIVE / 60)}
                                      h:
                                      {goalTable[row].PASSIVE % 60 < 10
                                        ? "0" + (goalTable[row].PASSIVE % 60)
                                        : goalTable[row].PASSIVE % 60}
                                      min
                                    </TableCell>
                                    <TableCell>
                                      {goalTable[row].TOTAL / 60 < 10
                                        ? "0" + parseInt(goalTable[row].TOTAL / 60)
                                        : parseInt(goalTable[row].TOTAL / 60)}
                                      h:
                                      {goalTable[row].TOTAL % 60 < 10
                                        ? "0" + (goalTable[row].TOTAL % 60)
                                        : goalTable[row].TOTAL % 60}
                                      min
                                    </TableCell>
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Paper>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
