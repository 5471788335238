import React, { useState, useEffect, useContext } from "react";
import { Container, Grid, IconButton, Paper } from "@material-ui/core";
import "../../assets/css/deda/main.css";
import "../../assets/css/deda/articles.css";
import DedasListMenu from "../../components/dedaComponents/molecules/DedasListMenu";
import DedasListMenuMobile from "../../components/dedaComponents/molecules/DedasListMenuMobile";
import { SettingsContext, UserContext } from "../../../shared/providers";
import MenuIcon from "@material-ui/icons/Menu";
import NavigationButtonsTop from "../../components/dedaComponents/molecules/NavigationButtonsTop";
import ContentLoading from "../../components/sharedComponents/atoms/ContentLoading";
import { unlockedDedasListMaker } from "../../utils/dedasListMaker";
import { useParams } from "react-router-dom";
import Copyright from "../../components/sharedComponents/atoms/Copyright";
import { DedaNotes, Listen, ReadRecord, Watch, ListenRead, Write } from "../../components/dedaComponents";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://graphql.contentful.com/content/v1/spaces/pjob7ut4tut3?access_token=JN6ypGRp60TdL_D3CVnABjBscmBl6X2HYXkEME34IU8",
  cache: new InMemoryCache(),
});

export default function Deda({ scrollTargetRef }) {
  const [isContentLoading, setIsContentLoading] = useState(true);
  const [isMainMenuOpen, setIsMainMenuOpen] = useState(true);
  const [isListMakeLoading, setIsListMakerLoading] = useState(true);
  const { isDesktop } = useContext(SettingsContext);
  const { newVersionUserDocData } = useContext(UserContext);
  const { unlockedDEDAs } = newVersionUserDocData;
  const { currentTime } = newVersionUserDocData;
  const weekRegex = new RegExp("^week[0-9]{1,2}$");
  const { weekNumber, dedaSubItem } = useParams();

  const [allDedasList, setAllDedasList] = useState({});

  useEffect(() => {
    if (newVersionUserDocData) {
      unlockedDedasListMaker(unlockedDEDAs, true)
        .then((currentDedaList) => {
          setAllDedasList(currentDedaList);
        })
        .finally(() => {
          setIsContentLoading(false);
        })
        .catch((error) => {
          setIsListMakerLoading(false);
          throw error;
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newVersionUserDocData]);

  const renderSubItem = () => {
    const id = allDedasList[weekNumber].dedaId;

    return (
      <React.Fragment>
        {dedaSubItem === "dedaNotes" && id && <DedaNotes dedaId={id} />}
        {dedaSubItem === "listen" && id && <Listen dedaId={id} />}
        {dedaSubItem === "read" && id && <ReadRecord dedaId={id} />}
        {dedaSubItem === "watch" && id && <Watch dedaId={id} />}
        {dedaSubItem === "listenRead" && id && <ListenRead dedaId={id} />}
        {dedaSubItem === "write" && id && <Write dedaId={id} />}
      </React.Fragment>
    );
  };

  if (isDesktop) {
    return (
      <main ref={scrollTargetRef} style={{ overflowY: isDesktop ? "hidden" : "scroll" }} className={"light"}>
        <div className="main-panel">
          <Paper className="paper-shape paper-props paper-course-content">
            <Grid container direction="row" spacing={1}>
              <Grid item md={isDesktop && isMainMenuOpen ? 3 : 0}>
                <Grid
                  container
                  direction="column"
                  justifyContent="flex-start"
                  alignItems={isDesktop ? "flex-start" : "stretch"}
                >
                  <Grid item xs={12}>
                    <div>
                      <IconButton
                        style={{
                          color: "#1d212e",
                          backgroundColor: "transparent",
                          padding: "0.5rem",
                        }}
                        onClick={() => {
                          setIsMainMenuOpen(!isMainMenuOpen);
                        }}
                      >
                        <MenuIcon fontSize="small" />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    {isDesktop && isMainMenuOpen && !isContentLoading && (
                      <Paper className="paper-props paper-shape paper-secondary content-scroller-height">
                        <DedasListMenu weekNumber={weekNumber} allDedasList={allDedasList} />
                      </Paper>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item md={isDesktop && isMainMenuOpen ? 9 : 12}>
                <Container className="content-scroller-height" maxWidth="xl">
                  <Grid container direction="column" alignItems="center">
                    {isContentLoading ? (
                      <ContentLoading />
                    ) : (
                      <ApolloProvider client={client}>
                        <Grid item xs={12} sm={8} className="module-content">
                          {renderSubItem()}
                        </Grid>
                      </ApolloProvider>
                    )}
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Paper>
          <Grid container direction="row" justifyContent="flex-end" className="full-width mt-4">
            <Grid item xs={2}>
              <NavigationButtonsTop weekNumber={weekNumber} dedaSubItem={dedaSubItem} />
            </Grid>
          </Grid>
        </div>
        <Copyright />
      </main>
    );
  } else {
    return (
      <main
        ref={scrollTargetRef}
        style={{ overflowY: isDesktop ? "hidden" : "scroll", marginTop: "9rem", backgroundColor: "#FFFFFF" }}
        className={"light"}
      >
        <Grid container direction="column" spacing={2} alignItems="stretch" className="full-width">
          <Grid item className="sticky-navbar" xs>
            <Grid container direction="row" spacing={4} alignItems="center">
              <Grid item xs={3}>
                <DedasListMenuMobile weekNumber={weekNumber} allDedasList={allDedasList} />
              </Grid>
              <Grid xs={9}>
                <Grid item xs={10} md={9}>
                  <NavigationButtonsTop weekNumber={weekNumber} dedaSubItem={dedaSubItem} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs style={{ paddingLeft: "2.2rem", paddingRight: "2.2rem" }}>
            <Grid container direction="column" alignItems="center">
              {isContentLoading ? (
                <ContentLoading />
              ) : (
                <ApolloProvider client={client}>
                  <Grid item xs={12} sm={8} className="module-content" style={{ marginBottom: "20vh" }}>
                    {renderSubItem()}
                  </Grid>
                </ApolloProvider>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Copyright />
      </main>
    );
  }
}
