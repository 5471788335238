import { clients } from "../../../clients";

export const getDedaIdsList = async () => {
  try {
    const dedasList = await clients.melpClient.get("/deda/list");

    return dedasList;
  } catch (error) {
    console.error("ERROR fetching DEDA list:", error.message);
    throw error;
  }
};
