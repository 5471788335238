import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useDeda } from "../../../hooks";
import { DedaContentArticle, HeadingText, BuildingMessage } from "../atoms";
import { TextContentRender } from "../molecules";
import ContentLoading from "../../sharedComponents/atoms/ContentLoading";

const Section = styled.section`
  width: 100%;
`;

const SectionSeparator = styled.hr`
  width: 100%;
  padding: 0 2rem;
`;

export default function DedaWrite({ dedaId }) {
  const dedaWriteResult = useDeda("dedaWriteQuery", dedaId);
  const { loading, data, error } = dedaWriteResult;

  const dedaWriteContent = useMemo(() => data?.dedaContentCollection?.items[0], [data]);

  if (!loading && data?.dedaContentCollection?.items?.length === 0) {
    return <BuildingMessage />;
  }

  if (loading || data?.dedaContentCollection?.items?.length === 0) {
    return <ContentLoading />;
  }

  if (error) {
    throw error;
  }

  const {
    dedaWriteContentDayOne,
    dedaWriteContentDayTwo,
    dedaWriteContentDayThree,
    dedaWriteContentDayFour,
    dedaWriteContentDayFive,
    dedaWriteContentDaySix,
    dedaWriteContentDaySeven,
  } = dedaWriteContent;

  const daysContent = [
    dedaWriteContentDayOne,
    dedaWriteContentDayTwo,
    dedaWriteContentDayThree,
    dedaWriteContentDayFour,
    dedaWriteContentDayFive,
    dedaWriteContentDaySix,
    dedaWriteContentDaySeven,
  ];

  return (
    <DedaContentArticle>
      {/* <TempMenu /> */}
      <HeadingText hType="h1" fontWeight="700">
        5) WRITE
      </HeadingText>
      {daysContent.map((dayContent, key) => (
        <React.Fragment key={key}>
          <Section>
            <HeadingText hType="h2">DAY {key + 1}</HeadingText>
            <br />
            <TextContentRender rawContent={dayContent?.json} links={dayContent?.links} />
          </Section>
          <SectionSeparator />
        </React.Fragment>
      ))}
    </DedaContentArticle>
  );
}
