import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { IconButton } from "@material-ui/core";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import MenuIcon from "@material-ui/icons/Menu";
import ListItems from "../atoms/ListItems";
import SecondaryListItems from "../atoms/SecondaryListItems";
import logo from "../../../../shared/img/logo-white.png";

const useStyles = makeStyles((theme) => ({
  list: {
    width: "50vw",
    [theme.breakpoints.up("lg")]: {
      width: "20vw",
    },
  },
  fullList: {
    width: "auto",
  },
}));

export default function TemporaryDrawer(props) {
  const { setViewTitle } = props;
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  return (
    <div>
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton color="inherit" onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </IconButton>
          <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
            <div
              style={{
                alignText: "center",
                margin: "1rem auto",
                padding: "0.5rem",
              }}
            >
              <img id="logo-drawer" src={logo} alt="logo" width="200vw" />
            </div>
            <div className={classes.list}>
              <Divider />
              <List className="white-icon">
                <ListItems setViewTitle={setViewTitle} />
              </List>
              <Divider />
              <List className="white-icon">
                <SecondaryListItems setViewTitle={setViewTitle} />
              </List>
            </div>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
