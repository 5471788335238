import React from "react";
import styled from "@emotion/styled";

const StyledLink = styled.a`
    color: blue;
    font-weight: 500;

    &:hover {
        color: red;
        font-weight: 700;
    }

    &:hover::after {
        content: ' \2197'; /* Unicode code for the up-right arrow */
        font-size: inherit;
    }
`;

const MagicLink = ({ magicLink, magicLabel }) => {
  return (
    <StyledLink target="_blank" href={magicLink}>
      {magicLabel}
    </StyledLink>
  );
};

export default MagicLink;
