import React, { useContext, useEffect, useState } from "react";
import { PerformanceContext, UserContext } from "../../../../../shared/providers";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import { getLinearGraph } from "../../../../utils/apiCalls/lampCalls/getLinearGraph";
import Chart from "chart.js";
import chartTrendLine from "chartjs-plugin-trendline";
import { createDailyStatsDataObject } from "../../../../utils/graphs/createDailyStatsDataObject";
import DataPicker from "../DataPicker";
import { unlockedDedasListMaker } from "../../../../utils/dedasListMaker";
import Box from "@material-ui/core/Box";

Chart.plugins.register(chartTrendLine);

export default function DailyStatsGraph({ theme }) {
  const { newVersionUserDocData, user, chosenWeek } = useContext(UserContext);
  const { isPerformanceLoading } = useContext(PerformanceContext);
  let [chartChosenWeek, setChartChosenWeek] = useState(false);

  const [currentDedaList, setCurrentDedaList] = useState({});

  useEffect(() => {
    unlockedDedasListMaker(newVersionUserDocData.unlockedDEDAs).then((list) => {
      delete list.week0;
      setCurrentDedaList(list);
    });
  }, []);

  const ctx = "daily-stats";

  const makeGraphic = async () => {
    if (chosenWeek !== false) {
      if (chartChosenWeek === false) {
        chartChosenWeek = chosenWeek;
      }
      await getLinearGraph(user.uid).then((response) => {
        new Chart(ctx, {
          type: "bar",
          data: createDailyStatsDataObject(response, chartChosenWeek),
          options: {
            responsive: true,
            maintainAspectRatio: false,
            tooltips: {
              callbacks: {
                label: function (tooltipItems, _) {
                  return tooltipItems.yLabel + " minutes";
                },
              },
            },
            legend: {
              labels: {
                fontFamily: "Varela Round",
                fontColor: theme === "dark" ? "white" : "#424242",
                fontSize: 15,
              },
            },
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              xAxes: [
                {
                  ticks: {
                    fontColor: theme === "dark" ? "white" : "#424242",
                    fontSize: 14,
                    fontFamily: "Varela Round",
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
            },
          },
        });
      });
    }
  };

  useEffect(() => {
    makeGraphic().catch((error) => {
      throw error;
    });
  }, [chosenWeek, isPerformanceLoading, chartChosenWeek]);

  return (
    <React.Fragment>
      <Paper className={`paper-props paper-shape`}>
        <Grid className="stretch" container direction="column">
          <Grid container item direction="row">
            <Grid item xs>
              <Typography className="primary-title-light" variant="h5" component="h5">
                <strong>Daily Statistics</strong>
              </Typography>
            </Grid>

            <Grid container item xs justifyContent="flex-end">
              {isPerformanceLoading === false && chosenWeek ? (
                <DataPicker
                  listToRender={currentDedaList}
                  defaultItem={chosenWeek}
                  onSelect={(theValue) => {
                    setChartChosenWeek(theValue);
                  }}
                />
              ) : null}
            </Grid>
          </Grid>
          <Grid container item direction="row">
            <Grid item xs>
              <p>The daily amount of time you have from DEDA. (Click on the legend to select a graph)</p>
            </Grid>
          </Grid>

          <Grid container item justifyContent="center">
            {isPerformanceLoading ? (
              <CircularProgress />
            ) : (
              <Grid container item xs={12}>
                <Box className="graph-wrapper full-width">
                  <canvas id="daily-stats" />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
