import React from "react";
import styled from "@emotion/styled";
import HeadingText from "./HeadingText";

const Message = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;
  margin-top: 5rem;
`;

export default function BuildingMessage() {
  return (
    <Message>
      <HeadingText hType="h3">
        We are still building this content for you. Please check in again another time.
      </HeadingText>
      <HeadingText hType="h4">It will be here soon! 😃</HeadingText>
    </Message>
  );
}
