import React, { useMemo } from "react";
import styled from "@emotion/styled";
import { useDeda } from "../../../hooks";
import { DedaContentArticle, HeadingText, BuildingMessage } from "../atoms";
import ContentLoading from "../../sharedComponents/atoms/ContentLoading";

const DedaWatchVideo = styled.iframe`
  width: 100%;
  height: 22.5rem;
  border: none;
`;

export default function DedaWatch({ dedaId }) {
  const dedaWatchResult = useDeda("dedaWatchQuery", dedaId);
  const { loading, data, error } = dedaWatchResult;

  const dedaWatchContent = useMemo(() => data?.dedaContentCollection?.items[0], [data]);

  if (!loading && data?.dedaContentCollection?.items?.length === 0) {
    return <BuildingMessage />;
  }

  if (loading || data?.dedaContentCollection?.items?.length === 0) {
    return <ContentLoading />;
  }

  if (error) {
    throw error;
  }

  const { dedaWatchVideoLink } = dedaWatchContent;

  return (
    <DedaContentArticle>
      <HeadingText hType="h1" fontWeight="700">
        3) WATCH
      </HeadingText>
      <DedaWatchVideo src={dedaWatchVideoLink} allowFullScreen allowTransparency />
    </DedaContentArticle>
  );
}
