import React from "react";
import DataPicker from "../molecules/DataPicker";
import { timeList } from "../../../utils/constants";

export default function TimeInputList({ defaultItem, onSelect, timeInputList, isInput }) {
  let timeLabelList = {};

  if (timeInputList) {
    Object.keys(timeInputList).forEach((item, index) => {
      timeLabelList[`timeValue${timeInputList[item].value}`] = timeInputList[item].time;
    });
  } else {
    Object.keys(timeList).forEach((item, index) => {
      timeLabelList[`timeValue${timeList[item].value}`] = timeList[item].time;
    });
  }

  return <DataPicker isInput={isInput} onSelect={onSelect} defaultItem={defaultItem} listToRender={timeLabelList} />;
}
