import React, { useContext, useState, useEffect } from "react";
import { Button, Grid, makeStyles } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import "../shared/css/login.css";
import axios from "axios";
//@todo refactor request
import { SettingsContext } from "../shared/providers";
import logoDeda from "../shared/img/svg/deda-horizontal.svg";
import logoHpec from "../shared/img/svg/hpec-horizontal.svg";
import logoLamp from "../shared/img/svg/lamp-horizontal.svg";
import logoMelpLogin from "../shared/img/svg/melp-login.svg";
import LoadingPage from "./LoadingPage";
import { passwordRecovery } from "../shared/authentication/passwordReset";

const checkValidLink = async (userUid, token) => {
  return await axios.get(
    process.env.REACT_APP_SERVICE_ACCOUNT_MANAGER_API +
      `recover-account-password/verify/?userUid=${userUid}&token=${token}`,
    {
      headers: {
        "melp-token": process.env.REACT_APP_MELP_REQUEST_TOKEN,
      },
    },
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function PasswordRecovery() {
  const classes = useStyles();
  const { isDesktop } = useContext(SettingsContext);
  const [newPassword, setNewPassword] = useState("");
  const [passwordMessageStatus, setPasswordMessageStatus] = useState("");
  const [isValidLink, setIsValidLink] = useState(undefined);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const userUid = params.get("userUid");
  const token = params.get("token");

  const flexCenter = {
    display: "flex",
    justifyContent: "center",
  };

  const formWidth = {
    width: "100%",
  };

  useEffect(() => {
    checkValidLink(userUid, token)
      .then((response) => {
        const validStatus = response.data.status === "Success";
        setIsValidLink(validStatus);
      })
      .catch((error) => {
        setIsValidLink(false);
        throw error;
      });
  }, [location, params]);

  if (isValidLink === undefined) {
    return <LoadingPage />;
  } else if (isValidLink === true) {
    return (
      <div className="main">
        <Grid container direction="row" alignItems="stretch">
          {isDesktop && (
            <Grid className="full-vh" item xs={12} sm={6}>
              <Grid className="full-height vertical-padding" container direction="column" justifyContent="flex-end">
                <Grid item>
                  <Grid
                    className="paper-padding"
                    container
                    direction="row"
                    spacing={4}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoDeda} alt="logo-deda" />
                    </Grid>
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoHpec} alt="logo-deda" />
                    </Grid>
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoLamp} alt="logo-deda" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className={isDesktop ? "dark-blue-background" : "dark-blue-background mobile-form"} item xs={12} sm={6}>
            <Grid
              style={{ height: "100%" }}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Grid
                item
                style={{
                  width: isDesktop ? "50%" : "80%",
                }}
              >
                <Grid column spacing={4}>
                  <Grid
                    style={{
                      ...flexCenter,
                      ...formWidth,
                      marginBottom: "2.5rem",
                    }}
                    item
                    sm={12}
                  >
                    <img style={formWidth} id="login-logo" src={logoMelpLogin} alt="melp-text-white-login" />
                  </Grid>
                  <Grid
                    style={{
                      ...formWidth,
                      flexCenter,
                    }}
                    item
                    sm={12}
                  >
                    <p className="color-white">
                      Digite uma nova senha abaixo para redefinir sua conta. A senha precisa ter no mínimo 8 dígitos.
                    </p>
                    {passwordMessageStatus === "" ? (
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();

                          passwordRecovery({ userUid, token, newPassword })
                            .then(() => {
                              setPasswordMessageStatus("Sua senha foi atualizada com sucesso");
                              alert(
                                "Senha atualizada com sucesso! Por favor, faça o login novamente utilizando a nova senha.",
                              );
                              document.location.href = "/";
                            })
                            .catch((_) => {
                              setPasswordMessageStatus(
                                "Ops! Algo deu errado. Tente recarregar a página ou faça um novo pedido de recuperação de senha.",
                              );
                            });
                        }}
                        className={classes.form}
                      >
                        <input
                          style={{ marginBottom: "1rem" }}
                          type="password"
                          className="form-field"
                          required
                          placeholder="Nova senha"
                          id="password"
                          name="passwordRecovery"
                          autoComplete={false}
                          autoFocus
                          value={newPassword}
                          onChange={(event) => {
                            setNewPassword(event.target.value);
                          }}
                        />
                        <Button id="login-button" type="submit" fullWidth disabled={newPassword.length < 8}>
                          Redefinir
                        </Button>

                        <Grid
                          style={{
                            marginTop: "3vh",
                          }}
                          container
                        >
                          <Grid item xs>
                            <Link className="link-neutral-style color-white" to="/" variant="body2">
                              Voltar à página principal
                            </Link>
                          </Grid>
                        </Grid>
                      </form>
                    ) : (
                      <p style={{ marginTop: "1rem" }} className="color-white">
                        {passwordMessageStatus}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  } else if (isValidLink === false) {
    return (
      <div className="main">
        <Grid container direction="row" alignItems="stretch">
          {isDesktop && (
            <Grid className="full-vh" item xs={12} sm={6}>
              <Grid className="full-height vertical-padding" container direction="column" justifyContent="flex-end">
                <Grid item>
                  <Grid
                    className="paper-padding"
                    container
                    direction="row"
                    spacing={4}
                    justifyContent="space-between"
                    alignItems="flex-start"
                  >
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoDeda} alt="logo-deda" />
                    </Grid>
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoHpec} alt="logo-deda" />
                    </Grid>
                    <Grid style={flexCenter} item xs={12} sm={4}>
                      <img className="logo-secondary" src={logoLamp} alt="logo-deda" />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid className={isDesktop ? "dark-blue-background" : "dark-blue-background mobile-form"} item xs={12} sm={6}>
            <Grid
              style={{ height: "100%" }}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
              spacing={0}
            >
              <Grid
                item
                style={{
                  width: isDesktop ? "50%" : "80%",
                }}
              >
                <Grid column spacing={4}>
                  <Grid
                    style={{
                      ...flexCenter,
                      ...formWidth,
                      marginBottom: "2.5rem",
                    }}
                    item
                    sm={12}
                  >
                    <img style={formWidth} id="login-logo" src={logoMelpLogin} alt="melp-text-white-login" />
                  </Grid>
                  <Grid
                    style={{
                      ...formWidth,
                      flexCenter,
                    }}
                    item
                    sm={12}
                  >
                    <h1 className="color-white">
                      O link que você está tentando acessar não é mais válido{" "}
                      <Link to="/">
                        <span style={{ color: "#FFF", textDecoration: "underline" }}>clique aqui para voltar</span>
                      </Link>
                    </h1>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}
