import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import Copyright from "../../components/sharedComponents/atoms/Copyright";
import { ViewRender, LampTopMenu } from "../../components/lampComponents/molecules";
import { InputDataProvider, SettingsContext } from "../../../shared/providers";
import "../../assets/css/lamp/App.css";
import "../../assets/css/lamp/dashboard.css";
import { useParams } from "react-router-dom";

export default function Lamp({ scrollTargetRef }) {
  const { settings } = useContext(SettingsContext);
  const { renderView } = useParams();

  return (
    <React.Fragment>
      <main
        ref={scrollTargetRef}
        // style={{ overflowY: isDesktop ? "hidden" : "scroll" }}
        className={"light"}
      >
        <div className="main-panel module-panel">
          <div id="main-content-deda" className="bottom-content-margin">
            <LampTopMenu />
            <InputDataProvider>
              <ViewRender renderView={renderView === "" ? "performance" : renderView} />
            </InputDataProvider>
            <Grid item xs={12}>
              <Copyright />
            </Grid>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}
