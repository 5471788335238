import React, { useContext } from "react";
import { Grid, Paper, Typography } from "@material-ui/core";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import { ComponentTitle } from "../../../sharedComponents";
import { InputStatisticTile } from "../index";
import { TargetIndex } from "../../atoms";
import { InputDataContext, SettingsContext } from "../../../../../shared/providers";

export default function ActiveInput() {
  const { inputData } = useContext(InputDataContext);
  const { isDesktop } = useContext(SettingsContext);

  return (
    <React.Fragment>
      <Paper className={"paper-props paper-shape paper-secondary"}>
        <Grid container direction="column" spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <Grid container direction="row" spacing={2} alignItems="space-between">
              <Grid item xs={2} md={1}>
                <AssignmentTurnedInIcon fontSize="large" />
              </Grid>
              <Grid item xs={5} md={8}>
                <ComponentTitle>ACTIVE</ComponentTitle>
                {isDesktop && <Typography variant="subtitle1">Input your Active Studying (HH:MM)</Typography>}
              </Grid>
              <Grid item xs={5} md={3}>
                <TargetIndex
                  title="Active progress"
                  index={inputData && inputData.inputData.active.percentageConcluded}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            style={{
              overflowX: !isDesktop ? "scroll" : "hidden",
              overflowY: "hidden",
            }}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              style={{
                width: !isDesktop ? "73rem" : "auto",
              }}
            >
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Deda notes"
                  inputTime={inputData && inputData.inputData.active.dedaNotes}
                  inputValueName="dedaNotes"
                  statisticObjectProp="active"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Review"
                  inputTime={inputData && inputData.inputData.active.review}
                  inputValueName="review"
                  statisticObjectProp="active"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Book"
                  inputTime={inputData && inputData.inputData.active.book}
                  inputValueName="book"
                  statisticObjectProp="active"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="MOOC"
                  inputTime={inputData && inputData.inputData.active.mooc}
                  inputValueName="mooc"
                  statisticObjectProp="active"
                />
              </Grid>
              <Grid item xs md={4}>
                <InputStatisticTile
                  inputName="Others"
                  inputTime={inputData && inputData.inputData.active.others}
                  inputValueName="others"
                  statisticObjectProp="active"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
