import React, { useState, createContext } from "react";

export const DedaContext = createContext({});

export const DedaProvider = ({ children }) => {
  const [dedaEvaluation, setDedaEvaluation] = useState(false);
  const [dedaTimes, setDedaTimes] = useState(false);

  const valueObject = {
    dedaEvaluation,
    setDedaEvaluation,
    dedaTimes,
    setDedaTimes,
  };

  return <DedaContext.Provider value={valueObject}>{children}</DedaContext.Provider>;
};
