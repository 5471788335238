import React, { useContext, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import { allowedHpecVcListMaker } from "../../../utils/dedasListMaker";
import { HpecContext, SettingsContext } from "../../../../shared/providers";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import Button from "@material-ui/core/Button";
import { hpecList } from "../../../utils/constants";

export default function HpecNavigationButtons() {
  const location = useLocation();
  const [navigation, setNavigation] = useState({
    previousLink: "#",
    previousLabel: {
      subtitle: "",
      title: "",
    },
    nextLink: "#",
    nextLabel: {
      subtitle: "",
      title: "",
    },
  });

  const { currentHpec } = useContext(HpecContext);
  const { isDesktop } = useContext(SettingsContext);

  const defineLinks = (currentHpecList) => {
    const urlSplit = location.pathname.split("/");
    const urlTopic = urlSplit[2];
    const urlVc = urlSplit[3];

    const currentTopicLabel = urlTopic;
    const topicList = Object.keys(currentHpecList);
    const currentTopic = currentHpecList[currentTopicLabel];
    const currentTopicArray = Object.keys(currentTopic);
    const currentTopicSize = currentTopicArray.length;

    const previousTopicLabel = topicList[topicList.indexOf(urlTopic) - 1];
    const previousTopic = topicList.indexOf(urlTopic) > 0 ? currentHpecList[previousTopicLabel] : null;
    const previousTopicArray = previousTopic && Object.keys(previousTopic);
    const previousTopicSize = previousTopic && previousTopicArray.length;

    const nextTopicLabel = topicList[topicList.indexOf(urlTopic) + 1];
    const nextTopic = topicList.indexOf(urlTopic) < topicList.length - 1 ? currentHpecList[nextTopicLabel] : null;
    const nextTopicArray = nextTopic && Object.keys(nextTopic);
    // const nextTopicSize = nextTopic && nextTopicArray.length;

    const currentVcLabel = urlVc;
    // const currentVc = currentTopic[currentVcLabel];

    const previousVc =
      currentTopicArray.indexOf(currentVcLabel) === 0 && previousTopic === null //is the vc the first one of all?
        ? null
        : currentTopicArray.indexOf(currentVcLabel) === 0 //is the vc the first one of the topic?
        ? previousTopic[previousTopicArray[previousTopicSize - 1]]
        : currentTopic[currentTopicArray[currentTopicArray.indexOf(currentVcLabel) - 1]];

    const previousVcLabel =
      previousVc === null
        ? null
        : currentTopicArray.indexOf(currentVcLabel) === 0 //is the vc the first one of the topic?
        ? previousTopicArray[previousTopicSize - 1]
        : currentTopicArray[currentTopicArray.indexOf(currentVcLabel) - 1];

    const nextVc =
      currentTopicArray.indexOf(currentVcLabel) === currentTopicSize - 1 && nextTopic === null //is the vc the last one of all?
        ? null
        : currentTopicArray.indexOf(currentVcLabel) === currentTopicSize - 1 //is the vc the last one of the topic?
        ? nextTopic[nextTopicArray[0]]
        : currentTopic[currentTopicArray[currentTopicArray.indexOf(currentVcLabel) + 1]];

    const nextVcLabel =
      nextVc === null
        ? null
        : currentTopicArray.indexOf(currentVcLabel) === currentTopicSize - 1 //is the vc the last one of the topic?
        ? nextTopicArray[0]
        : currentTopicArray[currentTopicArray.indexOf(currentVcLabel) + 1];

    const previousLinkPath =
      previousVcLabel === null
        ? "#"
        : currentTopicArray.indexOf(currentVcLabel) === 0
        ? `/hpec/${previousTopicLabel}/${previousVcLabel}`
        : `/hpec/${currentTopicLabel}/${previousVcLabel}`;

    const nextLinkPath =
      nextVcLabel === null
        ? "#"
        : currentTopicArray.indexOf(currentVcLabel) === currentTopicSize - 1
        ? `/hpec/${nextTopicLabel}/${nextVcLabel}`
        : `/hpec/${currentTopicLabel}/${nextVcLabel}`;

    setNavigation({
      previousLink: previousLinkPath,
      previousLabel: {
        subtitle: previousTopic === null && previousVc === null ? "" : "Previous",
        title: previousVc,
      },
      nextLink: nextLinkPath,
      nextLabel: {
        subtitle: nextTopic === null && nextVc === null ? "" : "Next",
        title: nextVc,
      },
    });
  };

  useEffect(() => {
    if (currentHpec) {
      const currentHpecList = {
        ...allowedHpecVcListMaker(currentHpec),
      };

      currentHpecList.TOPIC1 = {
        VC1: hpecList["TOPIC1"].VC1,
        VC2: hpecList["TOPIC1"].VC2,
        VC3: hpecList["TOPIC1"].VC3,
      };

      defineLinks(currentHpecList);
    }
  }, [currentHpec, location]);

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={isDesktop ? 4 : 1} alignItems="center">
        <Grid item xs={6}>
          <Link to={navigation.previousLink} disabled={navigation.previousLink === "#"}>
            <Button variant="contained" fullWidth className="nav-buttons bg-transparent-i">
              {navigation.previousLink === "#" ? null : (
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item xs={2}>
                    <NavigateBeforeIcon
                      className="dark-text"
                      style={{
                        fontSize: isDesktop ? "1.5rem" : "0.7rem",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container direction="column" spacing={0} justifyContent="center" alignItems="flex-start">
                      <Grid item>
                        <h6
                          className="dark-text font-bold"
                          style={{
                            fontSize: isDesktop ? "1rem" : "0.7rem",
                          }}
                        >
                          {navigation.previousLabel.subtitle}
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to={navigation.nextLink}>
            <Button variant="contained" fullWidth className="nav-buttons bg-transparent-i">
              {navigation.nextLink === "#" ? null : (
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Grid container direction="column" spacing={0} justifyContent="center" alignItems="flex-end">
                      <Grid item>
                        <h6
                          className="dark-text font-bold"
                          style={{
                            fontSize: isDesktop ? "1rem" : "0.7rem",
                          }}
                        >
                          {navigation.nextLabel.subtitle}
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <NavigateNextIcon
                      className="dark-text"
                      style={{
                        fontSize: isDesktop ? "1.5rem" : "0.7rem",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
