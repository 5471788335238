import React, { useState, createContext, useContext, useEffect } from "react";
import { UserContext } from "./UserProvider";
import { hpecList as hpecListNew } from "../../MELPv3/utils/constants";

export const HpecContext = createContext({});

export const HpecProvider = ({ children }) => {
  const { isUserLegacy, newVersionUserDocData } = useContext(UserContext);
  const [currentHpec, setCurrentHpec] = useState(false);
  const [nextHpec, setNextHpec] = useState(false);
  const hpecListArray = Object.keys(hpecListNew);

  useEffect(() => {
    if (!isUserLegacy && newVersionUserDocData) {
      //New api version
      const { accountStatus, currentTime, dedaConfirmedAndStarted } = newVersionUserDocData;
      const { daysSinceMelpBegin } = currentTime;
      const { dedaStart, pause } = accountStatus;
      const { isDedaStartConfirmed } = dedaStart;
      const { isAccountPaused } = pause;

      hpecListArray.forEach((hpec, index) => {
        //if the user is in the first nine days
        if (daysSinceMelpBegin < 9) {
          if (daysSinceMelpBegin >= hpecListNew[hpec].drippingDay) {
            if (daysSinceMelpBegin < 8) {
              setCurrentHpec({
                ...hpecListNew[hpec],
                hpecNumber: index + 1,
              });
              setNextHpec({
                ...hpecListNew[hpecListArray[index + 1]],
                hpecNumber: index + 2,
              });
            } else {
              setCurrentHpec({
                ...hpecListNew[hpec],
                hpecNumber: index + 1,
              });
              setNextHpec(null);
            }
          }
        } else if (
          (daysSinceMelpBegin >= 9 && !isDedaStartConfirmed && !isAccountPaused) ||
          (isDedaStartConfirmed && !dedaConfirmedAndStarted)
        ) {
          setCurrentHpec({
            ...hpecListNew.TOPIC4,
            hpecNumber: 4,
          });
          setNextHpec(null);
        } else if ((isDedaStartConfirmed && dedaConfirmedAndStarted) || isAccountPaused) {
          if (currentTime.currentDay >= hpecListNew[hpec].drippingDay - 9) {
            setCurrentHpec({
              ...hpecListNew[hpec],
              //the hpec number equals to the index number + 1
              hpecNumber: index + 1,
            });
            setNextHpec({
              ...hpecListNew[hpecListArray[index + 1]],
              //the hpec number equals to the index number + 2
              hpecNumber: index + 2,
            });
          }
        } else if (isDedaStartConfirmed && currentTime.currentDay >= 730) {
          setCurrentHpec({
            ...hpecListNew.TOPIC9,
            hpecNumber: 9,
          });
          setNextHpec(null);
        }
      });
    }
  }, [isUserLegacy, newVersionUserDocData]);

  return <HpecContext.Provider value={{ currentHpec, nextHpec }}>{children}</HpecContext.Provider>;
};
