import { hpecList, hpecListSubMenu } from "./constants";
import { getDedaIdsList } from "./apiCalls/deda/read/getDedasIdsList";

export const unlockedDedasListMaker = async (unlockedDedas, isMenu) => {
  const unlockedList = {};

  const dedasIdsList = await getDedaIdsList().then((result) => result);

  await Promise.all(
    unlockedDedas.map((unlockedDedaId, index) => {
      if (isMenu) {
        unlockedList[`week${index}`] = {
          dedaId: unlockedDedaId,
          dedaName: dedasIdsList[unlockedDedaId],
        };
      } else {
        unlockedList[`week${index}`] = dedasIdsList[unlockedDedaId];
      }
    }),
  );

  return unlockedList;
};

export const allowedHpecListMaker = (currentHpec) => {
  const limitObject = {};

  const hpecListArray = Object.keys(hpecList);
  const indexOfCurrentHpec = hpecListArray.indexOf("TOPIC" + currentHpec.hpecNumber);
  const allowedTopics = hpecListArray.slice(1, indexOfCurrentHpec + 1);

  allowedTopics.forEach((topic) => {
    limitObject[topic] = hpecList[topic];
  });

  return limitObject;
};

export const allowedHpecVcListMaker = (currentHpec) => {
  const limitObject = {};

  const hpecListArray = Object.keys(hpecList);
  const indexOfCurrentHpec = hpecListArray.indexOf("TOPIC" + currentHpec.hpecNumber);
  const allowedSubTopics = hpecListArray.slice(0, indexOfCurrentHpec + 1);

  allowedSubTopics.forEach((topic) => {
    limitObject[topic] = hpecListSubMenu[topic];
  });

  return limitObject;
};

export const blockedHpecListMaker = (currentHpec) => {
  const limitObject = {};

  const hpecListArray = Object.keys(hpecList);
  const indexOfCurrentHpec = hpecListArray.indexOf("TOPIC" + currentHpec.hpecNumber);
  const blockedTopics = hpecListArray.slice(indexOfCurrentHpec + 1);

  blockedTopics.forEach((topic) => {
    limitObject[topic] = hpecListSubMenu[topic];
  });

  return limitObject;
};
