import React, { useEffect, useState, useContext } from "react";
import { Grid, Paper } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import InsertChartRoundedIcon from "@material-ui/icons/InsertChartRounded";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import { SettingsContext } from "../../../../shared/providers";

export default function LampTopMenu() {
  const { isDesktop } = useContext(SettingsContext);
  const [activeLink, setActiveLink] = useState(window.location.pathname.split("/")[2]);
  const location = useLocation();

  useEffect(() => {
    setActiveLink(location.pathname.split("/")[2]);
  }, [location]);

  const menuButtons = {
    performance: {
      icon: <InsertChartRoundedIcon />,
      label: "Performance",
      link: "/lamp/performance",
      activeLink: "performance",
    },
    input: {
      icon: <BorderColorRoundedIcon />,
      label: "Input",
      link: "/lamp/input",
      activeLink: "input",
    },
    goal: {
      icon: <TrendingUpIcon />,
      label: "Goal",
      link: "/lamp/goal",
      activeLink: "goal",
    },
  };

  return (
    <React.Fragment>
      <Grid style={{ marginBottom: "2vh" }} container direction="row" justifyContent="flex-start" spacing={2}>
        {Object.keys(menuButtons).map((button) => {
          const { icon, label, link } = menuButtons[button];
          return (
            <React.Fragment>
              <Grid item xs={4} md={2}>
                <Link to={link}>
                  <Paper
                    className={
                      menuButtons[button].activeLink === activeLink
                        ? "paper-shape paper-props smaller-padding lamp-menu-item selected"
                        : "paper-shape paper-props smaller-padding lamp-menu-item"
                    }
                  >
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={3}>
                      <Grid item xs={1}>
                        {icon}
                      </Grid>
                      <Grid item xs={8}>
                        <h6
                          style={{
                            fontSize: !isDesktop && "0.6rem",
                          }}
                        >
                          <strong>{label}</strong>
                        </h6>
                      </Grid>
                    </Grid>
                  </Paper>
                </Link>
              </Grid>
            </React.Fragment>
          );
        })}
      </Grid>
    </React.Fragment>
  );
}
