import React, { useState, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@material-ui/core";
import HotelIcon from "@material-ui/icons/Hotel";
import Button from "@material-ui/core/Button";

export default function TimeoutSessionDialog() {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  let idleTime = 0;

  useEffect(() => {
    const idleTimerIncrement = () => {
      idleTime += 1;
      if (idleTime > 3600) {
        setIsSessionExpired(true);
        clearInterval(idleTimerInterval);
      }
    };

    const idleTimerInterval = setInterval(idleTimerIncrement, 1000);

    [
      "mousemove",
      "keydown",
      "wheel",
      "DOMMouseScroll",
      "mousewheel",
      "mousedown",
      "touchstart",
      "touchmove",
      "MSPointerDown",
      "MSPointerMove",
      "visibilitychange",
    ].forEach((event) => {
      window.addEventListener(event, () => {
        idleTime = 0;
      });
    });
  }, []);

  return (
    <React.Fragment>
      <Dialog open={isSessionExpired} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item>
              <HotelIcon fontSize="large" />
            </Grid>
            <Grid item>
              <h2>Session expired</h2>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h3>You have been inactive for a while, please click on the button below to refresh the page.</h3>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            className="dark-background primary-title-dark"
          >
            Got it!
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
