import axios from "axios";

const baseUrl = process.env.REACT_APP_SERVICE_ACCOUNT_MANAGER_API;
const melpToken = process.env.REACT_APP_MELP_REQUEST_TOKEN;
const requestConfig = {
  headers: {
    "melp-token": melpToken,
  },
};

export const getAdminData = async (userUid) => {
  try {
    return await axios
      .get(`${baseUrl}admin-data?userUid=${userUid}`, requestConfig)
      .then((adminResult) => {
        return adminResult.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (error) {
    console.error("new api user error", error);
    throw error;
  }
};
