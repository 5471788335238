import React, { useContext } from "react";
import { Grid } from "@material-ui/core";
import { PerformanceContext, SettingsContext, UserContext } from "../../../../shared/providers";
import {
  MainStatisticTile,
  DailyStatsGraph,
  LinearGraphDevelopment,
  OverallPieGraph,
  WeeklyStatsGraph,
} from "../molecules";
import "../../../assets/css/lamp/performance.css";
import LoadingScreen from "../atoms/LoadingScreen";

export default function Performance() {
  const { mainPerformanceStats, isPerformanceLoading } = useContext(PerformanceContext);
  const { newVersionUserDocData } = useContext(UserContext);
  const { settings } = newVersionUserDocData;

  return (
    <React.Fragment>
      {isPerformanceLoading ? (
        <LoadingScreen />
      ) : (
        <React.Fragment>
          <Grid container direction="column" spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <Grid container direction="row" spacing={4} justifyContent="space-between">
                <Grid item xs={6} lg={3}>
                  <MainStatisticTile topic="Deda" statistic={mainPerformanceStats.general.dedaQuality} theme="deda" />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <MainStatisticTile topic="Active" statistic={mainPerformanceStats.general.active} theme="active" />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <MainStatisticTile topic="Passive" statistic={mainPerformanceStats.general.passive} theme="passive" />
                </Grid>
                <Grid item xs={6} lg={3}>
                  <MainStatisticTile topic="Review" statistic={mainPerformanceStats.general.review} theme="review" />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="stretch">
                <Grid container item xs={12} lg={4}>
                  <Grid container item direction="row" alignItems="stretch">
                    <OverallPieGraph theme={settings.theme} />
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={8}>
                  <LinearGraphDevelopment theme={settings.theme} />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container direction="row" spacing={4} justifyContent="space-between" alignItems="stretch">
                <Grid item xs={12} md={6}>
                  <WeeklyStatsGraph theme={settings.theme} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <DailyStatsGraph theme={settings.theme} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </React.Fragment>
  );
}
