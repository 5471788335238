// eslint-disable-next-line
import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import { Link } from "react-router-dom";
import { UserContext } from "../../../../shared/providers";
import { addDays } from "date-fns";

export default function HpecSubMenu({ topic, isBlocked, subList, drippingDay }) {
  const { newVersionUserDocData } = useContext(UserContext);
  const { currentTime, accountStatus } = newVersionUserDocData;

  const getDayFutureDate = () => {
    if (accountStatus.dedaStart.isDedaStartConfirmed && !accountStatus.pause.isAccountPaused) {
      const daysUntilNextHpec = drippingDay - 9 - currentTime.currentDay;
      const today = new Date();
      const hpecVcOpenDate = addDays(today, daysUntilNextHpec);

      return "Opens on " + hpecVcOpenDate.toLocaleDateString();
    }
    if (drippingDay <= 9) {
      const daysUntilNextHpec = drippingDay - currentTime.daysSinceMelpBegin;
      const today = new Date();
      const hpecVcOpenDate = addDays(today, daysUntilNextHpec);

      return "Opens on " + hpecVcOpenDate.toLocaleDateString();
    } else {
      return "START REQUIRED";
    }
  };

  const futureDate = getDayFutureDate();

  return (
    <React.Fragment>
      {isBlocked
        ? Object.keys(subList).map((subMenuItem) => {
            return (
              <Grid
                className="list-option"
                style={{ padding: "0.6rem" }}
                container
                direction="row"
                spacing={0}
                alignItems="center"
              >
                <Grid
                  item
                  style={{
                    paddingLeft: "0.4rem",
                  }}
                >
                  <LockIcon />
                </Grid>
                <Grid
                  item
                  style={{
                    paddingLeft: "0.4rem",
                  }}
                >
                  <Typography
                    style={{
                      fontWeight: "300",
                    }}
                    variant="subtitle2"
                  >
                    {futureDate}
                  </Typography>
                  <Typography
                    style={{
                      fontWeight: "400",
                    }}
                    variant="subtitle1"
                  >
                    {subList[subMenuItem]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })
        : Object.keys(subList).map((subMenuItem) => {
            return (
              <Link
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
                to={`/hpec/${topic}/${subMenuItem}`}
              >
                <Grid
                  className="list-option"
                  style={{ padding: "0.6rem" }}
                  container
                  direction="row"
                  spacing={0}
                  alignItems="center"
                >
                  <Grid
                    item
                    style={{
                      paddingLeft: "0.4rem",
                    }}
                  >
                    <Typography
                      style={{
                        fontWeight: "400",
                      }}
                      variant="subtitle1"
                    >
                      {subList[subMenuItem]}
                    </Typography>
                  </Grid>
                </Grid>
              </Link>
            );
          })}
    </React.Fragment>
  );
}
