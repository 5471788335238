import React from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Button } from "@material-ui/core";

function DedaStart() {
  return (
    <React.Fragment>
      <h4>Você já pode começar o programa!</h4>
      <br />
      <h4>
        <strong>Se você...</strong>
      </h4>
      <br />
      <ul>
        <li>
          <h4>Já assistiu todos os primeiros 14 vídeos do HPEC (e leu seus respectivos resumos)</h4>
        </li>
        <li>
          <h4>Já sabe o quê é e como fazer o DEDA diariamente (incluindo o DEDA Notes)</h4>
        </li>
        <li>
          <h4>Já sabe o quê é e como computar suas atividades na LAMP diariamente</h4>
        </li>
      </ul>
      <br />
      <h4>
        ...então, clique em <strong style={{ backgroundColor: "#57af16", color: "#000" }}>"START"</strong> e o sistema
        irá configurar sua conta para que o <strong>primeiro dia do primeiro DEDA a ser computado na LAMP</strong> seja
        na próxima <strong>segunda-feira.</strong>
      </h4>
      <br />
      <h4>
        Caso você ainda não tenha assistido todos os primeiros 14 vídeos do HPEC ou não tenha feito com a devida
        atenção, clique em <strong style={{ backgroundColor: "#c22a2a", color: "#FFF" }}>"NOT YET"</strong> e
        assista/estude o que for necessário para que você saiba, de fato, fazer o DEDA e computar todas as suas
        atividades, até aqui ensinadas, na LAMP.{" "}
      </h4>
      <br />
      <h4>
        Não tenha pressa! Tenha calma e paciência! Siga em frente somente se você tiver certeza de que já chegou o
        momento certo de começar!
      </h4>
      <br />
      <h4>
        Caso hoje seja este momento, clique em{" "}
        <strong style={{ backgroundColor: "#57af16", color: "#000" }}>"START"</strong> e aguarde que segunda-feira será
        o seu DAY 01 oficial na LAMP.
      </h4>
    </React.Fragment>
  );
}

export default function PopUpAlert({ popMessage, openPopup, onClose, closePopup, isDedaStart }) {
  return (
    <React.Fragment>
      <Dialog open={openPopup} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <Grid item>
              <h2>{popMessage.title}</h2>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isDedaStart ? <DedaStart /> : <h3>{popMessage.content}</h3>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isDedaStart ? (
            <React.Fragment>
              <Button
                className="danger-background primary-title-dark"
                onClick={() => {
                  closePopup(false);
                }}
              >
                <h3>
                  <strong>NOT YET</strong>
                </h3>
              </Button>
              <Button
                onClick={() => {
                  onClose();
                }}
                className="success-background primary-title-light"
              >
                <h3>
                  <strong>START</strong>
                </h3>
              </Button>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                onClick={() => {
                  closePopup(false);
                }}
              >
                Cancelar
              </Button>
              <Button
                onClick={() => {
                  onClose();
                }}
                className="dark-background primary-title-dark"
              >
                Entendi!
              </Button>
            </React.Fragment>
          )}
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
