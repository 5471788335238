import { clients } from "../../clients";

export const getLinearGraph = async (userUid) => {
  try {
    const graphData = await clients.lampClient.get(`/performance/${userUid}/linear-graph`);
    return graphData;
  } catch (error) {
    throw error;
  }
};
