import { clients } from "../../clients";

export const getPerformanceData = async ({ userUid, chosenWeek, chosenDay, includesGeneral }) => {
  try {
    const performance = await clients.lampClient.get(`/performance/${userUid}`, {
      params: {
        weekNumber: chosenWeek,
        weekDay: chosenDay,
        includesGeneral: !!includesGeneral,
      },
    });
    return performance;
  } catch (error) {
    throw error;
  }
};
