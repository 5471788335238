import { clients } from "../../../clients";

export const saveInputData = async (requestBody) => {
  const { userUid, weekNumber, weekDay, inputData } = requestBody;
  const { active, dedaEvaluation, passive } = inputData.inputData;
  const { reviewData, dedaDaily } = inputData;

  try {
    const inputResponse = await clients.lampClient.patch(`/input/${userUid}/${weekNumber}/${weekDay}`, {
      inputDataToSave: {
        active,
        passive,
        dedaEvaluation: {
          ...dedaEvaluation,
          dedaDaily: {
            ...dedaDaily,
          },
        },
        review: reviewData,
      },
    });
    return inputResponse;
  } catch (error) {
    throw error;
  }
};
