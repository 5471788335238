import React from "react";
import styled from "@emotion/styled";

const IFrame = styled.iframe`
  width: 100%;
  height: 14.5rem;
  border-radius: 0.8rem;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  border-bottom-color: rgba(0, 0, 0, 0.87);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom-style: none;
  border-bottom-width: 0px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgba(0, 0, 0, 0.87);
  border-left-style: none;
  border-left-width: 0px;
  border-right-color: rgba(0, 0, 0, 0.87);
  border-right-style: none;
  border-right-width: 0px;
  border-top-color: rgba(0, 0, 0, 0.87);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-top-style: none;
  border-top-width: 0px;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: inline;
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 25.6px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  outline-color: rgba(0, 0, 0, 0.87);
  outline-style: none;
  outline-width: 0px;
  overflow-wrap: break-word;
  text-align: center;
`;

const PodcastFrame = ({ src }) => <IFrame allowFullScreen src={src} />;

export default PodcastFrame;
