import React, { useContext, useEffect, useState } from "react";
import { hpecList, hpecListSubMenu } from "../../../utils/constants";
import { HpecContext, SettingsContext } from "../../../../shared/providers";
import { allowedHpecListMaker, blockedHpecListMaker } from "../../../utils/dedasListMaker";
import { Grid, Typography } from "@material-ui/core";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import LockIcon from "@material-ui/icons/Lock";
import HpecSubMenu from "../atoms/HpecSubMenu";

export default function HpecListMenu({ chosenTopic }) {
  const { currentHpec } = useContext(HpecContext);
  const { isDesktop } = useContext(SettingsContext);
  const [isHpecOpen, setIsHpecOpen] = useState({
    [hpecList[chosenTopic].NAME]: true,
  });
  const [allHpecList, setAllHpecList] = useState({
    open: {},
    blocked: {},
  });

  const handleHpecOpen = (topic) => {
    isHpecOpen[hpecList[topic].NAME]
      ? setIsHpecOpen({ [hpecList[topic].NAME]: false })
      : setIsHpecOpen({ [hpecList[topic].NAME]: true });
  };

  useEffect(() => {
    if (currentHpec) {
      const currentHpecList = allowedHpecListMaker(currentHpec);
      const blockedHpeList = blockedHpecListMaker(currentHpec);

      setAllHpecList({ open: currentHpecList, blocked: blockedHpeList });
    }
  }, [currentHpec]);

  return (
    <Grid className={isDesktop && "overflow-column"} item sm={12}>
      <Grid container direction="column">
        <Grid item>
          {/*List of hpecs*/}
          <Grid container direction="column">
            <Grid
              style={{
                padding: "1rem",
                maxHeight: "4.5rem",
              }}
              className="deda-borders list-option"
              item
              xs={12}
            >
              <Grid
                item
                onClick={() => {
                  handleHpecOpen("TOPIC1");
                }}
              >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center" xs={12}>
                  <Grid item xs={2} xl={1}>
                    <CreateNewFolderIcon className="folder-color" />
                  </Grid>
                  <Grid item xs={10} xl={11}>
                    <Typography className="line-clamp" variant="subtitle1">
                      {hpecList["TOPIC1"].NAME}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {isHpecOpen[hpecList["TOPIC1"].NAME] && (
              <Grid className="deda-borders" item xs={12}>
                <HpecSubMenu
                  topic={"TOPIC1"}
                  subList={{
                    VC1: hpecList["TOPIC1"].VC1,
                    VC2: hpecList["TOPIC1"].VC2,
                    VC3: hpecList["TOPIC1"].VC3,
                  }}
                />
              </Grid>
            )}

            {Object.keys(allHpecList.open).map((topic, topicNumberIndex) => {
              return (
                <React.Fragment>
                  <Grid
                    style={{
                      padding: "1rem",
                      maxHeight: "4.5rem",
                    }}
                    className="deda-borders list-option"
                    item
                    xs={12}
                    onClick={() => {
                      handleHpecOpen(topic);
                    }}
                  >
                    <Grid item xs={12}>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center" xs={12}>
                        <Grid item xs={2} xl={1}>
                          <CreateNewFolderIcon className="folder-color" />
                        </Grid>
                        <Grid item xs={10} xl={11}>
                          <Typography className="line-clamp" variant="subtitle1">
                            {hpecList[topic].NAME}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isHpecOpen[hpecList[topic].NAME] && (
                    <Grid className="deda-borders" item xs={12}>
                      <HpecSubMenu topic={topic} subList={hpecListSubMenu[topic]} />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}

            {Object.keys(allHpecList.blocked).map((topic, topicNumberIndex) => {
              return (
                <React.Fragment>
                  <Grid
                    style={{
                      padding: "1rem",
                      maxHeight: "4.5rem",
                    }}
                    className="deda-borders list-option"
                    item
                    xs={12}
                  >
                    <Grid item>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center" xs={12}>
                        <Grid item xs={2} xl={1}>
                          <LockIcon className="locked-content" />
                        </Grid>
                        <Grid item xs={10} xl={11}>
                          <Typography className="line-clamp" variant="subtitle1">
                            {hpecList[topic].NAME}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  {isHpecOpen[hpecList[topic].NAME] && (
                    <Grid className="deda-borders" item xs={12}>
                      <HpecSubMenu
                        isBlocked
                        subList={hpecListSubMenu[topic]}
                        drippingDay={hpecList[topic].drippingDay}
                      />
                    </Grid>
                  )}
                </React.Fragment>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
