import React, { useContext } from "react";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import WarningIcon from "@material-ui/icons/Warning";
import { AccessLink, WidgetTitle } from "../atoms";
import { UserContext, HpecContext, DatesContext } from "../../../../shared/providers";
import { getNextStartDate } from "../../../utils/functions";
import { confirmDedaStart, unpauseAccount } from "../../../utils/apiCalls/accountManagerCalls/accountStatuses";

const MilestoneRender = ({ dedaIdsList }) => {
  const { newVersionUserDocData, setLoading } = useContext(UserContext);
  const { nextHpec } = useContext(HpecContext);
  const { dates } = useContext(DatesContext);
  const { accountStatus, currentTime, unlockedDEDAs } = newVersionUserDocData;
  const { daysSinceMelpBegin, currentDay, currentWeek } = currentTime;
  const { isDedaStartConfirmed } = accountStatus.dedaStart;
  const isDedaGoing = currentDay <= 60 * 7;

  const firstDays = () => {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container direction="column" justifyContent="space-between" spacing={2} className="col-max-height">
            <Grid item>
              <h3>
                <strong>Welcome to MELP!</strong>
              </h3>
            </Grid>
            <Grid item>
              <h5>As you can see, DEDA and LAMP are still closed for you.</h5>
              <br />
              <h5>
                Therefore, you should start from the <strong>section HPEC</strong> (below), consuming the content
                according to the opening schedule.
              </h5>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const weekZero = () => {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container direction="column" justifyContent="space-between" spacing={2} className="col-max-height">
            <Grid item>
              <h5>You are on your first DEDA. Take a look!</h5>
            </Grid>
            <Grid item>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <h5>WEEK: 0</h5>
                </Grid>
                <Grid item>
                  <h5>
                    DEDA: <strong>{dedaIdsList?.DEDA0 || "loading..."}</strong>
                  </h5>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <AccessLink href="deda/week0/dedaNotes" />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const confirmDedaPrompt = () => {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container direction="column" justifyContent="space-between" spacing={2} className="col-max-height">
            <Grid item>
              <Grid container direction="row" spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <WarningIcon style={{ fontSize: "2.5rem" }} />
                </Grid>
                <Grid item xs={10}>
                  <h4>
                    <strong>Hey there!</strong>
                  </h4>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              {accountStatus.pause.isAccountPaused ? (
                <h5>
                  Feeling like getting back to DEDA? The next available date is{" "}
                  <strong>{getNextStartDate().toLocaleDateString()}</strong>
                </h5>
              ) : (
                <h5>
                  You can start already the DEDA program. The next available date is{" "}
                  <strong>{getNextStartDate().toLocaleDateString()}</strong>
                </h5>
              )}
            </Grid>
            <Grid item>
              {/*@todo integrate function to submit confirmation*/}
              <Button
                className="access-button"
                onClick={async () => {
                  setLoading(true);
                  if (accountStatus.pause.isAccountPaused) {
                    await unpauseAccount(newVersionUserDocData.userData.uid)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((error) => {
                        throw error;
                      });
                  } else {
                    await confirmDedaStart(newVersionUserDocData.userData.uid)
                      .then(() => {
                        window.location.reload();
                      })
                      .catch((error) => {
                        throw error;
                      });
                  }
                }}
              >
                Yes, I want to start on {getNextStartDate().toLocaleDateString()}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const dedaConfirmedNotStarted = () => {
    return (
      <React.Fragment>
        <Grid item>
          <Grid container direction="column" justifyContent="space-between" spacing={2} className="col-max-height">
            <Grid item>
              <h5>
                Great! You have already confirmed the start of DEDA. It will happen <strong>next monday.</strong>
              </h5>
              <h5>Hang in there!</h5>
            </Grid>
            <Grid item>Feel free to take a look at your latest DEDA in the meantime</Grid>
            <Grid item>
              <AccessLink href={`deda/week${unlockedDEDAs.length - 1}/dedaNotes`} />
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const dedaConfirmedStarted = () => {
    return (
      <React.Fragment>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-evenly">
            <Grid item xs={isDedaGoing ? 6 : 12}>
              <Grid container direction="column" justifyContent="space-between" alignItems="stretch">
                <Grid item xs={12}>
                  <p>
                    Current WEEK: <strong>{currentWeek < 10 ? "0" + currentWeek : currentWeek}</strong>
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <p>
                    Current DAY: <strong>{currentDay < 10 ? "0" + currentDay : currentDay}</strong>
                  </p>
                </Grid>

                <Grid item xs={12}>
                  <p>Current DEDA:</p>
                  <p>
                    <strong>{currentTime.currentDedaName}</strong>
                  </p>
                </Grid>
              </Grid>
            </Grid>

            {isDedaGoing && (
              <Grid
                style={{
                  borderLeft: "solid black 1px",
                  paddingLeft: "2rem",
                }}
                item
                xs={6}
              >
                {dates && dates.nextHpecDate !== "Invalid Date" && (
                  <Grid container direction="column" justifyContent="space-between">
                    <Grid item xs={12}>
                      <p>Next HPEC module:</p>
                    </Grid>

                    <Grid item xs={12}>
                      <p>
                        <strong>{nextHpec && nextHpec.NAME}</strong>
                      </p>
                    </Grid>

                    <Grid item xs={12}>
                      <p>
                        Available on: <strong>{dates && dates.nextHpecDate}</strong>
                      </p>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderContinueWork = () => {
    if (!isDedaStartConfirmed) {
      if (daysSinceMelpBegin >= 0 && daysSinceMelpBegin < 2) {
        return firstDays();
      } else if (daysSinceMelpBegin >= 2 && daysSinceMelpBegin < 9) {
        return weekZero();
      } else if (daysSinceMelpBegin >= 9) {
        return confirmDedaPrompt();
      }
    } else {
      if (newVersionUserDocData.dedaConfirmedAndStarted) {
        return dedaConfirmedStarted();
      } else {
        return dedaConfirmedNotStarted();
      }
    }
  };

  return <React.Fragment>{renderContinueWork()}</React.Fragment>;
};

export default function ContinueWorkWidget({ dedaIdsList }) {
  const { isLoading } = useContext(UserContext);

  return (
    <React.Fragment>
      <Paper className="paper-shape paper-props paper-secondary widgets-height">
        <Grid container direction="row">
          <Grid item xs={12}>
            <WidgetTitle titleName={"Your work"} titleIcon={"continueWork"} />
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          xs={12}
          justifyContent="center"
          alignItems="stretch"
          spacing={1}
          className="col-max-height"
        >
          {isLoading ? <CircularProgress color="primary" /> : <MilestoneRender dedaIdsList={dedaIdsList} />}
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
