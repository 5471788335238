import React, { useContext, useState, createContext, useEffect } from "react";
import { UserContext, NotificationContext } from "./index";
import { getInputDataNewVersion } from "../../MELPv3/utils/apiCalls/lamp/read/getInputData";

export const InputDataContext = createContext({});

export const InputDataProvider = ({ children }) => {
  const { chosenWeek, chosenWeekDay, user, currentDay, setIsLampLoading, isUserLegacy } = useContext(UserContext);
  const { setNotification } = useContext(NotificationContext);
  const [inputData, setInputData] = useState({});
  const [isInputDataChanged, setIsInputDataChanged] = useState(false);

  useEffect(() => {
    if (isUserLegacy) {
      setIsLampLoading(true);
      const fetchInput = async () => {
        await getInputData({
          user: user.uid,
          chosenWeek: `WEEK${chosenWeek}`,
          chosenWeekDay: `DAY${chosenWeekDay}`,
        }).then((input) => {
          if (input) {
            setInputData(input);
          }
        });
      };
      if (user && chosenWeekDay && chosenWeek) {
        fetchInput()
          .then(() => setIsLampLoading(false))
          .catch((err) => console.log("fetchInput error from fetching input on provider:", err));
      }
    }
  }, [chosenWeek, chosenWeekDay, user, currentDay, isUserLegacy]);

  useEffect(() => {
    if (isInputDataChanged) {
      setNotification({
        isOpen: true,
        notificationText: "Remember to save your changes before you leave!",
        notificationType: "info",
      });
    }
  }, [isInputDataChanged, inputData]);

  useEffect(() => {
    if (!isUserLegacy) {
      const fetchInputAsync = async () => {
        setIsLampLoading(true);
        await getInputDataNewVersion({
          userUid: user.uid,
          chosenWeek,
          chosenWeekDay,
        })
          .then((input) => {
            setInputData(input);
          })
          .catch((error) => {
            throw error;
          });
      };

      if (user && chosenWeek && chosenWeekDay) {
        fetchInputAsync()
          .then(() => {
            setIsLampLoading(false);
          })
          .catch((error) => {
            throw error;
          });
      }
    }
  }, [chosenWeek, chosenWeekDay, user, isUserLegacy]);

  return (
    <InputDataContext.Provider
      value={{
        inputData,
        setInputData,
        isInputDataChanged,
        setIsInputDataChanged,
      }}
    >
      {children}
    </InputDataContext.Provider>
  );
};
