import React, { useContext } from "react";
import { NotificationContext, SettingsContext } from "../../../../shared/providers";
import { Chip } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoIcon from "@material-ui/icons/Info";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

const Success = ({ handleDelete, notification, commonStyles }) => {
  return (
    <Chip
      style={{
        ...commonStyles,
        backgroundColor: "rgba(39,245,75,0.32)",
        color: "green",
      }}
      icon={<CheckCircleIcon fontSize="medium" style={{ color: "green" }} />}
      variant="outlined"
      onDelete={handleDelete}
      label={notification.notificationText}
    />
  );
};

const Info = ({ handleDelete, notification, commonStyles }) => {
  return (
    <Chip
      style={{
        ...commonStyles,
        backgroundColor: "rgba(224,183,60,0.32)",
        color: "rgb(100,94,13)",
      }}
      icon={<InfoIcon fontSize="medium" style={{ color: "rgb(148,162,25)" }} />}
      variant="outlined"
      onDelete={handleDelete}
      label={notification.notificationText}
    />
  );
};

const Fail = ({ handleDelete, notification, commonStyles }) => {
  return (
    <Chip
      style={{
        ...commonStyles,
        backgroundColor: "rgba(229,57,57,0.32)",
        color: "red",
      }}
      icon={<NotInterestedIcon fontSize="medium" style={{ color: "red" }} />}
      variant="outlined"
      onDelete={handleDelete}
      label={"Error: " + notification.notificationText}
    />
  );
};

export default function Notifications() {
  const { notification, setNotification } = useContext(NotificationContext);
  const { isDesktop } = useContext(SettingsContext);

  const handleDelete = () => {
    const shouldRefresh = notification.notificationType === "failure";
    setNotification({
      isOpen: false,
      notificationText: "",
      notificationType: "",
    });

    if (shouldRefresh) {
      window.location.reload();
    }
  };

  const commonStyles = {
    width: "100%",
    justifyContent: "space-between",
    paddingTop: "1.5rem",
    paddingBottom: "1.5rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "5rem",
    fontSize: isDesktop ? "1.5rem" : "0.8",
  };

  const notificationChips = {
    success: <Success handleDelete={handleDelete} notification={notification} commonStyles={commonStyles} />,
    info: <Info handleDelete={handleDelete} notification={notification} commonStyles={commonStyles} />,
    failure: <Fail handleDelete={handleDelete} notification={notification} commonStyles={commonStyles} />,
  };

  return <React.Fragment>{notification.isOpen && notificationChips[notification.notificationType]}</React.Fragment>;
}
