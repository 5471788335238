import React, { useContext, useEffect, useState } from "react";
import { AppBar, Toolbar, Grid, CircularProgress } from "@material-ui/core";
import { SettingsContext, UserContext } from "../../../../shared/providers";
import UserMenu from "../atoms/UserMenu";
import "../../../assets/css/shared/navigation-bar.css";
import melpLogo from "../../../../shared/img/svg/melp-horizontal.svg";
import Notifications from "../atoms/Notifications";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import CloseIcon from "@material-ui/icons/Close";
import Help from "./Help";

function UserDisplay({ justify, fontSize }) {
  const { newVersionUserDocData, isLoading } = useContext(UserContext);
  const { currentTime, accountStatus } = newVersionUserDocData;
  const { currentDay, currentWeek, currentDedaName } = currentTime;
  const { chatOpen, setChatOpen } = useContext(SettingsContext);

  return (
    <Grid container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
      <Grid item xs={8} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <h3
          className="line-clamp"
          style={{
            color: "#1D212E",
            fontWeight: "500",
            fontSize,
            textAlign: "center",
          }}
        >
          {isLoading ? (
            <CircularProgress color="primary" />
          ) : accountStatus.dedaStart.isDedaStartConfirmed || accountStatus.pause.isAccountPaused ? (
            `W${currentWeek < 10 ? "0" + currentWeek : currentWeek} | ${currentDedaName} | D0${
              new Date().getDay() === 0 ? 7 : new Date().getDay()
            }`
          ) : null}
        </h3>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
        <Avatar
          style={{ cursor: "pointer" }}
          onClick={() => {
            setChatOpen(!chatOpen);
          }}
          id="help-avatar"
          alt="help-avatar"
          className="dark-background"
        >
          {chatOpen ? <CloseIcon fontSize="medium" /> : <QuestionAnswerIcon fontSize="default" />}
        </Avatar>
      </Grid>
      {chatOpen && <Help isAbsolute />}
      <Grid item xs={2}>
        <UserMenu />
      </Grid>
    </Grid>
  );
}

export default function NavigationBar({ scrolledClass }) {
  const { isDesktop } = useContext(SettingsContext);

  return (
    <AppBar position="fixed" id="nav-bar">
      {isDesktop ? (
        <Toolbar>
          <Grid container direction="row" xs={12} spacing={2} alignItems="center">
            <Grid item xs={2}>
              <Link to="/">
                <img
                  alt="melp-logo"
                  src={melpLogo}
                  style={{
                    width: "80%",
                    padding: "1.2rem",
                  }}
                />
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
              <UserDisplay fontSize={"1.5rem"} justifyContent="flex-end" />
            </Grid>
          </Grid>
        </Toolbar>
      ) : (
        <Toolbar style={{ padding: "1rem" }}>
          <Grid container direction="row" alignItems="center" justifyContent="center" spacing={2}>
            <Grid item xs={10}>
              <Grid container spacing={1} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  <UserDisplay fontSize={"1.2rem"} justifyContent="center" />
                </Grid>
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Notifications />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      )}
    </AppBar>
  );
}
