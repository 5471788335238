import { auth } from "../../services/firebase";
import { onAuthStateChanged } from "firebase/auth";

const clearInputs = ({ setEmail, setPassword }) => {
  setEmail("");
  setPassword("");
};

export const clearErrors = ({ setEmailError, setPasswordError }) => {
  setEmailError("");
  setPasswordError("");
};

export const authListener = async ({ setUser, setEmail, setPassword, setLoading }) => {
  await onAuthStateChanged(auth, (user) => {
    if (user) {
      clearInputs({ setEmail, setPassword });
      setUser(user);
    } else {
      setLoading(false);
      setUser(null);
    }
  });
};
