import React from "react";
import { CircularProgress, Container, Typography, Grid } from "@material-ui/core";
import "../shared/css/loadingScreen.css";
import logoMelpLogin from "../shared/img/svg/melp-login.svg";

export default function LoadingPage() {
  return (
    <React.Fragment>
      <Container maxWidth="xl" className="loading-screen dark-blue-background">
        <Grid className="loading-blob" container direction="column" justifyContent="center" alignItems="center">
          <Grid container item direction="column" justifyContent="center" alignItems="center" spacing={2}>
            <Grid item>
              <img style={{ width: "20vw" }} id="login-logo" src={logoMelpLogin} alt="melp-text-white-login" />
            </Grid>
            <Grid item>
              <CircularProgress color="" />
            </Grid>
            <Grid item>
              <Typography style={{ textAlign: "center" }} variant="h5" component="h5">
                <strong>Please wait while data is loading. This might take a moment.</strong>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
