import React, { useState, useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import DedaSubMenu from "../atoms/DedaSubMenu";
import { SettingsContext } from "../../../../shared/providers";

export default function DedasListMenu({ weekNumber, allDedasList }) {
  const { isDesktop } = useContext(SettingsContext);
  const [isDedaOpen, setIsDedaOpen] = useState({
    [weekNumber]: true,
  });

  const handleDedaOpen = (week) => {
    isDedaOpen[week] ? setIsDedaOpen({ [week]: false }) : setIsDedaOpen({ [week]: true });
  };

  return (
    <div className={isDesktop && "overflow-column"}>
      <Grid container direction="column">
        {Object.keys(allDedasList).map((week, index) => {
          const weekNumberCount = index + 1;
          return (
            <React.Fragment>
              <Grid
                style={{
                  padding: "1rem",
                  maxHeight: "4.5rem",
                }}
                className="deda-borders list-option"
                item
                xs={12}
                onClick={() => {
                  handleDedaOpen(week);
                }}
              >
                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2} xs={12}>
                    <Grid item xs={2} xl={1}>
                      <CreateNewFolderIcon className="folder-color" />
                    </Grid>
                    <Grid item xs={10} xl={11}>
                      <Typography variant="subtitle1" className="line-clamp">
                        W{weekNumberCount - 1 < 10 ? "0" + (weekNumberCount - 1) : weekNumberCount - 1} |{" "}
                        {allDedasList[week].dedaName}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {isDedaOpen[week] && (
                <Grid className="deda-borders" item xs={12}>
                  <DedaSubMenu dedaWeek={week} />
                </Grid>
              )}
            </React.Fragment>
          );
        })}
      </Grid>
    </div>
  );
}
