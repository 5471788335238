import React, { useState, createContext, useEffect, useContext } from "react";
import { UserContext } from "./UserProvider";
import { updateWindowSize } from "../../MELPv3/utils/functions";

export const SettingsContext = createContext({});

export const SettingsProvider = ({ children }) => {
  const { user, isUserLegacy } = useContext(UserContext);
  const [settings, setSettings] = useState(false);
  const [isSettingsLoading, setIsSettingsLoading] = useState(true);
  const [isDesktop, setIsDesktop] = useState(true);
  const [chatOpen, setChatOpen] = useState(false);
  const [isBottomMenuDisplayed, setIsBottomMenuDisplayed] = useState(true);

  useEffect(() => {
    setIsBottomMenuDisplayed(!chatOpen);
  }, [chatOpen]);

  useEffect(() => {
    updateWindowSize(setIsDesktop);
    window.addEventListener("resize", () => {
      updateWindowSize(setIsDesktop);
    });
  }, []);

  const handleSaveSettings = async () => {
    setIsSettingsLoading(true);
  };

  useEffect(() => {
    if (isUserLegacy) {
      const fetchSettings = async () => {};

      if (user) {
        fetchSettings()
          .then(() => {
            setIsSettingsLoading(false);
          })
          .catch((err) => Promise.reject(err));
      }
    }
  }, [user, isUserLegacy]);

  return (
    <SettingsContext.Provider
      value={{
        isDesktop,
        settings,
        setSettings,
        isSettingsLoading,
        handleSaveSettings,
        chatOpen,
        setChatOpen,
        isBottomMenuDisplayed,
        setIsBottomMenuDisplayed,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};
