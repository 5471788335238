import React from "react";
import { Typography } from "@material-ui/core";

export default function ComponentTitle({ children }) {
  return (
    <React.Fragment>
      <h5>
        <span style={{ fontWeight: 600 }}>{children}</span>
      </h5>
    </React.Fragment>
  );
}
