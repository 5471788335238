import styled from "@emotion/styled";

const ListenSoundCloud = styled.iframe`
  box-sizing: border-box;
  color: rgb(51, 51, 51);
  display: flex;
  font-family: "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Garuda, Verdana, Tahoma, sans-serif;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  font-variant-caps: normal;
  font-weight: 400;
  height: 10.3;
  line-height: 16.799999px;
  width: 100%;
  border: none;

  @media only screen and (max-width: 500px) {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.54);
    display: block;
    font-family: Raleway, sans-serif;
    font-size: 10.4px;
    font-weight: 400;
    height: 100%;
    letter-spacing: 0.111384px;
    line-height: 14.872px;
    margin-block-end: 0px;
    margin-block-start: 11.06px;
    margin-bottom: 0px;
    margin-inline-end: 0px;
    margin-inline-start: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 11.06px;
    text-align: center;
    width: 100%;
  }
`;

export default ListenSoundCloud;
