import React from "react";
import { Button, Divider, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function AccessLink({ href, startDay }) {
  return (
    <React.Fragment>
      <Grid container direction="column" xs={12} spacing={1}>
        <Grid item style={{ width: "100%" }}>
          <Divider />
        </Grid>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end" xs={12}>
            <Grid item xs={12} sm={6}>
              <Link to={href}>
                <Button className="access-button">{startDay ? "Welcome! START HERE" : "ACCESS"}</Button>
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
