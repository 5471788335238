import React, { useContext } from "react";
import { CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import VideoLabelRoundedIcon from "@material-ui/icons/VideoLabelRounded";
import PlaylistAddCheckRoundedIcon from "@material-ui/icons/PlaylistAddCheckRounded";
import BorderColorRoundedIcon from "@material-ui/icons/BorderColorRounded";
import HeadsetRoundedIcon from "@material-ui/icons/HeadsetRounded";
import { SettingsContext } from "../../../../shared/providers";
// import "../../assets/plugins/nucleo/css/nucleo.css";

export default function MainStatisticTile({ topic, statistic, theme }) {
  const { isDesktop } = useContext(SettingsContext);

  const themeSelect = {
    deda: {
      paperId: "performance-tile-deda",
      icon: <VideoLabelRoundedIcon fontSize="large" />,
    },
    review: {
      paperId: "performance-tile-review",
      icon: <PlaylistAddCheckRoundedIcon fontSize="large" />,
    },
    active: {
      paperId: "performance-tile-active",
      icon: <BorderColorRoundedIcon fontSize="large" />,
    },
    passive: {
      paperId: "performance-tile-passive",
      icon: <HeadsetRoundedIcon fontSize="large" />,
    },
  };
  return (
    <Paper
      // id={themeSelect[theme].paperId}
      className="statistic-tile paper-props paper-shape "
    >
      <Grid container direction="row" justifyContent="center" item spacing={2}>
        <Grid
          direction="column"
          container
          item
          xs={12}
          md={6}
          justifyContent="space-evenly"
          alignItems={!isDesktop ? "center" : "flex-start"}
        >
          <Grid item xs>
            <Typography variant="h6" comoponent="h6">
              <span className="statistic-name">{topic}</span>
            </Typography>
          </Grid>
          <Grid item xs>
            <Typography variant="h5" component="h5">
              <strong>{typeof statistic === "number" ? `${statistic.toFixed(2)} %` : <CircularProgress />}</strong>
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column" justifyContent="center" xs={3}>
          <Grid item style={{ textAlign: "center" }}>
            <div id="icon-background">{themeSelect[theme].icon}</div>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
