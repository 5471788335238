import React from "react";

export default function Help({ isAbsolute }) {
  const style = isAbsolute
    ? {
        position: "absolute",
        right: 0,
        top: 80,
        zIndex: "999",
      }
    : {};
  return (
    <React.Fragment>
      <iframe
        src="/help.html"
        allowTransparency
        style={{
          ...style,
          minHeight: "40rem",
          height: "40vh",
          minWidth: "400px",
          marginRight: "auto",
        }}
      />
    </React.Fragment>
  );
}
