import { addDays, isFriday, isMonday, isSaturday, isSunday, isThursday, isTuesday, isWednesday } from "date-fns";

export const updateDedaQualityColor = (average, setQualityColor) => {
  if ((average / 5) * 100 < 50) {
    setQualityColor("bad");
  } else {
    setQualityColor("good");
  }
};

export const updateQualityColor = (average, setQualityColor) => {
  if (average < 50) {
    setQualityColor("bad");
  } else {
    setQualityColor("good");
  }
};

export function updateWindowSize(setIsDesktop) {
  if (window.innerWidth < 970) {
    setIsDesktop(false);
  } else {
    setIsDesktop(true);
  }
}

export const timestampToDate = (timestamp) => {
  return new Date(timestamp._seconds * 1000);
};

export const getNextStartDate = () => {
  let nextStartDate = new Date();

  if (isMonday(nextStartDate)) return nextStartDate;
  if (isTuesday(nextStartDate)) nextStartDate = addDays(nextStartDate, 6);
  if (isWednesday(nextStartDate)) nextStartDate = addDays(nextStartDate, 5);
  if (isThursday(nextStartDate)) nextStartDate = addDays(nextStartDate, 4);
  if (isFriday(nextStartDate)) nextStartDate = addDays(nextStartDate, 3);
  if (isSaturday(nextStartDate)) nextStartDate = addDays(nextStartDate, 2);
  if (isSunday(nextStartDate)) nextStartDate = addDays(nextStartDate, 1);
  return nextStartDate;
};
