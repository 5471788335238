import { hpecLessonIds } from "../../../utils/constants";
import useGetLessonContent from "../../../hooks/queries/useGetLessonContent";
import { HeadingText } from "../../dedaComponents/atoms";
import EmbedVideo from "../../dedaComponents/atoms/EmbedVideo";
import { TextContentRender } from "../../dedaComponents/molecules";
import React from "react";

export const HpecRenderer = ({ subTopic }) => {
  const lessonId = hpecLessonIds[subTopic];

  const lessonResult = useGetLessonContent(lessonId);

  if (lessonResult.error) throw new Error(lessonResult.error.message);

  let lesson;
  if (!lessonResult.loading && lessonResult.data) {
    lesson = lessonResult.data.singleLessonCollection.items[0];
  }
  return (
    <>
      <HeadingText hType="h1" fontWeight="700">
        {lesson?.lessonTitle}
      </HeadingText>
      <br />
      {lesson?.lessonVideoEmbedUrl && <EmbedVideo fullWidth videoUrl={lesson.lessonVideoEmbedUrl} />}
      <TextContentRender rawContent={lesson?.lessonContent.json} links={lesson?.lessonContent.links} />
    </>
  );
};
