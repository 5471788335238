import { clients } from "../../MELPv3/utils/clients";

export const forgottenPassword = async (email) => {
  try {
    await clients.accountsClient.post("/passwords/forgot", { email });
  } catch (error) {
    console.error("ERROR: ", error);
  }
};

export const passwordRecovery = async ({ userUid, newPassword, token }) => {
  try {
    await clients.accountsClient.patch(`/passwords/${userUid}/forgot`, {
      token,
      newPassword,
    });
  } catch (error) {
    console.error("ERROR: ", error);
  }
};
