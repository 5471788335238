import React, { useState, useEffect, useContext } from "react";
import { unlockedDedasListMaker } from "../../../utils/dedasListMaker";
import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { SettingsContext, UserContext } from "../../../../shared/providers";
import { subMenuList } from "../../../utils/constants";
import "../../../assets/css/deda/navigator.css";
import Button from "@material-ui/core/Button";

export default function NavigationButtonsTop({ weekNumber, dedaSubItem }) {
  const [navigation, setNavigation] = useState({
    previousLink: "",
    previousLabel: {
      subtitle: "",
      title: "",
    },
    nextLink: "",
    nextLabel: {
      subtitle: "",
      title: "",
    },
  });
  const [allowedList, setAllowedList] = useState({});
  const { newVersionUserDocData } = useContext(UserContext);
  const { isDesktop } = useContext(SettingsContext);

  const subMenuListNumbers = {
    dedaNotes: 1,
    listen: 2,
    read: 3,
    watch: 4,
    listenRead: 5,
    write: 6,
  };

  const chosenWeek = parseInt(weekNumber.substr(4));

  useEffect(() => {
    unlockedDedasListMaker(newVersionUserDocData.unlockedDEDAs).then((list) => {
      setAllowedList(list);
    });
  }, []);

  const allowedWeeksList = Object.keys(allowedList);

  const getNavigationLinks = () => {
    const previousWeek = subMenuListNumbers[dedaSubItem] === 1 ? `week${chosenWeek - 1}` : weekNumber;
    const nextWeek = subMenuListNumbers[dedaSubItem] === 6 ? `week${chosenWeek + 1}` : weekNumber;

    const previousSubMenuItem =
      dedaSubItem === "dedaNotes" ? "write" : Object.keys(subMenuListNumbers)[subMenuListNumbers[dedaSubItem] - 2];
    const nextSubMenuItem =
      dedaSubItem === "write" ? "dedaNotes" : Object.keys(subMenuListNumbers)[subMenuListNumbers[dedaSubItem]];

    return {
      previousLink: chosenWeek === 0 && dedaSubItem === "dedaNotes" ? null : `/${previousWeek}/${previousSubMenuItem}`,
      previousLabel: {
        subtitle: "Previous",
        // + allowedList[previousWeek],
        title: subMenuList[previousSubMenuItem],
      },
      nextLink:
        (weekNumber === allowedWeeksList[allowedWeeksList.length - 1] ||
          allowedWeeksList[allowedWeeksList.length - 1] === undefined) &&
        dedaSubItem === "write"
          ? null
          : `/${nextWeek}/${nextSubMenuItem}`,
      nextLabel: {
        subtitle:
          // allowedList[nextWeek] +
          "Next",
        title: subMenuList[nextSubMenuItem],
      },
    };
  };

  useEffect(() => {
    setNavigation(getNavigationLinks());
  }, [weekNumber, dedaSubItem]);

  return (
    <React.Fragment>
      <Grid container direction="row" spacing={isDesktop ? 4 : 1} alignItems="center">
        <Grid item xs={6}>
          <Link to={navigation.previousLink === null ? "#" : "/deda" + navigation.previousLink}>
            <Button variant="contained" fullWidth className="nav-buttons bg-transparent-i">
              {navigation.previousLink === null ? null : (
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item xs={2}>
                    <NavigateBeforeIcon
                      className="dark-text"
                      style={{
                        fontSize: isDesktop ? "1.5rem" : "0.7rem",
                      }}
                    />
                  </Grid>
                  <Grid item xs={10}>
                    <Grid container direction="column" spacing={0} justifyContent="center" alignItems="flex-start">
                      <Grid item>
                        <h6
                          className="dark-text font-bold"
                          style={{
                            fontSize: isDesktop ? "1rem" : "0.7rem",
                          }}
                        >
                          {navigation.previousLabel.subtitle}
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Button>
          </Link>
        </Grid>
        <Grid item xs={6}>
          <Link to={navigation.nextLink === null ? "#" : "/deda" + navigation.nextLink}>
            <Button variant="contained" fullWidth className="nav-buttons bg-transparent-i">
              {navigation.nextLink === null ? null : (
                <Grid container direction="row" spacing={1} alignItems="center">
                  <Grid item xs={10}>
                    <Grid container direction="column" spacing={0} justifyContent="center" alignItems="flex-end">
                      <Grid item>
                        <h6
                          className="dark-text font-bold"
                          style={{
                            fontSize: isDesktop ? "1rem" : "0.7rem",
                          }}
                        >
                          {navigation.nextLabel.subtitle}
                        </h6>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={2}>
                    <NavigateNextIcon
                      className="dark-text"
                      style={{
                        fontSize: isDesktop ? "1.5rem" : "0.7rem",
                      }}
                    />
                  </Grid>
                </Grid>
              )}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
